import React from "react";
import SpinnerFetching from './SpinnerFetching';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

const SponFollowsBarChartThree = (props) => {

    function isProcessing() {
        return props.loading === true;
    }

    return (
        <div>
           {
    isProcessing() ? ( <SpinnerFetching /> ) :(
                 <div className="glass-chart-section">
                     <ResponsiveContainer width="90%" height={250}>
        <BarChart
          data={props.followLogs}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Bar type="monotone" dataKey="Followers" fill="#008080" />
        </BarChart>
        </ResponsiveContainer>
      </div>
      )}
        </div>
    )
}

export default SponFollowsBarChartThree;