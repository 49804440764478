import React, { useEffect, useState } from 'react';
import { isSponAuthenticated } from './repository';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const ItemImageDash = ({ match }) => {

  const [img, setImg] = useState([]);
  const [api, setApi] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
      fetchApi();
  }, [])

  const fetchApi = async () => {
      const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/imagedash`);
      const api = await fetchApi.json();
      setApi(api);
      setLoading(false);
  };

  useEffect(() => {
    fetchImg();
},[]);

  const fetchImg = async () => {
    const fetchIt = await fetch(`${process.env.REACT_APP_API_URL}/b/mainitemimg/${ match.params.itemid }`);
    const img = await fetchIt.json();
    setImg(img);
};

  return (
    <div>
      <br />
      <br />
      <br />
      {
        (loading) ? ( <Spinner /> ) : (
      <div className="glass-account-edit">
      <h1>{api.item}</h1>
        {
            ( isSponAuthenticated() ) ? <div>
                <Link to={ `/uploadone/${ match.params.itemid }` }>Add or Change Item Image</Link>
                <br />
  
            </div> : ''
        }
        <br />
        <img id="image" src={`${process.env.REACT_APP_CDN_URL}/${img.img1}`} alt="" height="300" width="300" />
        <br />
 <Link to={ `/myshop` }>Back to my Shop</Link>
 <br />
 </div>)
}
 <br />
            <br />
            <br />
            <br />
    </div>
  );
};

export default ItemImageDash;