import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const UpgradeAccount = (props) => {

const [sponuser, setUser] = useState(JSON.parse(localStorage.getItem('sponuser')) || [])
const [item, setItem] = useState([]);
const [info, setInfo] = useState([]);
const [loading, setLoading] = useState(true);
const [currentTier, setCurrentTier] = useState(sponuser.spontier);
const [sponID, setSponID] = useState(sponuser.sponsorid);
const [obj, setObj] = useState([]);

useEffect(()=>{
    fetchInfo();
}, [])

const fetchInfo = async () => {
    const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/content/upgradeaccount`);
    const info = await fetchInfo.json();
    setInfo(info);
    setLoading(false);
};

    useEffect(() => {
        let int = setInterval(() => {
            setItem(localStorage.setItem("sponuser", JSON.stringify(sponuser)));
            clearInterval(int);
        }, 1000)  
        return () => {
            clearInterval(int);
        }
    }, [fetchInfo]);

    const fetchAddress = async () => {
        const fetchAdd = await fetch(`${process.env.REACT_APP_API_URL}/b/spondetails/${sponuser.sponsorid}`);
        const addInfo = await fetchAdd.json();
        setObj(addInfo);
    };

    useEffect(() => {
        fetchAddress();
    }, [sponID])

    function addressHere() {
        return obj !== false;
    }

    function isTierOne() {
        return currentTier === 1;
    }
    
return (
    <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
    <div className="focus-div">
            <h1>{info.title}</h1>
        {isTierOne() ? (     <div className="focus-login">
            <h2>Your account has already been upgraded!</h2>
    </div>) : (
      <div className="focus-login">
   <div>
     <h3>Upgrade your Givmap Business Account and start using advanced analytics tools from Givmap.</h3>
     <p>Subscription is $29 per month and can be cancelled at any time.</p>
     {addressHere() ? (<h3><Link to='/settier'>Upgrade Business Account!</Link></h3>) : (<h3><Link to='/upgradeaddress'>Upgrade Business Account!</Link></h3>)}
     </div>
     </div>)}
      <br />
 <h3><Link to={`/accountsettings`}>Account Settings</Link></h3>
 <br />
      </div>
      )
}
      <br />
      <br />
            <br />
            <br />
            <br />
    </div>
)

}

export default UpgradeAccount;