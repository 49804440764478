import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const ReplySpon = ({ match }) => {

const [header, setHeader] = useState({});    
const [loading, setLoading] = useState(true);
const [mess, setMess] = useState([]);
const initialInputState = { message: '' };
const [eachEntry, setEachEntry] = useState(initialInputState);
const {message} = eachEntry;


const fetchMessage = async () => {
    const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/fullsponmessagenolog/${ match.params.messageid }`, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} });
    const item = await fetchItem.json();
    const content = item[0];
    setMess(content);
    const fetchTitle = await fetch(`${process.env.REACT_APP_API_URL}/b/content/sponreply`);
    const title = await fetchTitle.json();
    setHeader(title);
    setLoading(false);
};

useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

useEffect(() => {
   fetchMessage();
  }, [])

  const messageCreate = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/b/sponmail`, { apisponsorid: mess.apisponsorid, apientrantid: mess.apientrantid, senderid: mess.recipientid, recipientid: mess.senderid, message: data.message }, {headers: { 'x-api-key':  localStorage.getItem('x-api-key') } } )
    .then(response => {
    return response.status
    })
    .catch(err => Promise.reject('Message could not be sent!'));
    }  

const handleInputChange = e => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  const handleFinalSubmit = e => {
      e.preventDefault();
      messageCreate(eachEntry)
      .then(token => window.location = '/businessreturn')
      .catch(err => alert(err));
  };

return (
    <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="glass-account-edit">
                    <h1>Reply to {mess.username}:</h1>
                    <div className="glass-giveaway-details">
                    <h2>{mess.message}</h2>
                    <p><small>Received at {new Date(mess.created_at).toUTCString()}</small></p>
                    </div>
                    <br />
                    <div className="glass-giveaway-details">
                        <h2>Compose {header.title}:</h2>
                            <div className="container">
                            <form onSubmit={handleFinalSubmit}>
                            <div className="form-group">
                            <label>Message Body: </label>
                            <input type="text" className="form-control" name="message"
                            onChange={handleInputChange}/>
                            </div>
                            <button type="submit" className="btn btn-default">Send Message</button>
                            </form>
                            <br />
                            </div>
                </div>
                <br />
                <Link to={`/businessmailbox`}>Back to Mailbox</Link>
      <br />
      </div>)
}
            <br />
            <br />
            <br />
            <br />
    </div>
)

}

export default ReplySpon;