import React from 'react';
import { Link } from 'react-router-dom';
import { isAuthenticated } from './repository';
import backlessgreen from './backlessgreen.png';

const Sidebar = (props) => {

    let drawerClasses = 'side-drawer'
  if (props.show === true) {
    drawerClasses = 'side-drawer open'
  } if (props.show === false) {
    drawerClasses = 'side-drawer'
  }

return (
    <nav className={drawerClasses}>
      { isAuthenticated() ? (
      <div className="sibebar-auth">
        <div className="sidebar_logo">
      <Link to="/"><img className="navimg" id="backlessgreen" src={backlessgreen} alt="Givmap" height="37" width="120" /></Link>
      </div>
      <div className='sidebar-center'>
     <ul>
     <li><Link to="/feed">🏠 Home</Link></li>
     <li><Link to='/search'>🔎 Search</Link></li>
     <li><Link to='/giveaways'>🎁 Browse Giveaways</Link></li>
      <li><Link to='/businesses'>🏙️ Browse Businesses</Link></li>
      <li><h3>My Activity</h3></li>
      <li><Link to='/mywongiveaways'>🎉 View Giveaways you won!</Link></li>
      <li><Link to='/mygiveaways'>🛍️ View Giveaways you entered!</Link></li>
      <li><Link to='/myfavorites'>🌟 View your favorited Products!</Link></li>
      <li><Link to='/following'>🚶 View your followed Businesses!</Link></li>
      <li><Link to="/myaccount">⚙️ Account Settings</Link></li>
      <li><h3>Other</h3></li>
      <li><Link to="/about">📜 About</Link><br /></li>
      <li><Link to='/businessabout'>📈 Givmap for your Business</Link></li>
      <li><Link to='/create'>✍️ Sign Up</Link></li>
      <li><Link to='/login'>💻 Login</Link></li>
     </ul>
     </div>
     </div>
      ) : (
        <div className="sidebar-no-auth">
          <div className="sidebar_logo">
      <Link to="/"><img className="navimg" id="backlessgreen" src={backlessgreen} alt="Givmap" height="37" width="120" /></Link>
      </div>
      <div className='sidebar-center'>
        <ul>
        <li><Link to='/search'>🔎 Search</Link></li>
        <li><Link to='/giveaways'>🎁 Browse Giveaways</Link></li>
         <li><Link to='/businesses'>🏙️ Browse Businesses</Link></li>
         <li><Link to="/about">📜 About</Link><br /></li>
         <li><Link to='/businessabout'>📈 Givmap for your Business</Link></li>
         <li><Link to='/create'>✍️ Sign Up</Link></li>
         <li><Link to='/login'>💻 Login</Link></li>
        </ul>
        </div>
        </div>
      )
    }
    <div className="sidebar_bottom">
 <small><p>Givmap Version 2.0</p></small>
    </div>
    </nav>
)

}
export default Sidebar;