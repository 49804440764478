import React, { useState, useEffect } from 'react';
import Spinner from './Spinner';

const Terms = (props) => {

    const [api, setApi] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        fetchApi();
    }, [])

    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/terms`);
        const api = await fetchApi.json();
        setApi(api);
        setLoading(false);
    };

    return (
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
            <div>
            <h1>{api.terms}</h1>
            </div>
            <div className="focus-text">
            <p>The Givmap.com Terms of Service ("Terms of Service," “the Agreement”) is a legally binding agreement between Givmap.com ("Givmap," "we," "our"), owned and operated by Paseo Applications, LLC, and the User ("you," "your"). By using Givmap.com and its services, you are agreeing to abide by Givmap's Terms of Service. If you do not abide by the Agreement and Givmap does not immediately act to remedy the User’s non-abidance, Givmap reserves the right to later action. Givmap may modify the Agreement for any reason and at any time. If certain terms within the Agreement should be deemed legally unenforceable, it will not alter the effectiveness and/or duration of remaining terms within the Agreement. The laws of the State of Oklahoma shall govern the Agreement, and the User consents to all claims arising out of or relating to the Terms of Service being litigated exclusively in federal or state courts of Oklahoma County, Oklahoma, United States.</p>
            <p>User must be 18 years or older and a resident of the United States to use Givmap’s services. User must be authorized by a business entity to use Givmap’s business services on behalf of a business entity. User is solely responsible for all content posted to Givmap’s services by User account, including potential legal liability. User must have permission to use Third Party information and intellectual property in Givmap’s services. Unless explicitly permitted by Givmap, User may not sell, license, display, distribute, download, upload, broadcast, modify, reproduce, circumvent, destroy, disable, fraudulently use or otherwise misuse any Givmap service. Unless explicitly permitted by Givmap, User may not use Givmap’s services in conjunction with bots, scrapers, botnets, or any other automated program. User may not use Givmap’s services in the solicitation, procurement, distribution, or sale of any illegal services or products. We reserve the right to terminate any User account in the event User is found by Givmap to have violated the Agreement.</p>
            <p>By uploading content via any Givmap service, User grants rights to Givmap and other Users of Givmap’s services to use and access aforementioned content; User licenses to Givmap and other Users a worldwide, non-exclusive, royalty-free, and transferrable license to reproduce, display, and distribute aforementioned content in connection to Givmap’s services. We may remove any User content found by Givmap to be in violation of the Agreement.</p>
            <p>User assumes full responsibility for actions performed by User account within Givmap’s services. Givmap is not responsible for and has no control over outbound, Third Party Sites and their content.</p>
            <p>User is fully responsible for the legal compliance of any Promotion (“Competition,” “Giveaway,” “Sweepstake”) created, uploaded, and/or hosted on Givmap’s services. User is responsible for administration, enaction, and fulfillment of any Promotion created by aforementioned User, including shipping to other Users any prizes won in conjunction with a Promotion. We reserve the right to alter or terminate any Promotion by any User found by Givmap to be in violation of the Agreement or requiring legal consideration by other Users to participate. We reserve the right to terminate any User account found by Givmap to have created, uploaded, and/or hosted a Promotion which violates the Agreement.</p>
            <p>WARRANTY DISCLAIMER: UNLESS EXPRESSLY STATED IN THE TERMS OF SERVICE OR REQUIRED BY LAW, THE SERVICE IS PROVIDED “AS IS” AND GIVMAP MAKES NO SPECIFIC WARRANTIES REGARDING THE SERVICE.</p>
            <p>LIMITATION OF LIABILITY: EXCEPT AS REQUIRED BY LAW, NEITHER GIVMAP NOR ITS AGENTS WILL BE RESPONSIBLE FOR ANY LOSS OF REVENUES, BUSINESS, VALUABLE OPPORTUNITY, DATA, OR OTHER LOSSES CAUSED BY OUTAGES BY ANY PARTY, INTENTIONAL OR UNINTENTIONAL, IN GIVMAP’S SERVICES.</p>
            </div>
            <br />
            <p>Effective September 25, 2020.</p>
            <br/>
            </div>)
}
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default Terms;