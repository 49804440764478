import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import Spinner from './Spinner';

const EntrantLoginHooks = (props) => {

    const initialInputState = { email: '', password: '' };
    const [eachEntry, setEachEntry] = useState(initialInputState);
    const {email, password} = eachEntry;
    const [api, setApi] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        fetchApi();
    }, [])

    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/loginentrant`);
        const api = await fetchApi.json();
        setApi(api);
        setLoading(false);
    };

    const handleInputChange = e => {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
      };

      const handleFinalSubmit = e => {
        e.preventDefault();
        entrantLogin(eachEntry)
        .then(token => {if (token.message === 'false') {window.location = '/sendverification'} else {window.location = '/feed'}})
        .catch(err => alert(err));
    };

    async function entrantLogin (data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/b/login`, { email: data.email, password: data.password })
        .then(response => {
          if (response.data.message === 'false') {
             localStorage.setItem('x-access-token', response.data.token);
             localStorage.setItem('x-access-token-expiration', Date.now() + 2 * 60 * 60 * 1000);
             const userItem = {
                'entrantid': response.data.rows[0].entrantid, 
                'email': response.data.rows[0].email,
                'username': response.data.rows[0].username
            }
             localStorage.setItem('user', JSON.stringify(userItem));
             return response.data
          } else {
        const userItem = {
            'entrantid': response.data.rows[0].entrantid, 
            'email': response.data.rows[0].email,
            'username': response.data.rows[0].username
            }
        localStorage.setItem('x-access-token', response.data.token);
        localStorage.setItem('x-access-token-expiration', Date.now() + 2 * 60 * 60 * 1000);
        localStorage.setItem('user', JSON.stringify(userItem));
        return response.data
          }
        })
        .catch(err => Promise.reject('Authentication Failed! Please try again.'));
        }

    return(
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
            <div>
             <h1>{api.login}</h1>
            </div>
             <div className="focus-login">
             <form onSubmit={handleFinalSubmit}>
                <div className="form-group">
                    <label><h3>Email: </h3></label>
                         <input type="text" className="form-control" name="email"
                         onChange={handleInputChange}/>
                </div>
                <div className="form-group">
                    <label><h3>Password: </h3></label>
                         <input type="password" className="form-control" name="password"
                         onChange={handleInputChange}/>
                </div>
                <br />
                <button type="submit" className="february-form-submit-button"> Login </button>
            </form>
            <br />
            <Link to='/forgotpassword'><h3>Forgot my Password</h3></Link>
            </div>
            <br />
                <Link to='/businesslogin'><h3>🏙️ Login for Business Accounts</h3></Link>
                </div>)
}
                <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default EntrantLoginHooks;