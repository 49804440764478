import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { adminLogin } from './repository';
import Spinner from './Spinner';

const AdminLogin = (props) => {

    const [item, setItem] = useState([]);
    const initialInputState = { adminemail: '', password: '' };
    const [eachEntry, setEachEntry] = useState(initialInputState);
    const {adminemail, password} = eachEntry;
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        fetchItem();
    }, [])

    const fetchItem = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/content/adminlogin`);
        const item =  await fetchItem.json();
        setItem(item);
        setLoading(false);
    };

    const handleInputChange = e => {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
      };

      const handleFinalSubmit = e => {
        e.preventDefault();
        adminLogin(eachEntry)
        .then(token => window.location = '/admindash')
        .catch(err => alert(err));
    };

    return(
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="glass-login">
             <h3>{item.Login}</h3>
             <div className="container">
             <form onSubmit={handleFinalSubmit}>
                <div className="form-group">
                    <label>User: </label>
                         <input type="text" className="form-control" name="adminemail"
                         onChange={handleInputChange}/>
                </div>
                <div className="form-group">
                    <label>Password: </label>
                         <input type="password" className="form-control" name="password"
                         onChange={handleInputChange}/>
                </div>
                <button type="submit" className="btn btn-default">Submit</button>
            </form>
            </div>
            <br />
                <Link to='/'><p>Return to Main Page</p></Link>
                <br />
                </div>)
}
            <br />
            <br />
            <br />
        </div>
    )
}

export default AdminLogin;