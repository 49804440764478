import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import Spinner from './Spinner';

const SponShop = ({ match }) => {

const [items, setItems] = useState([]);
const [loading, setLoading] = useState(true);
const [data, setData] = useState(items);

const [currentPage, setcurrentPage] = useState(1);
const [itemsPerPage, setitemsPerPage] = useState(60);

const [pageNumberLimit, setpageNumberLimit] = useState(5);
const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

const fetcher = async () => {
    const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/myshop/${ match.params.sponsorid }`);
    const items = await fetchItem.json();
    setItems(items);
    setData(items);
    setLoading(false);
};

useEffect(() => {
    fetcher();
}, [])

const renderData = (items) => {
    return (
        <div className="container-array">
        {items.map(item => (
            <div key={item.itemid} className="glass-container-feed">
            <Link to={`/products/${item.itemid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${item.img1}`} alt="" height="75" width="75" /></Link>
            <h3 className="title"><Link to={`/products/${item.itemid}`}>{item.itemname}</Link></h3>
            <h3><small>${item.value}</small></h3>
        </div>
        ))}
      </div>
    );
  };
  
  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  const handleLoadMore = () => {
    setitemsPerPage(itemsPerPage + 5);
  };
  
function isArrThere() {
return data.length > 0;
}

function isArrBig() {
return data.length > 60;
}

return (
<div>
    <br />
    <br />
    <br />
    {
        (loading) ? ( <Spinner /> ) : (
    <div className="glass-shop">
          { isArrThere() ? (<div>
        <h1>Shop</h1>
        {renderData(currentItems)}
          {isArrBig() ? (
              <div>
      <div className="pageNumbersDiv">
      <button onClick={handleLoadMore} className="loadmore">
        Load More
      </button>
      </div>
      <div className="pageNumbersDiv">
      <ul className="pageNumbers">
        <li>
          <button
            onClick={handlePrevbtn}
            disabled={currentPage == pages[0] ? true : false}
          >
            Prev
          </button>
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}

        <li>
          <button
            onClick={handleNextbtn}
            disabled={currentPage == pages[pages.length - 1] ? true : false}
          >
            Next
          </button>
        </li>
      </ul>
      </div>
      </div>) : ('') }
          <br />
          <div className="shop-link">
          <h3><Link to={`/business/${ match.params.sponsorid }`}>Return to Business Profile Page</Link></h3>
          </div>
          <br />
          </div>) : (<div><h2>Nothing here... yet!</h2>
            <br />
            <div className="shop-link">
            <h3><Link to={`/business/${ match.params.sponsorid }`}>Return to Business Profile Page</Link></h3>
            </div>
            </div>
          ) }
          </div>)
}
          <br />
            <br />
            <br />
            <br />
</div>
)

}

export default SponShop;