import React, {useState, useEffect} from 'react';
import Spinner from './Spinner';
import { Link } from 'react-router-dom';

const ViewWinner = ({ match }) => {

    const [item, setItem] = useState({});
    const [sponuser, setUser] = useState(JSON.parse(localStorage.getItem('sponuser')))
    const [api, setApi] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        fetchApi();
    }, [])
  
    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/viewwinner`);
        const api = await fetchApi.json();
        setApi(api);
    };

      const fetchItem = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/getwinnerinfo/${ match.params.gwayid }`, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} });
        const item = await fetchItem.json();
        setItem(item);
        setLoading(false);
    };

    useEffect(() => {
        fetchItem();
      }, [])

      function isSpon() {
        return item.sponsorid === sponuser.sponsorid;
      }

    return ( 
    <div>
      <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="glass-account-edit">
      { ( isSpon() ) ? (<div><h1>{api.winner} of the {item.itemname} Giveaway:</h1>
    <h2>Please send the prize ({item.itemname}) to the following recipient:</h2>
    <h3>{item.firstname} {item.lastname}</h3>
    <h3>{item.street}</h3>
    <h3>{item.city}</h3>
    <h3>{item.state}</h3>
    <h3>{item.country}</h3>
    <h3>{item.zip}</h3>
    <h2>Please send confirmation and tracking information to {item.email}.</h2>
    <Link to={`/compose/${match.params.gwayid}`}><h2>Message Winner</h2></Link>
    </div>) : ( item == false ) ? (<h1>Waiting for {api.winner} to add address information...</h1>) : (<h1> Unavailable </h1>) }
   </div>)
}
    <br />
            <br />
            <br />
            <br />
    </div>
    )
};

export default ViewWinner;