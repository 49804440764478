import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const MailSentItem = ({ match }) => {

const [loading, setLoading] = useState(true);
const [unread, setUnread] = useState(false);
const [mess, setMess] = useState({});
const [sponimg, setSponimg] = useState({});

const fetchMessage = async () => {
    const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/fullmessage/${ match.params.messageid }`, { headers: { 'x-access-token': localStorage.getItem('x-access-token')} });
    const item = await fetchItem.json();
    const content = item[0];
    setMess(content);
    setLoading(false);
    const img = await fetch(`${process.env.REACT_APP_API_URL}/b/sponimg/${content.recipientid}`);
    const newImg = await img.json();
    setSponimg(newImg);
};

useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

useEffect(() => {
   fetchMessage();
  }, [])

return (
    <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="glass-account-edit">
                <h1>✉️ Message to</h1>
                <Link to={`/business/${mess.senderid}`}><img id="image" src={`${process.env.REACT_APP_CDN_URL}/${sponimg.image}`} alt="" height="100" width="100" /></Link><h1><Link to={`/business/${mess.senderid}`}>{mess.name}</Link>:</h1>
                <div className="glass-giveaway-details">
                <h2>{mess.message}</h2>
                <p><small>Sent at {new Date(mess.created_at).toUTCString()}</small></p>
                </div>
                <br />
                <p><Link to={`/mailsent`}>Back to Sent Mail</Link></p>
      <br />
      </div>)
}
            <br />
            <br />
            <br />
            <br />
    </div>
)

}

export default MailSentItem;