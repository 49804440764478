import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import Spinner from './Spinner';

const MyGwaysEntered = (props) => {

    const [loading, setLoading] = useState(true);

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || []);
    const [giveaways, setGiveaways] = useState([]);
    const [details, setDetails] = useState([]);

    const [data, setData] = useState(giveaways);

    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(60);
  
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

    const fetcher = () => {
        try {
        axios.get(`${process.env.REACT_APP_API_URL}/b/mygwayscombo/${user.entrantid}`, { headers: { 'x-access-token': localStorage.getItem('x-access-token')} })
        .then(res => {
            setGiveaways(res.data);
            setData(res.data);
            setLoading(false);
        }) } catch (error){
            return
        }
    }

    const fetchDetails = async () => {
        const fetchDets = await fetch(`${process.env.REACT_APP_API_URL}/b/entdetailsnolog/${ user.entrantid }`);
        const dets = await fetchDets.json();
        setDetails(dets);
    }
    
    useEffect(() => {
        fetcher();
    }, [])

    useEffect(() => {
        fetchDetails();
    },[])

    function noDetails () {
        return details === false;
    }

    const renderData = (giveaways) => {
        return (
            <div className="glass-container-array">
            {giveaways.map(giveaway => (
                <div key={giveaway.gwayid} className="glass-container-feed">
                              { giveaway.origintable === 'apigway' ? <Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link> : <Link target="_blank" to={`/instant/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link> }
                              { giveaway.origintable === 'apigway' ? <h3 className="title"><Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> : <h3 className="title"><Link target="_blank" to={`/instant/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> }
                            <p>by <Link target="_blank" to={`/business/${giveaway.sponsorid}`}>{giveaway.name}</Link></p>
                            { giveaway.origintable === 'apigway' ? <p><small>End: {new Date(giveaway.endon).toDateString()}</small></p> : <p><small>Instant Win</small></p> }
                        </div>
        
            ))}
          </div>
        );
      };

      const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
      };
    
      const pages = [];
      for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
        pages.push(i);
      }
    
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    
      const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
          return (
            <li
              key={number}
              id={number}
              onClick={handleClick}
              className={currentPage == number ? "active" : null}
            >
              {number}
            </li>
          );
        } else {
          return null;
        }
      });

    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);
    
        if (currentPage + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };
    
      const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);
    
        if ((currentPage - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };
    
      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
      }
    
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
      }
    
      const handleLoadMore = () => {
        setitemsPerPage(itemsPerPage + 5);
      };

  function isArrThere() {
    return data.length > 0;
  }

  function isArrBig() {
    return data.length > 60;
  }

    return (
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="gway-list-glass">
               { isArrThere() ? (<div>
            <h1>All Giveaways entered by {user.username}:</h1>
           { (noDetails()) ? (
               <div>
                   <h2>Please enter your address so prizes you win can be shipped to you!</h2>
                   <div>
           {renderData(currentItems)}
           { isArrBig() ? (<div>
      <div className="pageNumbersDiv">
      <button onClick={handleLoadMore} className="loadmore">
        Load More
      </button>
      </div>
      <div className="pageNumbersDiv">
      <ul className="pageNumbers">
        <li>
          <button
            onClick={handlePrevbtn}
            disabled={currentPage == pages[0] ? true : false}
          >
            Prev
          </button>
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}

        <li>
          <button
            onClick={handleNextbtn}
            disabled={currentPage == pages[pages.length - 1] ? true : false}
          >
            Next
          </button>
        </li>
      </ul>
      </div>
      </div>) : ('') }
      </div>
            </div>) : (<div>
            {renderData(currentItems)}
           { isArrBig() ? (<div>
      <div className="pageNumbersDiv">
      <button onClick={handleLoadMore} className="loadmore">
        Load More
      </button>
      </div>
      <div className="pageNumbersDiv">
      <ul className="pageNumbers">
        <li>
          <button
            onClick={handlePrevbtn}
            disabled={currentPage == pages[0] ? true : false}
          >
            Prev
          </button>
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}

        <li>
          <button
            onClick={handleNextbtn}
            disabled={currentPage == pages[pages.length - 1] ? true : false}
          >
            Next
          </button>
        </li>
      </ul>
      </div>
      </div>) : ('') }
            </div>) }
            </div>) : (<div><h2>Nothing here... yet!</h2></div>) }
            </div>)
}
            <br />
            <br />
            <br />
            <br />
        </div>
    )

}

export default MyGwaysEntered;