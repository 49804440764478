import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const UpgradeAddress = (props) => {

    const [loading, setLoading] = useState(true);

    const initialInputState = { street: '', city: '', state: '', country: '', zip: '' };
    const [eachEntry, setEachEntry] = useState(initialInputState);
    const {category, street, city, state, country, zip, website, bio, twitter, facebook, instagram, phone} = eachEntry;
    const [sponuser] = useState(JSON.parse(localStorage.getItem('sponuser')) || [])
    const [info, setInfo] = useState([]);

async function sponsorEditAddressInfo (sponsorid, data, stripeid) {
    axios.patch(`${process.env.REACT_APP_API_URL}/b/sponaddress/${sponsorid}`, { apisponid: sponsorid, street: data.street, city: data.city, state: data.state, country: data.country, zip: data.zip }, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} })
    .then(response => {
    return response.data
    })
    .catch(err => Promise.reject('Please enter all address fields!'));
    return axios.post(`${process.env.REACT_APP_API_URL}/b/customeraddress`, { customerId: stripeid, street: data.street, city: data.city, state: data.state, country: data.country, zip: data.zip }, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} })
    .then(response => {
    return response.data
    })
    .catch(err => Promise.reject('Please enter all address fields!'));
    }

const handleInputChange = e => {
setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
};

const handleFinalSubmit = e => {
e.preventDefault();
sponsorEditAddressInfo(sponuser.sponsorid, eachEntry, sponuser.stripeid)
.then(token => window.location = `/settier`)
.catch(err => alert(err));
};

useEffect(() => {
    fetchInfo();
}, [])

const fetchInfo = async () => {
    const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/spondetails/${ sponuser.sponsorid }`);
    const info = await fetchInfo.json();
    setInfo(info);
    setLoading(false);
};

    return(
<div>
<br />
<br />
<br />
{
        (loading) ? ( <Spinner /> ) : (
<div className="focus-div">
                <div>
                <h1>Add Your Business's Address, <Link to={`/business/${sponuser.sponsorid}`} >{sponuser.name}</Link>!</h1>
                </div>
                <div>
                <h2>We will need your billing address to proceed.</h2>
                </div>
                <div className="focus-edit">
                <div className="focus-edit-box">
                    <form onSubmit={handleFinalSubmit}>
                    <div className="form-group">
                        <h3>Current Address: </h3><h3 className="focus-contrast">{info.street}</h3><h3 className="focus-contrast">{info.city}</h3><h3 className="focus-contrast">{info.state}</h3><h3 className="focus-contrast">{info.country}</h3><h3 className="focus-contrast">{info.zip}</h3>
                    <label><h3>Update Street Address: </h3></label>
                    <input type="text" className="form-control" name="street"
                    onChange={handleInputChange}/>
                    </div>
                    <div className="form-group">
                    <label><h3>Update City: </h3></label>
                    <input type="text" className="form-control" name="city"
                    onChange={handleInputChange}/>
                    </div>
                    <div className="form-group">
                    <label><h3>Update State: </h3></label>
                    <select name="state" className="form-control" onChange={handleInputChange}>
                    <option value=""></option>
                    <option value="Alabama">Alabama</option>
                    <option value="Alaska">Alaska</option>
                    <option value="Arizona">Arizona</option>
                    <option value="Arkansas">Arkansas</option>
                    <option value="California">California</option>
                    <option value="Colorado">Colorado</option>
                    <option value="Connecticut">Connecticut</option>
                    <option value="Delaware">Delaware</option>
                    <option value="Florida">Florida</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Idaho">Idaho</option>
                    <option value="Illinois">Illinois</option>
                    <option value="Indiana">Indiana</option>
                    <option value="Iowa">Iowa</option>
                    <option value="Kansas">Kansas</option>
                    <option value="Kentucky">Kentucky</option>
                    <option value="Louisiana">Louisiana</option>
                    <option value="Maine">Maine</option>
                    <option value="Maryland">Maryland</option>
                    <option value="Massachusetts">Massachusetts</option>
                    <option value="Michigan">Michigan</option>
                    <option value="Minnesota">Minnesota</option>
                    <option value="Mississippi">Mississippi</option>
                    <option value="Missouri">Missouri</option>
                    <option value="Montana">Montana</option>
                    <option value="Nebraska">Nebraska</option>
                    <option value="Nevada">Nevada</option>
                    <option value="New Hampshire">New Hampshire</option>
                    <option value="New Jersey">New Jersey</option>
                    <option value="New Mexico">New Mexico</option>
                    <option value="New York">New York</option>
                    <option value="North Carolina">North Carolina</option>
                    <option value="North Dakota">North Dakota</option>
                    <option value="Ohio">Ohio</option>
                    <option value="Oklahoma">Oklahoma</option>
                    <option value="Oregon">Oregon</option>
                    <option value="Pennsylvania">Pennsylvania</option>
                    <option value="Rhode Island">Rhode Island</option>
                    <option value="South Carolina">South Carolina</option>
                    <option value="South Dakota">South Dakota</option>
                    <option value="Tennessee">Tennessee</option>
                    <option value="Texas">Texas</option>
                    <option value="Utah">Utah</option>
                    <option value="Vermont">Vermont</option>
                    <option value="Virginia">Virginia</option>
                    <option value="Washington">Washington</option>
                    <option value="West Virginia">West Virginia</option>
                    <option value="Wisconsin">Wisconsin</option>
                    <option value="Wyoming">Wyoming</option>
                    <option value="D.C.">District of Columbia</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Guam">Guam</option>
                    <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                    </select>
                    </div>
                    <div className="form-group">
                    <label><h3>Update Country: </h3></label>
                    <select name="country" className="form-control" onChange={handleInputChange}>
                    <option value=""></option>
                    <option value="United States">United States</option>
                    </select>
                    </div>
                    <div className="form-group">
                    <label><h3>Update Zip Code: </h3></label>
                    <input type="text" className="form-control" name="zip"
                    onChange={handleInputChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                </div>
    <br />
    <h2><Link to='/upgradeaccount'>Back to Upgrade Account</Link></h2>
    <br />
    </div>)
}
    <br />
            <br />
            <br />
            <br />
 </div>
    )
}

export default UpgradeAddress;