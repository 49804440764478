import React from "react";
import { AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Area, ResponsiveContainer } from "recharts";

const SponChartBasic = (props) => {

    return (
                 <div className="glass-chart-section">
           <h3>Total Views (Giveaways, Products, and Business Profile)</h3>
           <ResponsiveContainer width="90%" height={250}>
        <AreaChart
          data={props.allLogs}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="Total Views" stroke="#8884d8" />
        </AreaChart>
        </ResponsiveContainer>
      </div>
    )
}

export default SponChartBasic;