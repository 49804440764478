import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const ReturnHooks = (props) => {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || [])
    const [api, setApi] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        fetchApi();
    }, [])

    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/return`);
        const api = await fetchApi.json();
        setApi(api);
        setLoading(false);
    };

    return(
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="glass-account-edit">
             <h1>{api.return}, {user.username}!</h1>
             <div className="glass-chart">
             <p><Link to='/giveaways'>Enter a Giveaway!</Link></p>
             <p><Link to='/businesses'>Or browse Businesses!</Link></p>
             </div>
             <br />
             </div>)
}
             <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default ReturnHooks;