import React from 'react';
import './SponToggleButton.css'

const SponDrawerToggleButton = (props) => (
    <button className="spon-toggle-button" onClick={props.click}>
    <div className="spon-toggle-button__line" />
    <div className="spon-toggle-button__line" />
    <div className="spon-toggle-button__line" />
  </button>
)

export default SponDrawerToggleButton;