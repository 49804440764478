import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import Spinner from './Spinner';

const EntrantEditHooks = (props) => {

const initialInputState = { street: '', city: '', state: '', country: '', zip: '' };
const [eachEntry, setEachEntry] = useState(initialInputState);
const {firstname, lastname, street, city, state, country, zip, bio, birthday} = eachEntry;
const [user] = useState(() => JSON.parse(localStorage.getItem("user")) || "");
const [info, setInfo] = useState([]);
const [loading, setLoading] = useState(true);

const initialFirstNameState = { firstname: '' };
const initialLastNameState = { lastname: '' };
const initialBioState = { bio: '' };
const initialBirthdayState = { birthday: '' };
const [firstNameEntry, setFirstNameEntry] = useState(initialFirstNameState);
const [lastNameEntry, setLastNameEntry] = useState(initialLastNameState);
const [bioEntry, setBioEntry] = useState(initialBioState);
const [birthdayEntry, setBirthdayEntry] = useState(initialBirthdayState);

const patchFirstName = async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/entfirstname/${user.entrantid}`, data, { headers: {'x-access-token': localStorage.getItem('x-access-token')} })
    .then(response => {
        console.log(response.data)
      return response.status
    })
    .catch(err => Promise.reject('Failed!'));
}

const handleFirstNameChange = e => {
    setFirstNameEntry({ ...firstNameEntry, [e.target.name]: e.target.value });
  };

  const handleFirstNameSubmit = e => {
    e.preventDefault();
    patchFirstName(firstNameEntry)
    .then(token => window.location = `/editmyprofile`)
    .catch(err => alert(err));
};

const patchLastName = async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/entlastname/${user.entrantid}`, data, { headers: {'x-access-token': localStorage.getItem('x-access-token')} })
    .then(response => {
      return response.status
    })
    .catch(err => Promise.reject('Failed!'));
}

const handleLastNameChange = e => {
    setLastNameEntry({ ...lastNameEntry, [e.target.name]: e.target.value });
  };

  const handleLastNameSubmit = e => {
    e.preventDefault();
    patchLastName(lastNameEntry)
    .then(token => window.location = `/editmyprofile`)
    .catch(err => alert(err));
};

async function entrantEditInfo (entrantid, data) {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/patchuseraddressinfo/${entrantid}`, { apientrantid: entrantid, street: data.street, city: data.city, state: data.state, country: data.country, zip: data.zip }, {headers: { 'x-access-token':  localStorage.getItem('x-access-token') } })
    .then(response => {
    return response.data
    })
    .catch(err => Promise.reject('Please enter all address fields!'));
    }

const handleInputChange = e => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  const handleFinalSubmit = e => {
    e.preventDefault();
    entrantEditInfo(user.entrantid, eachEntry)
    .then(token => window.location = '/editmyprofile')
    .catch(err => alert(err));
};

const patchBio = async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/entbio/${user.entrantid}`, data, { headers: {'x-access-token': localStorage.getItem('x-access-token')} })
    .then(response => {
      return response.status
    })
    .catch(err => Promise.reject('Failed!'));
}

const handleBioChange = e => {
    setBioEntry({ ...bioEntry, [e.target.name]: e.target.value });
  };

  const handleBioSubmit = e => {
    e.preventDefault();
    patchBio(bioEntry)
    .then(token => window.location = `/editmyprofile`)
    .catch(err => alert(err));
};

const patchBirthday = async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/entbirthday/${user.entrantid}`, data, { headers: {'x-access-token': localStorage.getItem('x-access-token')} })
    .then(response => {
      return response.status
    })
    .catch(err => Promise.reject('Failed!'));
}

const handleBirthdayChange = e => {
    setBirthdayEntry({ ...birthdayEntry, [e.target.name]: e.target.value });
  };

  const handleBirthdaySubmit = e => {
    e.preventDefault();
    patchBirthday(birthdayEntry)
    .then(token => window.location = `/editmyprofile`)
    .catch(err => alert(err));
};

const fetchInfo = async () => {
    const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/entdetails/${ user.entrantid }`);
    const info = await fetchInfo.json();
    setInfo(info);
    setLoading(false);
};

useEffect(() => {
    fetchInfo();
}, [])

    return(
<div>
     <br />
     <br />
     <br />
     {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
        <div>
        <h1>Add Account Details</h1>
        </div>
        <div>
        <h2>Your address, email, and name are needed so a prize can be shipped to you if you win!</h2>
        <h2>You will also be eligible to enter Giveaways exclusive to your state!</h2>
        </div>
        <div className="focus-edit">
                    <div className="focus-edit-box">
                    <form onSubmit={handleFirstNameSubmit}>
                    <div className="form-grounp">
                    <h3>Current First Name: </h3><h3 className="focus-contrast">{info.firstname}</h3>
                    <label><h3>Update First Name: </h3></label>
                    <input type="text" className="form-control" name="firstname"
                    onChange={handleFirstNameChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handleLastNameSubmit}>
                    <div className="form-group">
                    <h3>Current Last Name: </h3><h3 className="focus-contrast">{info.lastname}</h3>
                    <label><h3>Update Last Name: </h3></label>
                    <input type="text" className="form-control" name="lastname"
                    onChange={handleLastNameChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handleFinalSubmit}>
                    <div className="form-group">
                        <h3>Current Address: </h3><h3 className="focus-contrast">{info.street}</h3><h3 className="focus-contrast">{info.city}</h3><h3 className="focus-contrast">{info.state}</h3><h3 className="focus-contrast">{info.country}</h3><h3 className="focus-contrast">{info.zip}</h3>
                    <label><h3>Update Street Address: </h3></label>
                    <input type="text" className="form-control" name="street"
                    onChange={handleInputChange}/>
                    </div>
                    <div className="form-group">
                    <label><h3>Update City: </h3></label>
                    <input type="text" className="form-control" name="city"
                    onChange={handleInputChange}/>
                    </div>
                    <div className="form-group">
                    <label><h3>Update State: </h3></label>
                    <select name="state" className="form-control" onChange={handleInputChange}>
                    <option value=""></option>
                    <option value="Alabama">Alabama</option>
                    <option value="Alaska">Alaska</option>
                    <option value="Arizona">Arizona</option>
                    <option value="Arkansas">Arkansas</option>
                    <option value="California">California</option>
                    <option value="Colorado">Colorado</option>
                    <option value="Connecticut">Connecticut</option>
                    <option value="Delaware">Delaware</option>
                    <option value="Florida">Florida</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Idaho">Idaho</option>
                    <option value="Illinois">Illinois</option>
                    <option value="Indiana">Indiana</option>
                    <option value="Iowa">Iowa</option>
                    <option value="Kansas">Kansas</option>
                    <option value="Kentucky">Kentucky</option>
                    <option value="Louisiana">Louisiana</option>
                    <option value="Maine">Maine</option>
                    <option value="Maryland">Maryland</option>
                    <option value="Massachusetts">Massachusetts</option>
                    <option value="Michigan">Michigan</option>
                    <option value="Minnesota">Minnesota</option>
                    <option value="Mississippi">Mississippi</option>
                    <option value="Missouri">Missouri</option>
                    <option value="Montana">Montana</option>
                    <option value="Nebraska">Nebraska</option>
                    <option value="Nevada">Nevada</option>
                    <option value="New Hampshire">New Hampshire</option>
                    <option value="New Jersey">New Jersey</option>
                    <option value="New Mexico">New Mexico</option>
                    <option value="New York">New York</option>
                    <option value="North Carolina">North Carolina</option>
                    <option value="North Dakota">North Dakota</option>
                    <option value="Ohio">Ohio</option>
                    <option value="Oklahoma">Oklahoma</option>
                    <option value="Oregon">Oregon</option>
                    <option value="Pennsylvania">Pennsylvania</option>
                    <option value="Rhode Island">Rhode Island</option>
                    <option value="South Carolina">South Carolina</option>
                    <option value="South Dakota">South Dakota</option>
                    <option value="Tennessee">Tennessee</option>
                    <option value="Texas">Texas</option>
                    <option value="Utah">Utah</option>
                    <option value="Vermont">Vermont</option>
                    <option value="Virginia">Virginia</option>
                    <option value="Washington">Washington</option>
                    <option value="West Virginia">West Virginia</option>
                    <option value="Wisconsin">Wisconsin</option>
                    <option value="Wyoming">Wyoming</option>
                    <option value="D.C.">District of Columbia</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Guam">Guam</option>
                    <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                    </select>
                    </div>
                    <div className="form-group">
                    <label><h3>Update Country: </h3></label>
                    <select name="country" className="form-control" onChange={handleInputChange}>
                    <option value=""></option>
                    <option value="United States">United States</option>
                    </select>
                    </div>
                    <div className="form-group">
                    <label><h3>Update Zip Code: </h3></label>
                    <input type="text" className="form-control" name="zip"
                    onChange={handleInputChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handleBioSubmit}>
                    <div className="form-group">
                    <h3>Current Bio: </h3><h3 className="focus-contrast">{info.bio}</h3>
                    <label><h3>Update Bio: </h3></label>
                    <input type="text" className="form-control" name="bio"
                    onChange={handleBioChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handleBirthdaySubmit}>
                    <div className="form-group">
                    <h3>Current Birthday: </h3>{(info.birthday === ""|| info.birthday === null || info.birthday === undefined) ? (<h3></h3>) : (<h3 className="focus-contrast">{new Date(info.birthday).toDateString()}</h3>)}
                    <label><h3>Update Birthday: </h3></label>
                    <input type="date" className="form-control" name="birthday"
                    onChange={handleBirthdayChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
        </div>       
 <br />
 <h3><Link to={`/myaccount`}>Account Settings</Link></h3>
<br />
    </div>)
}
    <br />
    <br />
            <br />
            <br />
            <br />
 </div>
    )
}

export default EntrantEditHooks;