import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const Home = (props) => {

  const [giveaways, setGiveaways] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [biz, setBiz] = useState(0);
  const [liveGways, setLiveGways] = useState(0);
  const [prizes, setPrizes] = useState(0);
  const [title, setTitle] = useState({})

  const today = new Date().toISOString().slice(0, 10);

  const gFetcher = async () => {
    const fetchGiveaways = await fetch(`${process.env.REACT_APP_API_URL}/b/frontpagegwayscombo/${today}`);
    const giveaways = await fetchGiveaways.json();
    setGiveaways(giveaways);
};

const bFetcher = async () => {
  const fetchBusinesses = await fetch(`${process.env.REACT_APP_API_URL}/b/frontpagespons`);
  const businesses = await fetchBusinesses.json();
  setBusinesses(businesses);
};

const bizFetcher = async () => {
  const fetchBiz = await fetch(`${process.env.REACT_APP_API_URL}/b/numberofbusinesses`);
  const businesses = await fetchBiz.json();
  setBiz(businesses.number);
};

const gwayFetcher = async () => {
  const fetchThis = await fetch(`${process.env.REACT_APP_API_URL}/b/numberofactivegways/${today}`);
  const liveGs = await fetchThis.json();
  const fetchThat = await fetch(`${process.env.REACT_APP_API_URL}/b/numberofactiveinstants/${today}`);
  const liveInstants = await fetchThat.json();
  const sum = liveGs.number + liveInstants.number;
  setLiveGways(sum);
};

const prizeFetcher = async () => {
  const fetchPrizes = await fetch(`${process.env.REACT_APP_API_URL}/b/numberofprizes`);
  const prizeOne = await fetchPrizes.json();
  const fetchInstantPrizes = await fetch(`${process.env.REACT_APP_API_URL}/b/numberofinstantprizes`);
  const prizeTwo = await fetchInstantPrizes.json();
  const num = prizeOne.number + prizeTwo.number;
  setPrizes(num);
  setLoading(false);
};

const titleFetcher = async () => {
  const fetchResult = await fetch(`${process.env.REACT_APP_API_URL}/b/content/titleheader`);
  const result = await fetchResult.json();
  setTitle(result);
}

useEffect(() =>{
  titleFetcher();
  gFetcher();
  bFetcher();
  bizFetcher();
  gwayFetcher();
  prizeFetcher();
}, [])

useEffect(() => {
  fetch(`${process.env.REACT_APP_API_URL}/b/givmap`);
}, [])

  return (
    <div>
      <br />
      <br />
      <br />
       {
        (loading) ? ( <Spinner /> ) : (
    <div className="home">
    <div className="home-stat-container">
    <h1>Welcome to the Giveaway Marketplace</h1>
    <h2><em>{title.header}</em></h2>
      <div className="home-stat-box">
      <div className="home-stats"><h2>Businesses: {biz.toLocaleString("en-US")}</h2></div>
      <div className="home-stats"><h2>Active Giveaways: {liveGways.toLocaleString("en-US")}</h2></div>
      <div className="home-stats"><h2>Prizes Won: {prizes.toLocaleString("en-US")}</h2></div>
      </div>
    </div>
    <br />
    <div className="home-title-container">
    <h2>Featured <Link to={`/giveaways`}>Giveaways</Link></h2>
    <div className="home-giveaways"> 
    {giveaways.map((giveaway) => {
                    return (
                        <div key={giveaway.gwayid} className="glass-front">
                          <div className="container-gwayswithbiz">
                          <div className="container-gwaytitle">
                          { giveaway.origintable === 'apigway' ? <Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link> : <Link target="_blank" to={`/instant/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link>}
                               { giveaway.origintable === 'apigway' ? <h3 className="title"><Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> : <h3 className="title"><Link target="_blank" to={`/instant/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> }
                               </div>
                                <p>by <Link target="_blank" to={`/business/${giveaway.sponsorid}`}>{giveaway.name}</Link></p>
                                </div>
                                { giveaway.origintable === 'apigway' ? <p><small>Ends: {new Date(giveaway.endon).toDateString()}</small></p> : <p><small>Instant Win</small></p> }
                        </div>
                    )
                })}
                </div>
    </div>
    <br />
    <div className="home-title-container">
    <h2>Featured <Link to={`/businesses`}>Businesses</Link> on Givmap</h2>
    <div className="home-businesses">
    {businesses.map((business) => {
                 return (
                     <div key={business.sponsorid} className="glass-front">
                         <Link target="_blank" to={`/business/${business.sponsorid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${business.image}`} alt="" height="75" width="75" /></Link>
                         <h3 className="title"><Link target="_blank" to={`/business/${business.sponsorid}`}>{business.name}</Link></h3>
                         <p>{business.city}, {business.state}</p>
                     </div>
                 )
             })}
             </div>
            </div>
            <br />
            <br />
            <br />
            <br />
    </div>)
}
    </div>
  );
};

export default Home;
