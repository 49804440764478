import React, {useState, useEffect} from 'react';
import { isAuthenticated } from './repository';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Spinner from './Spinner';
import SpinnerEntry from './SpinnerEntry';

function BusinessDetails({ match }) {

    const [item, setItem] = useState({});
    const [user] = useState(() => JSON.parse(localStorage.getItem("user")) || "");
    const [followed, setFollowed] = useState("");
    const [loading, setLoading] = useState(true);
    const [score, setScore] = useState([]);
    const [tier, setTier] = useState([]);
    const [isSet, setIsSet] = useState(false);
    const [location, setLocation] = useState([]);
    const [arr, setArr] = useState([]);
    const [clicked, setClicked] = useState(false);  
    const [message, setMessage] = useState([]);
    const [thinking, setThinking] = useState(false);

    const today = new Date().toISOString().slice(0, 10);

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    
      useEffect(() => {
          fetchItem();
      },[]);
  
      useEffect(() => {
        fetchFollow();
      }, [])

      useEffect(() => {
        fetchScore();
      }, [])

    const fetchItem = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/sponsorsfull/${ match.params.sponsorid }`);
        if (fetchItem.status === 400) {
          window.location = "/notfound"
        }
        const item = await fetchItem.json();
        setItem(item);
        setTier(item.spontier);
        setLocation(item.state);
        setIsSet(true);
    };

    const fetchArr = async () => {
      if (tier > 0) {
      const fetchArrInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/sidegiveawaysonecombo/${item.sponsorid}/${today}`);
      const info = await fetchArrInfo.json();
      setArr(info);
    } else {
      const fetchArrInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/sidebusinesseszero/${location}`);
      const info = await fetchArrInfo.json();
      setArr(info);
    }
  };

  useEffect(() => {
    if (isSet === true) {
      fetchArr();
    } else return
  }, [isSet])

    const fetchFollow = async () => {
      const fetchIt = await fetch(`${process.env.REACT_APP_API_URL}/b/haveifollowed/${ match.params.sponsorid }`, { headers: { 'x-access-token': localStorage.getItem('x-access-token')} });
      const it = await fetchIt.json();
      setFollowed(it);
      setLoading(false);
  };

   async function createEntSpon (data) {
      setClicked(true)
      return axios.post(`${process.env.REACT_APP_API_URL}/b/entspons/${ match.params.sponsorid }`, { apientrantid: user.entrantid }, {headers: { 'x-access-token':  localStorage.getItem('x-access-token') } } )
      .then(response => {
        setMessage(response.data)
        setThinking(false);
      return response.status
      })
      .catch(err => Promise.reject('Entspon relationship could not be created!'));
      }

     async function unfollowSpon (data) {
        setClicked(true)
        return axios.delete(`${process.env.REACT_APP_API_URL}/b/entspons/${ match.params.sponsorid }`, { headers: { 'x-access-token':  localStorage.getItem('x-access-token') }, data: { apientrantid: user.entrantid } } )
        .then(response => {
          setMessage(response.data)
          setThinking(false);
        return response.status
        })
        .catch(err => Promise.reject('Business could not be unfollowed!'));
        }
  
function submitCreate(event){
  event.preventDefault();
  setThinking(true);
  createEntSpon()
  .catch(err => alert(err));
  };

  function submitUnfollow(event){
    event.preventDefault();
    setThinking(true);
    unfollowSpon()
    .catch(err => alert(err));
    };

    function arrSum(arr){
      return arr.reduce(function(a,b){
        return a + b
      }, 0);
    }

    const fetchScore = async () => {
        const fetchIt = await fetch(`${process.env.REACT_APP_API_URL}/b/ratingscorecombo/${ match.params.sponsorid }`);
        const it = await fetchIt.json();
        if (it === false) {
          setScore(false)
        } else {
        const long = await it.length;
        const sum = arrSum(it);
        const result = sum / long;
        const roundNum = Math.round(result);
        setScore(roundNum);
        }
    };

    function isOneStar() {
      return score === 1;
    }

    function isTwoStar() {
      return score === 2;
    }

    function isThreeStar() {
      return score === 3;
    }

    function isFourStar() {
      return score === 4;
    }

    function isFiveStar() {
      return score === 5;
    }

    function isPremium() {
      return tier > 0;
  }

  return (
    <div>
      <br />
      <br />
      <br />
      {(loading) ? ( <Spinner /> ) : (
      <div className="focus-div">
        <br />
      <div className="focus-biz-pro">
        <div className="focus-column">
        <div className="focus-pro-card">
          <div className="focus-pro-pic">
            <a target="_blank" href={`${process.env.REACT_APP_CDN_URL}/${item.image}`}><img id="image" src={`${process.env.REACT_APP_CDN_URL}/${item.image}`} alt="" height="180" width="180" /></a>
          </div>
          <div className="focus-pro-name">
            <h1>{item.name}</h1>
          </div>
          {isPremium() ? <p><small>✨ Premium ✨</small></p> : '' }
          <p><em>{item.bio}</em></p>
          <div>
            <p>Rating: </p> {(isOneStar() ? <div>⭐◾◾◾◾</div> : isTwoStar() ? <div>⭐⭐◾◾◾</div> : isThreeStar() ? <div>⭐⭐⭐◾◾</div> : isFourStar() ? <div>⭐⭐⭐⭐◾</div> : isFiveStar() ? <div>⭐⭐⭐⭐⭐</div> : <p>No ratings yet!</p>)}
          </div>
          <div className="focus-biz-pro-button">
        { (thinking === true) ? <div><SpinnerEntry /></div> :
         <div>
        { ( isAuthenticated() && followed === false && (!clicked)) ? (<button className="january-form-submit-button" onClick={submitCreate}> Follow </button>) : ( isAuthenticated() && followed === true && (!clicked)) ? (<button className="january-form-submit-button" onClick={submitUnfollow}> Unfollow </button>) : ( isAuthenticated() && followed === false && (clicked)) ? (<h2>{message.message}</h2>) : ( isAuthenticated() && followed === true && (clicked)) ? (<h2>{message.message}</h2>) : (<h3><Link to='/create'>Create an account to follow and more!</Link></h3>) }
          </div> }
        </div>
        </div>
      </div>
      <br />
      <div className="focus-column">
        <h2>About</h2>
      <h3>Category: </h3>
      <p> {item.category}</p>
       <div className="focus-biz-pro-contact">
         {(item.street !== "" && item.city !== "" && item.state !== "" && item.country !=="" && item.zip !=="") ?
        (<div>
        <h3>Address: </h3>
        <p>{item.street}</p>
        <p>{item.city}, {item.state}, {item.country} {item.zip}</p>
        </div>) : ('') }
        {(item.phone !== "") ? 
        (<div>
        <h3>Phone: </h3>
        <p>{item.phone}</p>
        </div>) : ('') }
        <div>
        {(item.website !=="") ? 
        (<div>
        <h3>Website: </h3>
        <a href={item.website} target="_blank">{item.website}</a>
        </div>) : ('') }
        </div>
        {(item.facebook !== "") ? 
        (<div>
        <a href={`https://www.facebook.com/${item.facebook}`} target="_blank"><h3>Facebook</h3></a>
        </div>) : ('') }
        {(item.instagram !== "") ? 
        (<div>
        <a href={`https://instagram.com/${item.instagram}`} target="_blank"><h3>Instagram</h3></a>
        </div>) : ('') }
        {(item.twitter !== "") ? 
        (<div>
        <a href={`https://mobile.twitter.com/${item.twitter}`} target="_blank"><h3>Twitter</h3></a>
        </div>) : ('') }
      </div>
      <br />
      </div>
      <br />
    </div>
    <br />    
    <div className="focus-invisible-array">
      <div className="focus-stat-item"><h3><Link to={`/shop/${item.sponsorid}`}>🛒 Products</Link></h3></div>
      <div className="focus-stat-item"><h3><Link to={`/shopgiveaways/${item.sponsorid}`}>⏱️ Active Giveaways</Link></h3></div>
      <div className="focus-stat-item"><h3><Link to={`/pastgiveaways/${item.sponsorid}`}>🎊 Expired Giveaways</Link></h3></div>
    </div>
    <div>
    {(tier > 0) ? (<div>
        <div>
      <h3>View Giveaways from {item.name}:</h3>
      </div>
      <div className="home-giveaways"> 
        {arr.map((giveaway) => {
                    return (
                       <div key={giveaway.gwayid} className="glass-front">
                          <div className="container-gwayswithbiz">
                          <div className="container-gwaytitle">
                          { giveaway.origintable === 'apigway' ? <Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link> : <Link target="_blank" to={`/instant/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link> }
                          { giveaway.origintable === 'apigway' ? <h3 className="title"><Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> : <h3 className="title"><Link target="_blank" to={`/instant/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> }
                            </div>
                            <p>by <Link target="_blank" to={`/business/${giveaway.sponsorid}`}>{giveaway.name}</Link></p>
                            </div>
                          { giveaway.origintable === 'apigway' ? <p><small>Ends: {new Date(giveaway.endon).toDateString()}</small></p> : <p><small>Instant Win</small></p> }
                        </div>
                    )
                })}
                </div>
      </div>) : (<div>
        <div>
                  <h3>View Similar Businesses:</h3>
                  </div>
                  <div className="home-businesses">
    {arr.map((business) => {
                 return (
                     <div key={business.sponsorid} className="glass-front">
                         <Link target="_blank" to={`/business/${business.sponsorid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${business.image}`} alt="" height="75" width="75" /></Link>
                         <h3 className="title"><Link target="_blank" to={`/business/${business.sponsorid}`}>{business.name}</Link></h3>
                         <p>{business.city}, {business.state}</p>
                     </div>
                 )
             })}
             </div>
      </div>)}
    </div>
    </div>)
}
    <br />
    <br />
    <br />
    <br />
    </div>
  );
}

export default BusinessDetails;