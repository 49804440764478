import React from "react";
import { AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";

const SponChartTotalThree = (props) => {

    return (
                 <div className="glass-chart-section">
           <h3 style={{ color: '#82ca9d' }}>Total Views (Giveaways, Products, and Business Profile)</h3>
           <h3 style={{ color: '#8884d8' }}>Business Profile Views</h3>
           <ResponsiveContainer width="90%" height={250}>
        <AreaChart data={props.allLogs}>
        <defs>
            <linearGradient id="colorProfile" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.75}/>
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0.2}/>
            </linearGradient>
            <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.75}/>
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.2}/>
            </linearGradient>
        </defs>
        <XAxis dataKey="date" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="Total Views" stroke="#82ca9d" fillOpacity={1} fill="url(#colorTotal)" />
        <Area type="monotone" dataKey="Profile Views" stroke="#8884d8" fillOpacity={1} fill="url(#colorProfile)" />
        </AreaChart>
        </ResponsiveContainer>
      </div>
     
    )
}

export default SponChartTotalThree;