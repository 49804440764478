import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Fuse = (props) => {
    
    const [winner, setWinner] = useState(props.winner);
    const [expired, setExpired] = useState(null);
    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');
    const [timerMs, setTimerMs] = useState('00');
    const [sponuser, setSponuser] = useState(() => JSON.parse(localStorage.getItem("sponuser")) || "");

    const countdownDate = new Date( props.date ).getTime();
    const now = new Date().getTime();
    const distance = countdownDate - now;

      const fuseQuery = async () => {
          const fetchWinner = await fetch(`${process.env.REACT_APP_API_URL}/b/random/${ props.id }`);
          const winner = await fetchWinner.json();
          return winner;
      }

      const messageCreate = async (gwayid, bizid, winnerid) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/b/giveawaymail/${gwayid}/${bizid}/${winnerid}`)
        .catch(err => Promise.reject('Message could not be sent!'));
        }  

useEffect(() => {
    let selector = setInterval(() => {

        const days = Math.floor(distance / (1000 * 60 * 60 *24));
        const hours = Math.floor((distance % (1000 * 60 * 60 *24) / (1000 * 60 * 60)));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);

        if ( distance < 0 ) {
            clearInterval(selector);
            setExpired(true);
        } else {
            setExpired(false);
        }

    }, 1000)
    return () => {
        clearInterval(selector);
    }
}, [expired])

useEffect(() => {
    let newInt = setInterval(() => {

        const ms = Math.floor((distance % (1000 * 60)));

        setTimerMs(ms);

        if ( distance < 0 && (expired) && !winner ) {
            clearInterval(newInt);
            fuseQuery().then(response => {
                if (response !== undefined) {
               return messageCreate(response[0].gwayid, response[0].sponsorid, response[0].winnerid);}
               else return
            }).catch(err => Promise.reject('Encountered an error!'));
        } else return
    }, 1000)

    return () => {
        clearInterval(newInt);
    }
}, [expired])

function didICreateIt() {
    return props.sponsor === sponuser.sponsorid;
  }

return (
  <div>
      { ( didICreateIt() && expired) ? 
        (<Link to={`/viewwinner/${props.id}` }>View Winner Information</Link>) : ''
        }
  </div>
);
};

export default Fuse;