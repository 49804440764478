import React, {useState, useEffect} from 'react';
import { isSponAuthenticated } from './repository';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const ItemView = ({ match }) => {

    const [item, setItem] = useState({});
    const [img, setImg] = useState([]);
    const [views, setViews] = useState([]);
    const [favs, setFavs] = useState(0);
    const [sponuser, setSponuser] = useState(() => JSON.parse(localStorage.getItem("sponuser")) || "");
    const [loading, setLoading] = useState(true);
    const [created, setCreated] = useState([]);
    const [timeSet, setTimeSet] = useState(false);
   
    const today = new Date().toISOString().slice(0, 10);

    const fetchItem = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/viewmyitem/${ match.params.itemid }`);
        const item = await fetchItem.json();
        setItem(item);
        const createdDate = (new Date(item.created_at)).toISOString().split('T')[0]
        setCreated(createdDate);
        setTimeSet(true);
    };

    const fetchViews = async () => {
        const fetchViews = await fetch(`${process.env.REACT_APP_ANALYTICS_URL}/g/totalitemviewsint/${created}/${ match.params.itemid }`);
        const views = await fetchViews.json();
        setViews(views);
    };

    useEffect(() => {
        fetchItem();
     },[]);

     useEffect(() => {
        if (timeSet === true) {
            return fetchViews();
        } else return
    }, [timeSet])

     const fetchImg = async () => {
        const fetchImg = await fetch(`${process.env.REACT_APP_API_URL}/b/mainitemimg/${ match.params.itemid }`);
        const img = await fetchImg.json();
        setImg(img);
        setLoading(false);
    };

    useEffect(() => {
        fetchImg();
     },[]);

    const fetchFavs = async () => {
        const fetchFav = await fetch(`${process.env.REACT_APP_API_URL}/b/entitems/${ match.params.itemid }`);
        const favs = await fetchFav.json();
        if (favs === false) {
             return
        } else
        setFavs(favs.length);
    };

    useEffect(() => {
        fetchFavs();
     },[]);

    return (
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
                <div className="glass-image-box">
            <Link to={`/imagedash/${ match.params.itemid }`}><img id="image" src={`${process.env.REACT_APP_CDN_URL}/${img.img1}`} alt="" height="120" width="120" /></Link><br />
           { ( isSponAuthenticated() ) ? 
            <Link to={`/imagedash/${ match.params.itemid }`}>Add Images to this Product</Link> : ''
            }
            </div>
            <h3><Link to={`/editproduct/${match.params.itemid}`}>Edit Product</Link></h3>
            <h1>{item.itemname}</h1>
            <h3>{item.itemcategory}</h3>
            <h3>{item.description}</h3>
            {(item.listingactive === true) ? (<h3 className="focus-contrast">Product is displayed in Shop</h3>) : (<h3 className="focus-contrast-rust">Product is not displayed in Shop</h3>)}
            <div className="focus-invisible-array">
            <div className="focus-stat-item"><h1>${item.value}</h1><h3>Price</h3></div>
            <div className="focus-stat-item"><h1>{views.views}</h1><h3>Product Views</h3></div>
            <div className="focus-stat-item"><h1>{favs}</h1><h3>Product Favorites</h3></div>
            </div>
            <Link to={`/products/${ match.params.itemid }`}><h3>Public View</h3></Link>
            <h2><Link to={`/creategiveaway`}>Create a Giveaway</Link></h2>
            <br />
            </div>)
}
            <br />
            <br />
            <br />
            <br />
        </div>
      );

}

export default ItemView;