import React, { useEffect, useState } from 'react';
import Spinner from './Spinner';
import WeeklySiteGetsChartNew from './WeeklySiteGetsChartNew';
import MonthlySiteGetsChartNew from './MonthlySiteGetsChartNew';
import { Link } from 'react-router-dom';

const AdminDash = (props) => {

  const [api, setApi] = useState([]);
  const [loading, setLoading] = useState(true);
  const [weeklyGetsChartOpen, setWeeklyGetsChartOpen] = useState(false);
  const [monthlyGetsChartOpen, setMonthlyGetsChartOpen] = useState(false);

    useEffect(()=>{
        fetchApi();
    }, [])

    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/admindash`);
        const api =  await fetchApi.json();
        setApi(api);
        setLoading(false);
    };

  const logOut = () => {
    localStorage.removeItem('x-admin-key');
    localStorage.removeItem('x-admin-key-expiration');
  }

  const weeklyGetsChartToggler = () => {
    if (weeklyGetsChartOpen === false) {
        return setWeeklyGetsChartOpen(true);
    } else {
        return setWeeklyGetsChartOpen(false);
    }
}

const monthlyGetsChartToggler = () => {
  if (monthlyGetsChartOpen === false) {
      return setMonthlyGetsChartOpen(true);
  } else {
      return setMonthlyGetsChartOpen(false);
  }
}

return (
    <div>
      <br />
      <br />
      <br />
      {
        (loading) ? ( <Spinner /> ) : (
      <div>
        <h2 onClick={logOut}><a href="/">Log Out</a></h2>
      <h1>{api.Admin}</h1>
      <br />
      <h3><Link to={`/adminreports`}>View Reported Giveaways</Link></h3>
      <br />
      <div>
              <button className="glass-sort-button" onClick={() => weeklyGetsChartToggler()}>View Weekly Get Requests Chart:</button>
                {weeklyGetsChartOpen && (
               <div className="glass-chart">
               <WeeklySiteGetsChartNew />
               </div>
                )}
            </div>
            <br />
            <div>
              <button className="glass-sort-button" onClick={() => monthlyGetsChartToggler()}>View Monthly Get Requests Chart:</button>
                {monthlyGetsChartOpen && (
               <div className="glass-chart">
               <MonthlySiteGetsChartNew />
               </div>
                )}
            </div>
            <br />
            <p>Business logged-in route views: </p>
      <p>Entrant logged-in route views: </p>
      <p>Admin route views: </p>
      <p>Total views of Giveaways: </p>
      <p>Total views of Products: </p>
      <p>Total views of Business profiles: </p>
      <p>Total views of Search Results page: </p>
      <p>Other site views accessible without logging in: </p>
      <p>Total Giveaways created: </p>
      <p>Total Products listed: </p>
      <p>Total Entrant Users: </p>
      <p>Total Business Users: </p>
      <p>Total Entries in Giveaways: </p>
      <p>Total Follows: </p>
      <p>Total Favorites: </p>
      </div>)
    }
      <br />
            <br />
            <br />
            <br />
    </div>
)

}

export default AdminDash;