import React, {useState, useEffect, useRef} from 'react';
import { isAuthenticated } from './repository';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Fuse from './Fuse';
import Spinner from './Spinner';
import SpinnerEntry from './SpinnerEntry';

function GiveawayDetails({ match }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    const [item, setItem] = useState({});
    const [user] = useState(() => JSON.parse(localStorage.getItem("user")) || "");
    const [views, setViews] = useState([]);
    const [userlocation, setUserLocation] = useState("");
    const [region, setRegion] = useState("");
    const [elligible, setElligible] = useState(null);
    const [entered, setEntered] = useState("");
    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');
    const [loading, setLoading] = useState(true);
    const [winnerID, setWinnerID] = useState("");
    const [timeSet, setTimeSet] = useState(false);
    const [startTime, setStartTime] = useState([]);
    const [endTime, setEndTime] = useState([]);
    const [tier, setTier] = useState([]);
    const [isSet, setIsSet] = useState(false);
    const [arr, setArr] = useState([]);  
    const [clicked, setClicked] = useState(false);  
    const [message, setMessage] = useState([]);
    const [thinking, setThinking] = useState(false);

    const today = new Date().toISOString().slice(0, 10);

    useEffect(() => {
      fetchItem();
  },[]);

  useEffect(() => {
    fetchEntered();
  }, [])

    const userLocationFetcher = () => {
      try {
      axios.get(`${process.env.REACT_APP_API_URL}/b/mylocation/${user.entrantid}`, { headers: { 'x-access-token': localStorage.getItem('x-access-token')} })
      .then(res => {
          setUserLocation(res.data);
      }) } catch (error){
          return
      }
  };

  const fetchEntered = async () => {
    const fetchIt = await fetch(`${process.env.REACT_APP_API_URL}/b/amientered/${ match.params.gwayid }`, { headers: { 'x-access-token': localStorage.getItem('x-access-token')} });
    const it = await fetchIt.json();
    setEntered(it);
    setLoading(false);
};

const fetchWon = async () => {
  const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/winner/${ match.params.gwayid }`);
  const item = await fetchItem.json();
  setWinnerID(item);
};

  useEffect(() => {
    let newInt = setInterval(() => {

        if (user) {
            clearInterval(newInt);
            return userLocationFetcher();
        } else return
    }, 1000)

    return () => {
        clearInterval(newInt);
    }
}, [user])

    const fetchItem = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/active/${ match.params.gwayid }`);
        if (fetchItem.status === 400) {
          window.location = "/notfound"
        }
        const item = await fetchItem.json();
        setStartTime((new Date(item.starton)).toISOString().split('T')[0]);
        setEndTime((new Date(item.endon)).toISOString().split('T')[0]);
        setTimeSet(true);
        setItem(item);
        setRegion(item.location);
        setTier(item.spontier);
        setIsSet(true);
    };

    const fetchArr = async () => {
      if (tier > 0) {
      const fetchArrInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/sidegiveawaysonereduxcombo/${item.sponsorid}/${today}/${match.params.gwayid}`);
      const info = await fetchArrInfo.json();
      setArr(info);
    } else {
      const fetchArrInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/sidegiveawayszerocombo/${today}`);
      const info = await fetchArrInfo.json();
      setArr(info);
    }
  };

  useEffect(() => {
    if (isSet === true) {
      fetchArr();
    } else return
  }, [isSet])

   async function createEntGway (data) {
    setClicked(true)
      return axios.post(`${process.env.REACT_APP_API_URL}/b/entgways/${ match.params.gwayid }`, { apientrantid: user.entrantid }, {headers: { 'x-access-token':  localStorage.getItem('x-access-token') } } )
      .then(response => {
        setMessage(response.data)
        setThinking(false);
      return response.status
      })
      .catch(err => Promise.reject('Giveaway could not be entered!'));
      }

     async function leaveGiveaway (data) {
      setClicked(true)
        return axios.delete(`${process.env.REACT_APP_API_URL}/b/entgways/${ match.params.gwayid }`, { headers: { 'x-access-token':  localStorage.getItem('x-access-token') }, data: { apientrantid: user.entrantid } } )
        .then(response => {
          setMessage(response.data)
          setThinking(false);
        return response.status
        })
        .catch(err => Promise.reject('Could not leave Giveaway!'));
        }
  
function submitCreate(event){
  event.preventDefault();
  setThinking(true);
  createEntGway()
  .catch(err => alert(err));
  };

  function submitLeave(event){
    event.preventDefault();
    setThinking(true);
    leaveGiveaway()
    .catch(err => alert(err));
    };

let interval = useRef();

const startTimer = () => {

    interval = setInterval(() => {

        const countdownDate = new Date( item.endon ).getTime();
        const now = new Date().getTime();
        const distance = countdownDate - now;
  
        const days = Math.floor(distance / (1000 * 60 * 60 *24));
        const hours = Math.floor((distance % (1000 * 60 * 60 *24) / (1000 * 60 * 60)));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance > 0) {
          setTimerDays(days);
          setTimerHours(hours);
          setTimerMinutes(minutes);
          setTimerSeconds(seconds);
        } else {
            clearInterval(interval.current);
        }
     }, 1000);
};

useEffect(() => {
startTimer();
return () => {
    clearInterval(interval.current);
};
});

useEffect(() => {

  const countDown = new Date( item.endon ).getTime();
  const rn = new Date().getTime();
  const dis = countDown - rn;

  if ((dis > 0) && (userlocation.state == region)) {
    setElligible(true)
  } if ((dis > 0) && (region == "United States")) {
    setElligible(true)
  } if (dis < 0) {
    setElligible(false)
  }

}, [userlocation, region])

function isEnded() {
  const count = new Date( item.endon ).getTime();
  const rightnow = new Date().getTime();
  const dif = count - rightnow;
  return dif < 0;
}

function didIWinIt() {
  return winnerID.winnerid === user.entrantid;
}

useEffect(() => {
  fetchWon();
}, [])

const parsedTime = new Date(item.endon);
const newTime = parsedTime.toUTCString();

  return (
    <div>
      <br />
      <br />
      <br />
      {
        (loading) ? ( <Spinner /> ) : (
    <div className="glass-giveaway">
    <br />
      <div className="glass-giveaway-details">
      {isEnded() ? (<h3 className="focus-ended">⚠️ Giveaway is not live! ⚠️</h3>) : ('')}
      <Link to={`/products/${item.prizeid}`}><img id="image" src={`${process.env.REACT_APP_CDN_URL}/${item.img1}`} alt="" height="180" width="180" /></Link>
       <h1>{item.itemname}</h1>
        <h2>Giveaway by <Link to={`/business/${item.sponsorid}`}>{item.name}</Link></h2>
        <h3>Prize: <Link to={`/products/${item.prizeid}`}>{item.itemname}</Link></h3>
        <p>Category: {item.itemcategory}</p>
        <p>Description: {item.description}</p>
         <p>Value: ${item.value}</p>
         </div>
         <div className="giveaway-countdown">
                <h3>Giveaway ends: </h3>
                {( isEnded() ) ? (<div><h3>Giveaway has ended!</h3></div>) : (<h3>{timerDays} Days {timerHours} Hours {timerMinutes} Minutes {timerSeconds} Seconds</h3>)}
                {( didIWinIt() ? (<div><h3>You won this Giveaway!</h3><h3><Link to={`/writereview/${ match.params.gwayid }`}>After you receive your Prize, please leave a Review for this Business</Link></h3>
                <p>or</p>
                <h3><Link to={`/composemail/${ match.params.gwayid }`}>Send a Message to {item.name}</Link></h3>
                </div>) : (""))}
         </div>
         <div className="additional-giveaway-info">
         <h3>Additional Infomation:</h3>
        <p>This Giveaway is exclusively available to {item.location} residents.</p>
        <p>Winner chosen at {newTime}</p>
        </div>
        <div className="additional-giveaway-info">
          <h3>Rules: </h3>
          <p className="focus-rules-text">{item.rules}</p>
          </div>
         <div className="giveaway-button">
         <Fuse
          date={ item.endon }
          id={ match.params.gwayid }
          winner= { item.winnerid }
          sponsor= { item.sponsorid }
         />
         <br />
         { (thinking === true) ? <div><SpinnerEntry /></div> :
         <div>
         { ( isAuthenticated() && elligible && (entered === false) && (!clicked) ) ? <button className="january-form-submit-button" onClick={submitCreate}> Enter Giveaway </button> : ( isAuthenticated() && elligible && (entered === true) && (!clicked) ) ? <button className="january-form-submit-button" onClick={submitLeave}> Leave Giveaway </button> : ( isAuthenticated() && elligible && (entered === false) && (clicked) ) ? (<h2>{message.message}</h2>) : ( isAuthenticated() && elligible && (entered === true) && (clicked) ) ? (<h2>{message.message}</h2>) : (<h2><Link to='/create'>Create an account to enter this Giveaway and so much more!</Link></h2>) }
         </div> }
         <br />
         <br />
         </div>
        <br />
        <div> {(tier > 0) ? (<h3>View More Giveaways from {item.name}:</h3>) : (<h3>View More Giveaways:</h3>)}</div>
        <div className="home-giveaways"> 
        {arr.map((giveaway) => {
                    return (
                        <div key={giveaway.gwayid} className="glass-front">
                          <div className="container-gwayswithbiz">
                          <div className="container-gwaytitle">
                          { giveaway.origintable === 'apigway' ? <Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link> : <Link target="_blank" to={`/instant/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link> }
                          { giveaway.origintable === 'apigway' ? <h3 className="title"><Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> : <h3 className="title"><Link target="_blank" to={`/instant/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> }
                            </div>
                            <p>by <Link target="_blank" to={`/business/${giveaway.sponsorid}`}>{giveaway.name}</Link></p>
                            </div>
                          { giveaway.origintable === 'apigway' ? <p><small>Ends: {new Date(giveaway.endon).toDateString()}</small></p> : <p><small>Instant Win</small></p> }
                        </div>
                    )
                })}
                </div>
        <br />
        <p>Does this Giveaway break the rules?</p>
        <Link to={`/reportgiveaway/${match.params.gwayid}`}><p>Report</p></Link>
        <br />
        </div>
        )
}
            <br />
            <br />
            <br />
            <br />
    </div>
  );
}

export default GiveawayDetails;