import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import Modal from './Modal';

const CreateGiveawayHooks = (props) => {

const initialInputState = { gwayname: '', starton: '', endon: '', gwaycategory: '', prizeid: '', rules: '', location: '' };
const [eachEntry, setEachEntry] = useState(initialInputState);
const {gwayname, starton, endon, gwaycategory, prizeid, rules, location} = eachEntry;
const [sponuser] = useState(JSON.parse(localStorage.getItem('sponuser')))
const [items, setItems] = useState([]);
const [info, setInfo] = useState([]);
const [loading, setLoading] = useState(true);
const [tier, setTier] = useState(sponuser.spontier);
const [hasImg, setHasImg] = useState('');

const [isInstantWin, setIsInstantWin] = useState(null);
const [display, setDisplay] = useState('');

const initialInstantState = { instantprizeid: '', instantwinstarton: '', instantlocation: '', instantrules: '', total: '', winners: '' };
const [eachInstantEntry, setEachInstantEntry] = useState(initialInstantState);
const {instantprizeid, instantwinstarton, instantlocation, instantrules, total, winners} = eachInstantEntry;

const [modalOpen, setModalOpen] = useState(false);
 
//const today = new Date(Date.now()).toISOString().replace('T',' ').replace('Z','');
const today = new Date(Date.now()).toISOString();

async function giveawayCreate (sponsorid, data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/b/giveaways`, { sponsorid: sponsorid, starton: data.starton, endon: data.endon, prizeid: data.prizeid, location: data.location, name: sponuser.name }, {headers: { 'x-api-key':  localStorage.getItem('x-api-key') } } )
    .then(response => {
    return response.status
    })
    .catch(err => Promise.reject('Giveaway could not be created!'));
    }

async function giveawayDateNowCreate (sponsorid, data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/b/giveaways`, { sponsorid: sponsorid, starton: today, endon: data.endon, prizeid: data.prizeid, location: data.location, name: sponuser.name }, {headers: { 'x-api-key':  localStorage.getItem('x-api-key') } } )
    .then(response => {
    return response.status
    })
    .catch(err => Promise.reject('Giveaway could not be created!'));
    }

const fetchInfo = async () => {
    const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/content/createnewgwayinfo`);
    const info =  await fetchInfo.json();
    setInfo(info);
    setLoading(false);
};

const fetchItemImgBool = async () => {
    if (prizeid === '') {
        return
    } else {
    const validate = await fetch(`${process.env.REACT_APP_API_URL}/b/itemhasimage/${prizeid}`);
    const val = await validate.json();
    setHasImg(val);
    }
};

const handleInputChange = async e => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  const handleFinalSubmit = async e => {
      if (tier > 0 && hasImg === true) {
      e.preventDefault();
      giveawayCreate(sponuser.sponsorid, eachEntry)
      .then(token => window.location = '/businessreturn')
      .catch(err => alert(err));
      } if (tier === 0 && hasImg === true) {
        e.preventDefault();
        giveawayDateNowCreate(sponuser.sponsorid, eachEntry)
        .then(token => window.location = '/businessreturn')
        .catch(err => alert(err));
      }  if (tier > 0 && hasImg === false) {
          return alert("Product must have an image!")
      }  if (tier === 0 && hasImg === false) {
        return alert("Product must have an image!")
    }
  };

useEffect(()=>{
    fetchInfo();
}, [])

useEffect(() => {
  fetchItems();
}, [])

  const fetchItems = async () => {
    const fetchItems = await fetch(`${process.env.REACT_APP_API_URL}/b/sponitemlist/${ sponuser.sponsorid }`);
    const items = await fetchItems.json();
    setItems(items);
};

function isPremium() { 
    return tier > 0;
}

useEffect(() => {
    fetchItemImgBool();
}, [prizeid])

const handleDisplayChange = e => {
    setDisplay(e.target.value);
  };

  useEffect(() => {
    if (display === "Classic") {
        setIsInstantWin(false);
    } if (display === "Instant Win") {
        setIsInstantWin(true);
    }
  }, [display])

  const handleInstantInput = async e => {
    setEachInstantEntry({ ...eachInstantEntry, [e.target.name]: e.target.value });
  };

  const handleInstantSubmit = async e => {
    if (tier > 0 && hasImg === true) {
      e.preventDefault();
      instantGiveawayCreate(sponuser.sponsorid, eachInstantEntry)
      .then(token => window.location = '/businessreturn')
      .catch(err => alert(err));
      } if (tier === 0 && hasImg === true) {
        e.preventDefault();
        instantGiveawayCreateDateNow(sponuser.sponsorid, eachInstantEntry)
        .then(token => window.location = '/businessreturn')
        .catch(err => alert(err));
    } if (tier > 0 && hasImg === false) {
        return alert("Product must have an image!")
    } if (tier === 0 && hasImg === false) {
      return alert("Product must have an image!")
      }
  };

  async function instantGiveawayCreate (sponsorid, data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/b/instantwin`, { sponsorid: sponsorid, prizeid: data.instantprizeid, instantwinstarton: data.instantwinstarton, location: data.instantlocation, name: sponuser.name, total: parseInt(data.total), winners: parseInt(data.winners) }, {headers: { 'x-api-key':  localStorage.getItem('x-api-key') } } )
    .then(response => {
    return response.status
    })
    .catch(err => Promise.reject('Giveaway could not be created!'));
    }

  async function instantGiveawayCreateDateNow (sponsorid, data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/b/instantwin`, { sponsorid: sponsorid, prizeid: data.instantprizeid, instantwinstarton: today, location: data.instantlocation, name: sponuser.name, total: parseInt(data.total), winners: parseInt(data.winners) }, {headers: { 'x-api-key':  localStorage.getItem('x-api-key') } } )
    .then(response => {
    return response.status
    })
    .catch(err => Promise.reject('Giveaway could not be created!'));
    }

    const fetchItemImgBoolInstant = async () => {
        if (instantprizeid === '') {
            return
        } else {
        const validate = await fetch(`${process.env.REACT_APP_API_URL}/b/itemhasimage/${instantprizeid}`);
        const val = await validate.json();
        setHasImg(val);
        }
    };

    useEffect(() => {
        fetchItemImgBoolInstant();
    }, [instantprizeid])

    const odds = (winners/total) * 100;

    function calculateOdds () {
        return total !== "" && winners !== "";
    }

    const toggleModal = async (e) => {
        e.preventDefault();
        setModalOpen(!modalOpen);
      }
/**
      const disablePast = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

    const minimumEnd = () => {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const dd = String(tomorrow.getDate() + 1).padStart(2, "0");
        const mm = String(tomorrow.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = tomorrow.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };
*/

var tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
const tom = new Date(tomorrow).toISOString().slice(0, 10)
const tod = new Date(today).toISOString().slice(0, 10)

    return (
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div>
            <div className="focus-div">
                { isPremium() ? 
                (<div>
             <h1>{info.gwayInfo}, {sponuser.name}!</h1>
             <h3><Link to="/guide">Read our Giveaway Guide for tips on creating Giveaways!</Link></h3>
             <br />
             <div className="focus-login">
             <div className="form-group">
             <label><h3>1. Select Type of Giveaway: </h3></label>
                <select className="form-control" onChange={handleDisplayChange}>
                        <option value={"Classic"}>Classic/Countdown Giveaway</option>
                        <option value={"Instant Win"}>Instant Win Giveaway</option>                     
                    </select>
                </div>
{ isInstantWin ? (   <div>
        <form onSubmit={toggleModal}>
        <div className="form-group">
        <label><h3>2. Prize: </h3></label>
        <select name="instantprizeid" className="form-control" onChange={handleInstantInput}>
                 <option value=""></option>
                 {items.map(items => (<option key={items.itemid} value={items.itemid}>{items.itemname}</option>))}
               </select>
        </div>
        <div className="form-group">
        <label><h3>3. Start Date: </h3></label>
        <input type="date" className="form-control" name="instantwinstarton"
        onChange={handleInstantInput} min={tod}/>
        </div>
         <div className="form-group">
        <label><h3>4. Location Available: </h3></label>
        <select name="instantlocation" className="form-control" onChange={handleInstantInput}>
                 <option value=""></option>
                 <option value="United States">United States</option>
                 <option value="Alabama">Alabama</option>
                 <option value="Alaska">Alaska</option>
                 <option value="Arizona">Arizona</option>
                 <option value="Arkansas">Arkansas</option>
                 <option value="California">California</option>
                 <option value="Colorado">Colorado</option>
                 <option value="Connecticut">Connecticut</option>
                 <option value="Delaware">Delaware</option>
                 <option value="Florida">Florida</option>
                 <option value="Georgia">Georgia</option>
                 <option value="Hawaii">Hawaii</option>
                 <option value="Idaho">Idaho</option>
                 <option value="Illinois">Illinois</option>
                 <option value="Indiana">Indiana</option>
                 <option value="Iowa">Iowa</option>
                 <option value="Kansas">Kansas</option>
                 <option value="Kentucky">Kentucky</option>
                 <option value="Louisiana">Louisiana</option>
                 <option value="Maine">Maine</option>
                 <option value="Maryland">Maryland</option>
                 <option value="Massachusetts">Massachusetts</option>
                 <option value="Michigan">Michigan</option>
                 <option value="Minnesota">Minnesota</option>
                 <option value="Mississippi">Mississippi</option>
                 <option value="Missouri">Missouri</option>
                 <option value="Montana">Montana</option>
                 <option value="Nebraska">Nebraska</option>
                 <option value="Nevada">Nevada</option>
                 <option value="New Hampshire">New Hampshire</option>
                 <option value="New Jersey">New Jersey</option>
                 <option value="New Mexico">New Mexico</option>
                 <option value="New York">New York</option>
                 <option value="North Carolina">North Carolina</option>
                 <option value="North Dakota">North Dakota</option>
                 <option value="Ohio">Ohio</option>
                 <option value="Oklahoma">Oklahoma</option>
                 <option value="Oregon">Oregon</option>
                 <option value="Pennsylvania">Pennsylvania</option>
                 <option value="Rhode Island">Rhode Island</option>
                 <option value="South Carolina">South Carolina</option>
                 <option value="South Dakota">South Dakota</option>
                 <option value="Tennessee">Tennessee</option>
                 <option value="Texas">Texas</option>
                 <option value="Utah">Utah</option>
                 <option value="Vermont">Vermont</option>
                 <option value="Virginia">Virginia</option>
                 <option value="Washington">Washington</option>
                 <option value="West Virginia">West Virginia</option>
                 <option value="Wisconsin">Wisconsin</option>
                 <option value="Wyoming">Wyoming</option>
                 <option value="D.C.">District of Columbia</option>
                 <option value="Puerto Rico">Puerto Rico</option>
                 <option value="Guam">Guam</option>
                 <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                 <option value="Northern Mariana Islands">Northern Mariana Islands</option>
               </select>
        </div>
        <div className="form-group">
        <label><h3>5. Total Number of Winners: </h3></label>
        <input type="number" name="winners" min="1" className="form-control" onChange={handleInstantInput}></input>
        </div>
        <div className="form-group">
        <label><h3>6. Target Number of Entries: </h3></label>
        <input type="number" name="total" min="1" className="form-control" onChange={handleInstantInput}></input>
        {calculateOdds() ? (<p>Odds of winning: {winners} out of {total} or {odds.toLocaleString("en-US")}% per entry.</p>) : ('')}
        </div>
        <br />
        <button type="submit" className="february-form-submit-button"> Create </button>
        </form>
        </div>) :
            (
             <div>
        <form onSubmit={toggleModal}>
        <div className="form-group">
        <label><h3>2. Start Date: </h3></label>
        <input type="date" className="form-control" name="starton"
        onChange={handleInputChange} min={tod}/>
        </div>
        <div className="form-group">
        <label><h3>3. End Date: </h3></label>
        <input type="date" className="form-control" name="endon"
        onChange={handleInputChange} min={tom}/>
        </div>
        <div className="form-group">
        <label><h3>4. Prize: </h3></label>
        <select name="prizeid" className="form-control" onChange={handleInputChange}>
                 <option value=""></option>
                 { items.map(items => ( <option key={items.itemid} value={items.itemid}>{items.itemname}</option> )) }
               </select>
        </div>
         <div className="form-group">
        <label><h3>5. Location Available: </h3></label>
        <select name="location" className="form-control" onChange={handleInputChange}>
                 <option value=""></option>
                 <option value="United States">United States</option>
                 <option value="Alabama">Alabama</option>
                 <option value="Alaska">Alaska</option>
                 <option value="Arizona">Arizona</option>
                 <option value="Arkansas">Arkansas</option>
                 <option value="California">California</option>
                 <option value="Colorado">Colorado</option>
                 <option value="Connecticut">Connecticut</option>
                 <option value="Delaware">Delaware</option>
                 <option value="Florida">Florida</option>
                 <option value="Georgia">Georgia</option>
                 <option value="Hawaii">Hawaii</option>
                 <option value="Idaho">Idaho</option>
                 <option value="Illinois">Illinois</option>
                 <option value="Indiana">Indiana</option>
                 <option value="Iowa">Iowa</option>
                 <option value="Kansas">Kansas</option>
                 <option value="Kentucky">Kentucky</option>
                 <option value="Louisiana">Louisiana</option>
                 <option value="Maine">Maine</option>
                 <option value="Maryland">Maryland</option>
                 <option value="Massachusetts">Massachusetts</option>
                 <option value="Michigan">Michigan</option>
                 <option value="Minnesota">Minnesota</option>
                 <option value="Mississippi">Mississippi</option>
                 <option value="Missouri">Missouri</option>
                 <option value="Montana">Montana</option>
                 <option value="Nebraska">Nebraska</option>
                 <option value="Nevada">Nevada</option>
                 <option value="New Hampshire">New Hampshire</option>
                 <option value="New Jersey">New Jersey</option>
                 <option value="New Mexico">New Mexico</option>
                 <option value="New York">New York</option>
                 <option value="North Carolina">North Carolina</option>
                 <option value="North Dakota">North Dakota</option>
                 <option value="Ohio">Ohio</option>
                 <option value="Oklahoma">Oklahoma</option>
                 <option value="Oregon">Oregon</option>
                 <option value="Pennsylvania">Pennsylvania</option>
                 <option value="Rhode Island">Rhode Island</option>
                 <option value="South Carolina">South Carolina</option>
                 <option value="South Dakota">South Dakota</option>
                 <option value="Tennessee">Tennessee</option>
                 <option value="Texas">Texas</option>
                 <option value="Utah">Utah</option>
                 <option value="Vermont">Vermont</option>
                 <option value="Virginia">Virginia</option>
                 <option value="Washington">Washington</option>
                 <option value="West Virginia">West Virginia</option>
                 <option value="Wisconsin">Wisconsin</option>
                 <option value="Wyoming">Wyoming</option>
                 <option value="D.C.">District of Columbia</option>
                 <option value="Puerto Rico">Puerto Rico</option>
                 <option value="Guam">Guam</option>
                 <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                 <option value="Northern Mariana Islands">Northern Mariana Islands</option>
               </select>
        </div>
        <br />
        <button type="submit" className="february-form-submit-button"> Create </button>
        </form>
        </div>
            )
            }
            <br />
            </div>
            <br />
        </div>
        ) : (
        <div>
             <h1>{info.gwayInfo}, {sponuser.name}!*</h1>
             <h3><Link to="/guide">Read our Giveaway Guide for tips on creating Giveaways!</Link></h3>
             <br />
             <div className="focus-login">
             <div className="form-group">
                <label><h3>1. Select Type of Giveaway: </h3></label>
                <select className="form-control" onChange={handleDisplayChange}>
                        <option value={"Classic"}>Classic/Countdown Giveaway</option>
                        <option value={"Instant Win"}>Instant Win Giveaway</option>                     
                    </select>
                </div>
             { isInstantWin ? (<div>
        <form onSubmit={toggleModal}>
        <div className="form-group">
        <label><h3>2. Prize: </h3></label>
        <select name="instantprizeid" className="form-control" onChange={handleInstantInput}>
                 <option value=""></option>
                 { items.map(items => ( <option key={items.itemid} value={items.itemid}>{items.itemname}</option> )) }
               </select>
        </div>
         <div className="form-group">
        <label><h3>3. Location Available: </h3></label>
        <select name="instantlocation" className="form-control" onChange={handleInstantInput}>
                 <option value=""></option>
                 <option value="United States">United States</option>
                 <option value="Alabama">Alabama</option>
                 <option value="Alaska">Alaska</option>
                 <option value="Arizona">Arizona</option>
                 <option value="Arkansas">Arkansas</option>
                 <option value="California">California</option>
                 <option value="Colorado">Colorado</option>
                 <option value="Connecticut">Connecticut</option>
                 <option value="Delaware">Delaware</option>
                 <option value="Florida">Florida</option>
                 <option value="Georgia">Georgia</option>
                 <option value="Hawaii">Hawaii</option>
                 <option value="Idaho">Idaho</option>
                 <option value="Illinois">Illinois</option>
                 <option value="Indiana">Indiana</option>
                 <option value="Iowa">Iowa</option>
                 <option value="Kansas">Kansas</option>
                 <option value="Kentucky">Kentucky</option>
                 <option value="Louisiana">Louisiana</option>
                 <option value="Maine">Maine</option>
                 <option value="Maryland">Maryland</option>
                 <option value="Massachusetts">Massachusetts</option>
                 <option value="Michigan">Michigan</option>
                 <option value="Minnesota">Minnesota</option>
                 <option value="Mississippi">Mississippi</option>
                 <option value="Missouri">Missouri</option>
                 <option value="Montana">Montana</option>
                 <option value="Nebraska">Nebraska</option>
                 <option value="Nevada">Nevada</option>
                 <option value="New Hampshire">New Hampshire</option>
                 <option value="New Jersey">New Jersey</option>
                 <option value="New Mexico">New Mexico</option>
                 <option value="New York">New York</option>
                 <option value="North Carolina">North Carolina</option>
                 <option value="North Dakota">North Dakota</option>
                 <option value="Ohio">Ohio</option>
                 <option value="Oklahoma">Oklahoma</option>
                 <option value="Oregon">Oregon</option>
                 <option value="Pennsylvania">Pennsylvania</option>
                 <option value="Rhode Island">Rhode Island</option>
                 <option value="South Carolina">South Carolina</option>
                 <option value="South Dakota">South Dakota</option>
                 <option value="Tennessee">Tennessee</option>
                 <option value="Texas">Texas</option>
                 <option value="Utah">Utah</option>
                 <option value="Vermont">Vermont</option>
                 <option value="Virginia">Virginia</option>
                 <option value="Washington">Washington</option>
                 <option value="West Virginia">West Virginia</option>
                 <option value="Wisconsin">Wisconsin</option>
                 <option value="Wyoming">Wyoming</option>
                 <option value="D.C.">District of Columbia</option>
                 <option value="Puerto Rico">Puerto Rico</option>
                 <option value="Guam">Guam</option>
                 <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                 <option value="Northern Mariana Islands">Northern Mariana Islands</option>
               </select>
        </div>
        <div className="form-group">
        <label><h3>4. Total Number of Winners: </h3></label>
        <input type="number" name="winners" min="1" className="form-control" onChange={handleInstantInput}></input>
        </div>
        <div className="form-group">
        <label><h3>5. Target Number of Entries: </h3></label>
        <input type="number" name="total" min="1" className="form-control" onChange={handleInstantInput}></input>
        {calculateOdds() ? (<p>Odds of winning: {winners} out of {total} or {odds.toLocaleString("en-US")}% per entry.</p>) : ('')}
        </div>
        <br />
        <button type="submit" className="february-form-submit-button"> Create </button>
        </form>
        </div>) :
            (
             <div>
        <form onSubmit={toggleModal}>
        <div className="form-group">
        <label><h3>2. End Date: </h3></label>
        <input type="date" className="form-control" name="endon"
        onChange={handleInputChange} min={tom}/>
        </div>
        <div className="form-group">
        <label><h3>3. Prize: </h3></label>
        <select name="prizeid" className="form-control" onChange={handleInputChange}>
                 <option value="select">{prizeid}</option>
                 { items.map(items => ( <option key={items.itemid} value={items.itemid}>{items.itemname}</option> )) }
               </select>
        </div>
         <div className="form-group">
        <label><h3>4. Location Available: </h3></label>
        <select name="location" className="form-control" onChange={handleInputChange}>
                 <option value="select">{location}</option>
                 <option value="United States">United States</option>
                 <option value="Alabama">Alabama</option>
                 <option value="Alaska">Alaska</option>
                 <option value="Arizona">Arizona</option>
                 <option value="Arkansas">Arkansas</option>
                 <option value="California">California</option>
                 <option value="Colorado">Colorado</option>
                 <option value="Connecticut">Connecticut</option>
                 <option value="Delaware">Delaware</option>
                 <option value="Florida">Florida</option>
                 <option value="Georgia">Georgia</option>
                 <option value="Hawaii">Hawaii</option>
                 <option value="Idaho">Idaho</option>
                 <option value="Illinois">Illinois</option>
                 <option value="Indiana">Indiana</option>
                 <option value="Iowa">Iowa</option>
                 <option value="Kansas">Kansas</option>
                 <option value="Kentucky">Kentucky</option>
                 <option value="Louisiana">Louisiana</option>
                 <option value="Maine">Maine</option>
                 <option value="Maryland">Maryland</option>
                 <option value="Massachusetts">Massachusetts</option>
                 <option value="Michigan">Michigan</option>
                 <option value="Minnesota">Minnesota</option>
                 <option value="Mississippi">Mississippi</option>
                 <option value="Missouri">Missouri</option>
                 <option value="Montana">Montana</option>
                 <option value="Nebraska">Nebraska</option>
                 <option value="Nevada">Nevada</option>
                 <option value="New Hampshire">New Hampshire</option>
                 <option value="New Jersey">New Jersey</option>
                 <option value="New Mexico">New Mexico</option>
                 <option value="New York">New York</option>
                 <option value="North Carolina">North Carolina</option>
                 <option value="North Dakota">North Dakota</option>
                 <option value="Ohio">Ohio</option>
                 <option value="Oklahoma">Oklahoma</option>
                 <option value="Oregon">Oregon</option>
                 <option value="Pennsylvania">Pennsylvania</option>
                 <option value="Rhode Island">Rhode Island</option>
                 <option value="South Carolina">South Carolina</option>
                 <option value="South Dakota">South Dakota</option>
                 <option value="Tennessee">Tennessee</option>
                 <option value="Texas">Texas</option>
                 <option value="Utah">Utah</option>
                 <option value="Vermont">Vermont</option>
                 <option value="Virginia">Virginia</option>
                 <option value="Washington">Washington</option>
                 <option value="West Virginia">West Virginia</option>
                 <option value="Wisconsin">Wisconsin</option>
                 <option value="Wyoming">Wyoming</option>
                 <option value="D.C.">District of Columbia</option>
                 <option value="Puerto Rico">Puerto Rico</option>
                 <option value="Guam">Guam</option>
                 <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                 <option value="Northern Mariana Islands">Northern Mariana Islands</option>
               </select>
        </div>
        <br />
        <button type="submit" className="february-form-submit-button"> Create </button>
        </form>
        </div>
            )
        }
            <br />
            </div>
            <br />
        </div>
        )
        }
        </div>
        {modalOpen && <Modal
                    content={<>
                    <h2>Create Giveaway?</h2>
                    {isPremium() ? (<div>
                    { isInstantWin ? (<div>
                        <div className="signup-agree">
                        <p>By creating this Giveaway, you agree to send the selected prize to the winner(s) of this Giveaway.</p>
                            <p> <Link target="_blank" to='/terms'>See Terms and Conditions</Link>.</p>
                        </div>
                        <br />
                      <button onClick={handleInstantSubmit}> Create </button>   <button onClick={toggleModal}> Back </button> 
                    </div>) : (<div>
                        <div className="signup-agree">
                        <p>By creating this Giveaway, you agree to send the selected prize to the winner(s) of this Giveaway.</p>
                            <p> <Link target="_blank" to='/terms'>See Terms and Conditions</Link>.</p>
                        </div>
                        <br />
                        <button onClick={handleFinalSubmit}> Create </button>   <button onClick={toggleModal}> Back </button> 
                    </div>) }
                    </div>) : (<div>
                    { isInstantWin ? (<div>
                        <div className="signup-agree">
                        <p>By creating this Giveaway, you agree to send the selected prize to the winner(s) of this Giveaway.</p>
                            <p> <Link target="_blank" to='/terms'>See Terms and Conditions</Link>.</p>
                        </div>
                        <br />
                        <button onClick={handleInstantSubmit}> Create </button>   <button onClick={toggleModal}> Back </button> 
                    </div>) : (<div>
                        <div className="signup-agree">
                        <p>By creating this Giveaway, you agree to send the selected prize to the winner(s) of this Giveaway.</p>
                            <p> <Link target="_blank" to='/terms'>See Terms and Conditions</Link>.</p>
                        </div>
                        <br />
                        <button onClick={handleFinalSubmit}> Create </button>   <button onClick={toggleModal}> Back </button> 
                    </div>) }
                    </div>)}
                    <br />
                    </>}
                    handleClose={toggleModal}
                    />}
           </div>
        )
        }

        <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default CreateGiveawayHooks;