import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const MailSentItemSpon = ({ match }) => {

const [loading, setLoading] = useState(true);
const [unread, setUnread] = useState(false);
const [mess, setMess] = useState({});

const fetchMessage = async () => {
    const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/fullsponmessage/${ match.params.messageid }`, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} });
    const item = await fetchItem.json();
    const content = item[0];
    setMess(content);
    setLoading(false);
};

useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

useEffect(() => {
   fetchMessage();
  }, [])

return (
    <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="glass-account-edit">
                <h1>✉️ Message to</h1>
                <h1>{mess.username}</h1>
                <div className="glass-giveaway-details">
                <h2>{mess.message}</h2>
                <p><small>Sent at {new Date(mess.created_at).toUTCString()}</small></p>
                </div>
                <br />
                <p><Link to={`/businessmailsent`}>Back to Sent Mail</Link></p>
      <br />
      </div>)
}
            <br />
            <br />
            <br />
            <br />
    </div>
)

}

export default MailSentItemSpon;