import React, { useState } from 'react';
import axios from 'axios';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import SpinnerProcessing from './SpinnerProcessing';
import Modal from './Modal';

const UpgradeHome = (props) => {

  const [processing, setProcessing] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  function createSubscription({ paymentMethodId, customerId }) {
    return (axios.post(`${process.env.REACT_APP_API_URL}/b/subscribeone`, { paymentMethodId: paymentMethodId, customerId: customerId }, { headers: { 'x-api-key': localStorage.getItem('x-api-key')}
      })
        .then((response) => {
          return response;
        })
        .then((result) => {
          if (result.error) {
            setProcessing(false);
            alert(result.error);
            throw result;
          }
          return result;
        })
        .then((result) => {
          return {
            paymentMethodId: paymentMethodId,
            subscription: result,
          };
        })
        .catch((error) => {
          setProcessing(false);
          alert('Error: Try another card!');
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          return
        })
    );
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setProcessing(true);
    setModalOpen(false);
    const cardElement = elements.getElement(CardElement);

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        address: {
          city: props.addressCity,
          line1: props.addressLine,
          state: props.addressState
        },
        email: props.email,
        name: props.name,
      },
    });
    if (error) {
      setProcessing(false);
      alert('Error: Please enter all fields and try again');
    } else {
      createSubscription({
        customerId: props.stripeid,
        paymentMethodId: paymentMethod.id,
      }).then((result) => {
        return setTierOne(result);
      }).catch(err => Promise.reject('Could not upgrade!'));
    }
  };

  async function setTierOne(result) {
    if (result.error) {
      alert(result.error);
      return result;
    }
    if (result.subscription.data.status === 'active') {
    const url = `${process.env.REACT_APP_API_URL}/b/tierone/${props.gid}`;
    const head = {'x-api-key': localStorage.getItem('x-api-key')};
    return axios.patch(url, head)
    .then(response => {
      const sponItem = {
        'sponsorid': response.data.rows[0].sponsorid, 
        'email': response.data.rows[0].email,
        'name': response.data.rows[0].name,
        'stripeid': response.data.rows[0].stripeid,
        'spontier': response.data.rows[0].spontier
        }
      localStorage.setItem('sponuser', JSON.stringify(sponItem))
      window.location = '/upgradereturn';
      return response.status
    })
    .catch(err => Promise.reject('Could not upgrade!'));
  } else {
    alert(result.error);
    return result
  }
    }

    const toggleModal = async (e) => {
      e.preventDefault();
      setModalOpen(!modalOpen);
    }   

  return (  
    <div>
    <form onSubmit={toggleModal}>
       <div className="focus-cc">
      <CardElement />
      </div>
      <br />
      {
    (processing) ? ( <SpinnerProcessing /> ) :
      (<button type="submit" disabled={!stripe}>
        Create Subscription
      </button>)}
      <br />
    </form>
    {modalOpen && <Modal
                    content={<>
                    <div>
                        <h2>Create Subscription?</h2>
                        <p>Would you like to upgrade your Givmap account?</p>
                        <p>You will be charged $29 per month</p>
                        <button onClick={handleSubmit}> Create Subscription </button>
                    </div>
                    <br />
                    </>}
                    handleClose={toggleModal}
                    />}
    </div>
  );
};

export default UpgradeHome;