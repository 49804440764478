import React, {useState, useEffect} from 'react';
import { isAuthenticated } from './repository';
import { isSponAuthenticated } from './repository';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Spinner from './Spinner';
import SpinnerEntry from './SpinnerEntry';

function ItemDetails({ match }) {

  const [item, setItem] = useState({});
  const [user] = useState( () => JSON.parse(localStorage.getItem("user")) || "" );
  const [sponuser, setSponuser] = useState("");
  const [liked, setLiked] = useState("");
  const [loading, setLoading] = useState(true);
  const [tier, setTier] = useState([]);
  const [isSet, setIsSet] = useState(false);
  const [arr, setArr] = useState([]);
  const [clicked, setClicked] = useState(false);  
  const [message, setMessage] = useState([]);
  const [thinking, setThinking] = useState(false);

  const today = new Date().toISOString().slice(0, 10);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    useEffect(() => {
        fetchItem();
     },[]);

  useEffect(() => {
    fetchSpon();
  }, [])

  const fetchSpon = async () => {
    const fetchSponInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/sponus`, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} });
    const info = await fetchSponInfo.json();
    setSponuser(info);
};

    const fetchItem = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/items/${ match.params.itemid }`);
        if (fetchItem.status === 400) {
          window.location = "/notfound"
        }
        const item = await fetchItem.json();
        setItem(item);
        setTier(item.spontier);
        setIsSet(true);
    };

    const fetchLiked = async () => {
      const fetchIt = await fetch(`${process.env.REACT_APP_API_URL}/b/haveiliked/${ match.params.itemid }`, { headers: { 'x-access-token': localStorage.getItem('x-access-token')} });
      const it = await fetchIt.json();
      setLiked(it);
      setLoading(false);
  };

  const fetchArr = async () => {
    if (tier > 0) {
    const fetchArrInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/sidegiveawaysonecombo/${item.sponsorid}/${today}`);
    const info = await fetchArrInfo.json();
    setArr(info);
  } else {
    const fetchArrInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/sidegiveawayszerocombo/${today}`);
    const info = await fetchArrInfo.json();
    setArr(info);
  }
};

  useEffect(() => {
    fetchLiked();
 },[]);

  useEffect(() => {
    if (isSet === true) {
      fetchArr();
    } else return
  }, [isSet])

    async function createEntItem (data) {
      setClicked(true)
      return axios.post(`${process.env.REACT_APP_API_URL}/b/entitems/${ match.params.itemid }`, { apientrantid: user.entrantid }, {headers: { 'x-access-token':  localStorage.getItem('x-access-token') } } )
      .then(response => {
        setMessage(response.data)
        setThinking(false);
      return response.status
      })
      .catch(err => Promise.reject('Product could not be favorited!'));
      }

      async function unlikeItem (data) {
        setClicked(true)
        return axios.delete(`${process.env.REACT_APP_API_URL}/b/entitems/${ match.params.itemid }`, { headers: { 'x-access-token':  localStorage.getItem('x-access-token') }, data: { apientrantid: user.entrantid } } )
        .then(response => {
          setMessage(response.data)
          setThinking(false);
        return response.status
        })
        .catch(err => Promise.reject('Product could not be unfavorited!'));
        }
  
function submitCreate(event){
  event.preventDefault();
  setThinking(true);
  createEntItem()
  .catch(err => alert(err));
  };

  function submitUnlike(event){
    event.preventDefault();
    setThinking(true);
    unlikeItem()
    .catch(err => alert(err));
    };

  function isSpon() {
    return item.sponsorid === sponuser.sponsorid;
  }

  return (
    <div>
      <br />
      <br />
      <br />
      
      {
        (loading) ? ( <Spinner /> ) : (
    <div className="focus-div">
       { ( isSponAuthenticated() && isSpon() ) ? <h3><Link to={`/imagedash/${ match.params.itemid }`}>Add Images to this Item</Link></h3> : '' }
    <div className="focus-biz-pro">
    <div className="focus-column">
        <div className="focus-pro-card">
        <div className="focus-pro-pic">
        <a target="_blank" href={`${process.env.REACT_APP_CDN_URL}/${item.img1}`}><img className="item-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${item.img1}`} alt="" height="180" width="180" /></a>
        </div>
        <div className="focus-pro-name">
          <h1>{item.itemname}</h1>
        </div>
       <div className="focus-biz-pro-button">
        { (thinking === true) ? <div><SpinnerEntry /></div> :
         <div>
        { ( isAuthenticated() && liked === false  && (!clicked)) ? (<button className="january-form-submit-button" onClick={submitCreate}> Favorite </button>) : ( isAuthenticated() && liked === true  && (!clicked)) ? (<button className="january-form-submit-button" onClick={submitUnlike}> Unfavorite </button>) : ( isAuthenticated() && liked === false  && (clicked)) ? (<h2>{message.message}</h2>) : ( isAuthenticated() && liked === true  && (clicked)) ? (<h2>{message.message}</h2>) : (<li><Link to='/create'>Create an account to favorite more!</Link></li>) }
        </div> }
        </div>
        </div>
        </div>
        <br />
        <div className="focus-column">
        <br />
        <h2>About</h2>
       <h3>Product from <Link to={`/business/${ item.sponsorid }`}>{item.name}</Link></h3>
        <h3>Category: {item.itemcategory}</h3>
        <h3>Price: ${item.value}</h3>
        <p>{item.description}</p>
        <br />
        <br />
        </div>
        </div>
        <div>
        <div>  {(tier > 0) ? (<h3>View Giveaways from {item.name}:</h3>) : (<h3>Enter a Giveaway:</h3>)}</div>
        <div className="home-giveaways"> 
        {arr.map((giveaway) => {
                    return (
                       <div key={giveaway.gwayid} className="glass-front">
                          <div className="container-gwayswithbiz">
                          <div className="container-gwaytitle">
                          { giveaway.origintable === 'apigway' ? <Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link> : <Link target="_blank" to={`/instant/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link> }
                          { giveaway.origintable === 'apigway' ? <h3 className="title"><Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> : <h3 className="title"><Link target="_blank" to={`/instant/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> }
                            </div>
                            <p>by <Link target="_blank" to={`/business/${giveaway.sponsorid}`}>{giveaway.name}</Link></p>
                            </div>
                          { giveaway.origintable === 'apigway' ? <p><small>Ends: {new Date(giveaway.endon).toDateString()}</small></p> : <p><small>Instant Win</small></p> }
                        </div>
                    )
                })}
                </div>
                </div>
        <h3><Link to={`/shop/${item.sponsorid}`}>View Other Products from {item.name}</Link></h3>
        </div>)
}
            <br />
            <br />
            <br />
            <br />
    </div>
  );
}

export default ItemDetails;