import React, { useState, useEffect } from "react";
import SpinnerFetching from './SpinnerFetching';
import axios from 'axios';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ComposedChart, Area, Bar } from "recharts";

const MonthlySiteGetsChartNew = (props) => {

    const [allLogs, setAllLogs] = useState([]);
    const [processing, setProcessing] = useState(true);

    const fetchLogs = async () => {
      var today = new Date()
      axios.get(`${process.env.REACT_APP_ANALYTICS_URL}/g/yearlygetschart`)
      .then(response => {
        setAllLogs(response.data);
        setProcessing(false);
         })
     .catch(err => Promise.reject('Failed!'));
    }

    useEffect(() => {
            fetchLogs();
    }, [])
 
    return (
        <div>
   {
    (processing) ? ( <SpinnerFetching /> ) :(
                 <div className="glass-chart-section">
           <h3>Site Views Past Year</h3>
        <LineChart
          width={730}
          height={250}
          data={allLogs}
          margin={{ top: 5, right: 100, left: 100, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="views" stroke="#82ca9d" />
        </LineChart>
      </div>)}
        </div>
    )
}

export default MonthlySiteGetsChartNew;