import axios from 'axios';

export async function entrantLogin (data) {
 return axios.post(`${process.env.REACT_APP_API_URL}/b/login`, { email: data.email, password: data.password })
 .then(response => {
   if (response.data.message === 'false') {
      localStorage.setItem('x-access-token', response.data.token);
      localStorage.setItem('x-access-token-expiration', Date.now() + 2 * 60 * 60 * 1000);
      return response.data
   } else {
 localStorage.setItem('x-access-token', response.data.token);
 localStorage.setItem('x-access-token-expiration', Date.now() + 2 * 60 * 60 * 1000);
 return response.data
   }
 })
 .catch(err => Promise.reject('Authentication Failed! Please try again.'));
 }

 export async function entrantCreate (data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/b/register`, { email: data.email, username: data.username, password: data.password })
    .then(response => {
    return response.data
    })
    .catch(err => Promise.reject('Account could not be created!'));
    }

 export async function sponsorLogin (data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/b/sponlogin`, { email: data.email, password: data.password })
    .then(response => {
      if (response.data.message === 'false') {
         localStorage.setItem('x-api-key', response.data.token);
         localStorage.setItem('x-api-key-expiration', Date.now() + 2 * 60 * 60 * 1000);
         return response.data
      } else {
         localStorage.setItem('x-api-key', response.data.token);
         localStorage.setItem('x-api-key-expiration', Date.now() + 2 * 60 * 60 * 1000);
         return response.data
      }
    })
    .catch(err => Promise.reject('Authentication Failed!'));
    }

    export async function sponsorCreate (data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/b/sponregister`, { email: data.email, name: data.name, password: data.password })
        .then(response => {
        return response.data
        })
        .catch(err => Promise.reject('Account could not be created!'));
        }

export function isAuthenticated(){
 return localStorage.getItem('x-access-token') && localStorage.getItem('x-access-token-expiration') > Date.now()
 }

 export function isSponAuthenticated(){
   return localStorage.getItem('x-api-key') && localStorage.getItem('x-api-key-expiration') > Date.now()
   }

   export async function giveawayCreate (sponsorid, data) {
      return axios.post(`${process.env.REACT_APP_API_URL}/b/giveaways`, { sponsorid: sponsorid, starton: data.starton, endon: data.endon, prizeid: data.prizeid, location: data.location, rules: data.rules }, {headers: { 'x-api-key':  localStorage.getItem('x-api-key') } } )
      .then(response => {
      return response.status
      })
      .catch(err => Promise.reject('Giveaway could not be created!'));
      }

      export async function itemCreate (data, sponsorid) {
         return axios.post(`${process.env.REACT_APP_API_URL}/b/items`, { itemname: data.itemname, itemcategory: data.itemcategory, description: data.description, value: data.value, sponsorid: sponsorid }, {headers: { 'x-api-key':  localStorage.getItem('x-api-key') } } )
         .then(response => {
         return response.status
         })
         .catch(err => Promise.reject('Product could not be created!'));
         }  

      export function getMe () {
         return axios.get(`${process.env.REACT_APP_API_URL}/b/me`, { headers: { 'x-access-token': localStorage.getItem('x-access-token')} })
         .then(response => response.data)
         .catch(err => Promise.reject('Request Not Authenticated!'));
         }

         export function getMeSpon () {
            return axios.get(`${process.env.REACT_APP_API_URL}/b/sponus`, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} })
            .then(response => response.data)
            .catch(err => Promise.reject('Request Not Authenticated!'));
            }


export function getUser () {
   return axios.get(`${process.env.REACT_APP_API_URL}/b/me`, { headers: { 'x-access-token': localStorage.getItem('x-access-token')} })
   .then(response => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    })
   
   .catch(err => Promise.reject('Request Not Authenticated!'));
   }

export function getSponUser () {
   return axios.get(`${process.env.REACT_APP_API_URL}/b/sponus`, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} })
   .then(response => {
      if (response.data) {
        localStorage.setItem("sponuser", JSON.stringify(response.data));
      }

      return response.data;
    })
   .catch(err => Promise.reject('Request Not Authenticated!'));
   }

export async function createEntGway (data) {
      return axios.post(`${process.env.REACT_APP_API_URL}/b/entgways`, { apientrant_id: this.props.user.id, apigway_id: this.props.match.params.id }, {headers: { 'x-access-token':  localStorage.getItem('x-access-token') } } )
      .then(response => {
      return response.status
      })
      .catch(err => Promise.reject('Entgway relationship could not be created!'));
      }

      export function isGiveawayLive(){
         return localStorage.getItem('x-access-token') && localStorage.getItem('x-access-token-expiration') > Date.now()
         }

export async function entrantEditInfo (entrantid, data) {
            return axios.post(`${process.env.REACT_APP_API_URL}/b/entdetails`, { apientrantid: entrantid, firstname: data.firstname, lastname: data.lastname, street: data.street, city: data.city, state: data.state, country: data.country, zip: data.zip, bio: data.bio, birthday: data.birthday }, {headers: { 'x-access-token':  localStorage.getItem('x-access-token') } })
            .then(response => {
            return response.data
            })
            .catch(err => Promise.reject('Please enter all fields!'));
            }

export async function sponsorEditInfo (sponsorid, data, stripeid) {
               axios.post(`${process.env.REACT_APP_API_URL}/b/spondetails`, { apisponid: sponsorid, category: data.category, street: data.street, city: data.city, state: data.state, country: data.country, zip: data.zip, website: data.website, bio: data.bio, twitter: data.twitter, facebook: data.facebook, instagram: data.instagram, phone: data.phone }, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} })
               .then(response => {
               return response.data
               })
               .catch(err => Promise.reject('Please enter all fields!'));
               return axios.post(`${process.env.REACT_APP_API_URL}/b/customeraddress`, { customerId: stripeid, street: data.street, city: data.city, state: data.state, country: data.country, zip: data.zip }, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} })
               .then(response => {
               return response.data
               })
               .catch(err => Promise.reject('Please enter all fields!'));
               }
               
export async function entrantEditEmail (data, entrantid) {
   return axios.put(`${process.env.REACT_APP_API_URL}/b/entrants`, { email: data.email, username: data.username, password: data.password, entrantid: entrantid }, {headers: { 'x-access-token':  localStorage.getItem('x-access-token') } })
   .then(response => {
   return response.data
   })
   .catch(err => Promise.reject('Please enter all fields!'));
   }

export async function sponsorEditEmail (data, sponsorid) {
      return axios.put(`${process.env.REACT_APP_API_URL}/b/sponsors`, { email: data.email, name: data.name, password: data.password, sponsorid: sponsorid }, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} })
      .then(response => {
      return response.data
      })
      .catch(err => Promise.reject('Please enter all fields!'));
      }

      export async function adminLogin (data) {
         return axios.post(`${process.env.REACT_APP_API_URL}/b/adminlogin`, { adminemail: data.adminemail, password: data.password })
         .then(response => {
         localStorage.setItem('x-admin-key', response.data.token);
         localStorage.setItem('x-admin-key-expiration', Date.now() + 2 * 60 * 60 * 1000);
         return response.data
         })
         .catch(err => Promise.reject('Authentication Failed!'));
         }