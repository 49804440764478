import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from './Spinner';

const ChangePasswordSpon = ({ match }) => {

const [info, setInfo] = useState([]);
const [loading, setLoading] = useState(true);
const initialInputState = { password: '' };
const [eachEntry, setEachEntry] = useState(initialInputState);
const {password} = eachEntry;

useEffect(()=>{
    fetchInfo();
}, [])

const fetchInfo = async () => {
    const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/content/sponchange`);
    const info =  await fetchInfo.json();
    setInfo(info);
    setLoading(false);
};
const handleInputChange = e => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  const handleFinalSubmit = e => {
    e.preventDefault();
    changePass(eachEntry)
    .then(token => window.location = '/businesslogin')
    .catch(err => alert(err));
};

    function changePass (data) {
        return axios.patch(`${process.env.REACT_APP_API_URL}/b/businesschangepass/${ match.params.token }`, { password: data.password})
        .then(response => {
        return response.status
        })
        .catch(err => Promise.reject('Could not change password!'));
        }

return (
    <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
    <div className="glass-account-edit">
  <h1>{info.title} Password:</h1>
  <div className="container">
             <form onSubmit={handleFinalSubmit}>
                <div className="form-group">
                    <label>Password: </label>
                         <input type="password" className="form-control" name="password"
                         onChange={handleInputChange}/>
                </div>
                <button type="submit" className="btn btn-default">Change Password</button>
            </form>
            </div>
      <br />
      </div>)
}
      <br />
      <br />
            <br />
            <br />
            <br />
    </div>
)

}

export default ChangePasswordSpon;