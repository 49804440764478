import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const MailSent = (props) => {

    const [loading, setLoading] = useState(true);

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || []);
    const [mail, setMail] = useState([]);
    const [unread, setUnread] = useState(false);

    const [data, setData] = useState(mail);

    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(10);

    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

    const fetchMail = async () => {
        const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/fullsentfolder/${user.entrantid}`, { headers: { 'x-access-token': localStorage.getItem('x-access-token')} });
        const info = await fetchInfo.json();
        /**const newArr = Array.from(new Set(info.map(a => a.senderid)))
        .map(senderid => {
          return info.find(a => a.senderid === senderid)
        })
        */
       if (info === false){
        setMail(info);
        setData([]);
        setLoading(false);
       } else {
        setMail(info);
        setData(info);
        setLoading(false);
       }
        };

    useEffect(() => {
          fetchMail();
      }, [])

      function noMail () {
          return mail === false;
      }

      function isUnread (message) {
        return message.unread === true;
      }

      const renderData = (mail) => {
        return (
            <div className="glass-container-array">
            {mail.map(message => (
                <div key={message.messageid} className="glass-mail-item">
                            <h3><Link to={`/mailsent/${message.messageid}`}>{message.message.slice(0, 25)}...</Link></h3>
                            <p><small>To <Link to={`/business/${message.apisponsorid}`}>{message.name}</Link> at {new Date(message.created_at).toUTCString()}</small></p>
                            {isUnread(message) ? (<p className="focus-orange"><small>Unread!</small></p>) : ""}
                        </div>
        
            ))}
          </div>
        );
      };
    
      const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
      };
    
      const pages = [];
      for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
        pages.push(i);
      }
    
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    
      const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
          return (
            <li
              key={number}
              id={number}
              onClick={handleClick}
              className={currentPage == number ? "active" : null}
            >
              {number}
            </li>
          );
        } else {
          return null;
        }
      });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  const handleLoadMore = () => {
    setitemsPerPage(itemsPerPage + 5);
  };

  function isArrBig() {
    return data.length > 10;
  }

    return (
        <div>
                <br />
                <br />
                <br />
                { (loading) ? ( <Spinner /> ) : (
                <div className="glass-account-edit">
                    <h1>✉️ Sent Mail:</h1>
                    <h3><Link to={`/mailbox`}>Inbox</Link></h3>
                    { (noMail()) ? (<div>Nothing here yet!</div>) :
                    (<div>
                  {renderData(currentItems)}
                  {isArrBig() ? (
      <div className="pageNumbersDiv">
      <button onClick={handleLoadMore} className="loadmore">
        Load More
      </button>
      </div>) : ('')}
      {isArrBig() ? (
      <div className="pageNumbersDiv">
      <ul className="pageNumbers">
        <li>
          <button
            onClick={handlePrevbtn}
            disabled={currentPage == pages[0] ? true : false}
          >
            Prev
          </button>
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}

        <li>
          <button
            onClick={handleNextbtn}
            disabled={currentPage == pages[pages.length - 1] ? true : false}
          >
            Next
          </button>
        </li>
      </ul>
      </div>) : ('')}
                    </div>)
                }
        <br />
        </div>)
    }
                <br />
                <br />
                <br />
                <br />
        </div>
    )

}

export default MailSent;