import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import Spinner from './Spinner';

const SponsorLoginHooks = (props) => {

    const initialInputState = { email: '', password: '' };
    const [eachEntry, setEachEntry] = useState(initialInputState);
    const {email, password} = eachEntry;
    const [api, setApi] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        fetchApi();
    }, [])

    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/sponlogin`);
        const api = await fetchApi.json();
        setApi(api);
        setLoading(false);
    };

    async function sponsorLogin (data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/b/sponlogin`, { email: data.email, password: data.password })
        .then(response => {
            const sponItem = {
                'sponsorid': response.data.rows[0].sponsorid, 
                'email': response.data.rows[0].email,
                'name': response.data.rows[0].name,
                'stripeid': response.data.rows[0].stripeid,
                'spontier': response.data.rows[0].spontier
                }
             localStorage.setItem('x-api-key', response.data.token);
             localStorage.setItem('x-api-key-expiration', Date.now() + 2 * 60 * 60 * 1000);
             localStorage.setItem('sponuser', JSON.stringify(sponItem))
             return response.data
        })
        .catch(err => Promise.reject('Authentication Failed! Please try again.'));
        }

    const handleInputChange = e => {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
      };

      const handleFinalSubmit = e => {
        e.preventDefault();
        sponsorLogin(eachEntry)
        .then(token => {
            if (token.message === 'false') {
                window.location = '/businesssendverification'
            } else {
                window.location = '/mydashboard'
            }
        })
        .catch(err => alert(err));
    };

    return(
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
            <div>
             <h1>{api.login}</h1>
            </div>
             <div className="focus-login">
             <form onSubmit={handleFinalSubmit}>
                <div className="form-group">
                    <label><h3>Email: </h3></label>
                         <input type="text" className="form-control" name="email"
                         onChange={handleInputChange}/>
                </div>
                <div className="form-group">
                    <label><h3>Password: </h3></label>
                         <input type="password" className="form-control" name="password"
                         onChange={handleInputChange}/>
                </div>
                <br />
                <button type="submit" className="february-form-submit-button"> Login </button>
            </form>
            <br />
            <Link to='/businessforgotpassword'><h3>Forgot my Password</h3></Link>
            </div>
            <br />
            <br />
            </div>)
}
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default SponsorLoginHooks;