import React, { useEffect, useState } from 'react';
import Spinner from './Spinner';

const RandomGiveaway = (props) => {

    const [loading, setLoading] = useState(true);
    const [gway, setGway] = useState([]);
    const [isSet, setIsSet] = useState(false);

    const today = new Date().toISOString().slice(0, 10);

    const getGway = async () => {
        const fetchGway = await fetch(`${process.env.REACT_APP_API_URL}/b/randomgiveaway/${today}`);
        const gwayInfo = await fetchGway.json();
        setGway(gwayInfo);
        setLoading(false);
        setIsSet(true);
    }

    useEffect(() => {
      getGway()
    }, [])

    useEffect(() => {
        if (gway && loading === false && isSet === true) {
            window.location = `/giveaways/${gway.gwayid}`
        } else return
    }, [isSet])

    return (
        <div>
            <br />
            <br />
            <br />
            <Spinner />
        </div>
    )

}

export default RandomGiveaway;