import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from './Spinner';
import SponFollowsBarChartThree from './SponFollowsBarChartThree';

const Followers = (props) => {

    const [sponuser] = useState(JSON.parse(localStorage.getItem('sponuser')) || []);
    const [tier, setTier] = useState(sponuser.spontier);
    const [followers, setFollowers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [followsLogs, setFollowsLogs] = useState([]);
    const [followsSet, setFollowsSet] = useState(false);
    const [followsLoading, setFollowsLoading] = useState(true);

    const fetcher = () => {
        try {
        axios.get(`${process.env.REACT_APP_API_URL}/b/followers/${sponuser.sponsorid}`, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} })
        .then(res => {
            setFollowers(res.data);
            setLoading(false);
        }) } catch (error){
            return
        }
    }

    const getDaysBetweenDates = function(startDate, endDate) {
        var listDate = [];
        var dateMove = new Date(startDate);
        var strDate = startDate;
        while (strDate < endDate){
          var strDate = dateMove.toISOString().slice(0,10);
          listDate.push(strDate);
          dateMove.setDate(dateMove.getDate()+1);
        };
        return listDate;
      }

      const fetchFollowLogs = async () => {
        var yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1);
        var thirtyDaysAgo = new Date()
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        try {
        const fetchFollows = await fetch(`${process.env.REACT_APP_ANALYTICS_URL}/g/newselectfollowlogs/${thirtyDaysAgo.toISOString().slice(0, 10)}/${yesterday.toISOString().slice(0, 10)}/${sponuser.sponsorid}`);
        const follows = await fetchFollows.json();
       const newNew = follows.map(function(row) {
        return { "date" : row.date, "Followers" : row.views }
     })
     setFollowsLogs(newNew);
     setFollowsLoading(false);
     setFollowsSet(true);
        } catch {
          return
        }
      }

    useEffect(() => {
        fetcher();
        fetchFollowLogs();
    }, [])

    return(
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
            <div>
            <h1>All of {sponuser.name}'s Followers:</h1>
            </div>
            <h2>Followers: {followers.length} </h2>
            {(tier > 0) ? (
            <div>
             <h2>Follows of {sponuser.name}</h2>
             <SponFollowsBarChartThree 
                followLogs={followsLogs}
                loading={followsLoading}
            />
      </div>
      ) : (
      <div>
          <h2>
            Upgrade to Premium to view this chart!
          </h2>
      </div>)}
        < br/>
        </div>)
}
        <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default Followers;