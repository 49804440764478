import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import Spinner from './Spinner';

const PaymentHistory = (props) => {

    const [loading, setLoading] = useState(true);

    const [sponuser] = useState(JSON.parse(localStorage.getItem('sponuser')) || []);
    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
     
            axios.get(`${process.env.REACT_APP_API_URL}/b/customerinvoices/${sponuser.stripeid}`)
                 .then(res => {
                     if (res.data.data.length <= 0) {
                        setInvoices([]);
                        setLoading(false);
                     } else {
                        setInvoices(res.data.data);
                    // invoices are in an object, not array
                        setLoading(false);
                     }
                }).catch(err => Promise.reject('Request failed!'));
    }, [])

    const renderList = (invoices) => {
        return (
            <div className="focus-invisible-array">
                {invoices.map((invoice) =>
                 <div key={invoice.id} className="focus-array-item">
                <h3 key={invoice.created}> <a href={invoice.hosted_invoice_url} target="_blank">Invoice dated {new Date(invoice.created*1000).toDateString()}</a></h3>
                </div>
                )}
            </div>
        );
    }

    function noInvoices () {
        return invoices.length === 0;
    }

return (
<div>
    <br />
    <br />
    <br />
    {
        (loading) ? ( <Spinner /> ) : (
    <div className="focus-div">
        {noInvoices() ? (<div>
            <h1>You have no invoices!</h1>
        <br />
        <h3><Link to={`/accountsettings`}>Account Settings</Link></h3>
        </div>) : (
        <div>
        <div>
        <h1>Invoices of {sponuser.name} </h1>
        </div>
        <br />
        <div className="focus-invisible-array">
        {renderList(invoices)}
        </div>
        <br />
        <h3><Link to={`/accountsettings`}>Account Settings</Link></h3>
        </div>) }
        <br />
        </div>)
}
        <br />
            <br />
            <br />
            <br />
</div>
)
}

export default PaymentHistory;