import React from "react";
import { Link } from 'react-router-dom';

const d = new Date();

let year = d.getFullYear();

const Footer = () => (
  <div className="footer">
    <p><small>© Paseo Applications, LLC {year}. All rights reserved. <Link to="/terms">Terms and Conditions</Link></small></p>
  </div>
);

export default Footer;