import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const EditProduct = ({match}) => {

    const [loading, setLoading] = useState(true);
    const [api, setApi] = useState([]);
    const [item, setItem] = useState([]);
    const [activeItem, setActiveItem] = useState('');
    const initialNameState = { itemname: '' };
    const initialCategoryState = { itemcategory: '' };
    const initialDescriptionState = { description: '' };
    const initialValueState = { value: '' };
    const initialBoolState = { bool: false}
    const [nameEntry, setNameEntry] = useState(initialNameState);
    const [categoryEntry, setCategoryEntry] = useState(initialCategoryState);
    const [descriptionEntry, setDescriptionEntry] = useState(initialDescriptionState);
    const [valueEntry, setValueEntry] = useState(initialValueState);
    const [boolActive, setBoolActive] = useState(initialBoolState);
    const [isToggled, setIsToggled] = useState(boolActive);
    const [isPressed, setIsPressed] = useState(false);

useEffect(() => {
    fetchApi();
    fetchItem();
    fetchActiveItem();
}, [])

const fetchApi = async () => {
    const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/updateproduct`);
    const api = await fetchApi.json();
    setApi(api);
};

const fetchItem = async () => {
    const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/itemnolog/${ match.params.itemid }`);
    const item = await fetchItem.json();
    setItem(item);
    if (item.listingactive === true ) {
        setBoolActive(true)
    } if (item.listingactive === false) {
        setBoolActive(false)
    }
};

const fetchActiveItem = async () => {
    const validate = await fetch(`${process.env.REACT_APP_API_URL}/b/hasactivegway/${match.params.itemid}`);
    const val = await validate.json();
    setActiveItem(val);
    setLoading(false);
};

const patchName = async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/itemname/${match.params.itemid}`, data, { headers: {'x-api-key': localStorage.getItem('x-api-key')} })
    .then(response => {
      return response.status
    })
    .catch(err => Promise.reject('Failed!'));
}

const patchCategory = async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/itemcategory/${match.params.itemid}`, data, { headers: {'x-api-key': localStorage.getItem('x-api-key')} })
    .then(response => {
      return response.status
    })
    .catch(err => Promise.reject('Failed!'));
}

const patchDescription = async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/itemdescription/${match.params.itemid}`, data, { headers: {'x-api-key': localStorage.getItem('x-api-key')} })
    .then(response => {
      return response.status
    })
    .catch(err => Promise.reject('Failed!'));
}

const patchValue = async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/itemvalue/${match.params.itemid}`, data, { headers: {'x-api-key': localStorage.getItem('x-api-key')} })
    .then(response => {
      return response.status
    })
    .catch(err => Promise.reject('Failed!'));
}

const handleNameChange = e => {
    setNameEntry({ ...nameEntry, [e.target.name]: e.target.value });
  };

  const handleNameSubmit = e => {
    e.preventDefault();
    patchName(nameEntry)
    .then(token => window.location = `/editproduct/${match.params.itemid}`)
    .catch(err => alert(err));
};

const handleCategoryChange = e => {
    setCategoryEntry({ ...categoryEntry, [e.target.name]: e.target.value });
  };

  const handleCategorySubmit = e => {
    e.preventDefault();
    patchCategory(categoryEntry)
    .then(token => window.location = `/editproduct/${match.params.itemid}`)
    .catch(err => alert(err));
};

const handleDescriptionChange = e => {
    setDescriptionEntry({ ...descriptionEntry, [e.target.name]: e.target.value });
  };

  const handleDescriptionSubmit = e => {
    e.preventDefault();
    patchDescription(descriptionEntry)
    .then(token => window.location = `/editproduct/${match.params.itemid}`)
    .catch(err => alert(err));
};

const handleValueChange = e => {
    setValueEntry({ ...valueEntry, [e.target.name]: e.target.value });
  };

  const handleValueSubmit = e => {
    e.preventDefault();
    patchValue(valueEntry)
    .then(token => window.location = `/editproduct/${match.params.itemid}`)
    .catch(err => alert(err));
};

const onToggle = () => {
    setIsToggled(!isToggled);
    setIsPressed(true)
}

const patchBool = async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/itemactivebool/${match.params.itemid}`, data, { headers: {'x-api-key': localStorage.getItem('x-api-key')} })
    .then(response => {
      return response.status
    })
    .catch(err => Promise.reject('Failed!'));
}

useEffect(() => {
    if (boolActive === true && isPressed === true) {
        let obj = {bool: false}
        setBoolActive(false)
        return patchBool(obj)
    } if (boolActive === false && isPressed === true) {
        let obj = {bool: true}
        setBoolActive(true)
        return patchBool(obj)
    }
}, [isToggled])

function isActive() {
    return activeItem === true;
  }

    return(
        <div>
            <br />
            <br />
            <br />
            {
                (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
                 { isActive() ? (<div><h2>Product details cannot be edited! Product is currently in a Giveaway!</h2><br /><h3><Link to={`/viewmyproduct/${match.params.itemid}`}>Back to Product</Link></h3>
                    </div>) : (<div>
                <div>
                <h1>{api.title}</h1>
                </div>
                <div className="focus-edit">
                    <div className="focus-edit-box">
                    <form onSubmit={handleNameSubmit}>
                    <div className="form-group">
                        <h3>Current Name: </h3><h3 className="focus-contrast">{item.itemname}</h3>
                    <label><h3>Update Product Name: </h3></label>
                    <input type="text" className="form-control" name="itemname"
                    onChange={handleNameChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handleCategorySubmit}>
                    <div className="form-group">
                    <h3>Current Category: </h3><h3 className="focus-contrast">{item.itemcategory}</h3>
                    <label><h3>Update Product Category: </h3></label>
                    <select name="itemcategory" className="form-control" onChange={handleCategoryChange}>
                    <option value={item.itemcategory}>{item.itemcategory}</option>
                        <option value="Antiques/Collectibles">Antiques/Collectibles</option>
                        <option value="Art">Art</option>
                        <option value="Athletic/Sports Gear">Athletic/Sports Gear</option>
                        <option value="Automotive">Automotive</option>
                        <option value="Beauty/Personal Care">Beauty/Personal Care</option>
                        <option value="Books">Books</option>
                        <option value="Clothing">Clothing</option>
                        <option value="Computers/Electronics">Computers/Electronics</option>
                        <option value="Food/Restaurant">Food/Restaurant</option>
                        <option value="Gift Cards">Gift Cards</option>
                        <option value="Hats/Headwear">Hats/Headwear</option>
                        <option value="Health">Health</option>
                        <option value="Home/Kitchen Goods">Home/Kitchen Goods</option>
                        <option value="Kids/Toys">Kids/Toys</option>
                        <option value="Luggage/Travel Gear">Luggage/Travel Gear</option>
                        <option value="Magazines/Media">Magazines/Media</option>
                        <option value="Movies/TV">Movies/TV</option>
                        <option value="Music">Music</option>
                        <option value="Musical Instruments">Musical Instruments</option>
                        <option value="NFTs">NFTs (Non-Fungible Tokens)</option>
                        <option value="Outdoors">Outdoors</option>
                        <option value="Pets">Pets</option>
                        <option value="Shoes">Shoes</option>
                        <option value="Software">Software</option>
                        <option value="Tickets">Tickets</option>
                        <option value="Video Games/E-Sports">Video Games/E-Sports</option>
                        <option value="Other">Other</option>
                    </select>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handleDescriptionSubmit}>
                    <div className="form-group">
                    <h3>Current Description: </h3><h3 className="focus-contrast">{item.description}</h3>
                    <label><h3>Update Product Description: </h3></label>
                    <input type="text" className="form-control" name="description"
                    onChange={handleDescriptionChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handleValueSubmit}>
                    <div className="form-group">
                    <h3>Current Value: </h3><h3 className="focus-contrast">${item.value}</h3>
                    <label><h3>Update Product Value ($): </h3></label>
                    <input type="text" className="form-control" name="value"
                    onChange={handleValueChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <div className="form-group">
                    <h3>Show this Product in your Shop: </h3>{(boolActive === true) ? (<h3 className="focus-contrast">True</h3>) : (<h3 className="focus-contrast-rust">False</h3>)}
                    <label className="toggle-switch">
                        <input type="checkbox" checked={boolActive} onChange={onToggle} />
                        <span className="switch" />
                    </label>
                    </div>
                    </div>
                    <br />
                    </div>
                    <br />
                    <h3><Link to={`/viewmyproduct/${match.params.itemid}`}>Back to Product</Link></h3>
                    </div>) 
                    }
            </div>)
        }
            <br />
            <br />
                    <br />
                    <br />
                    <br />
        </div>
    )
}

export default EditProduct;