import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const ItemUploadOne = ({ match }) => {

  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('Choose File');
  const [uploadedFile, setUploadedFile] = useState({});
  const [api, setApi] = useState([]);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [activeItem, setActiveItem] = useState('');

  useEffect(()=>{
      fetchApi();
      fetchActiveItem();
  }, [])

  const fetchApi = async () => {
      const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/uploadone`);
      const api = await fetchApi.json();
      setApi(api);
      setLoading(false);
  };

  const fetchActiveItem = async () => {
    const validate = await fetch(`${process.env.REACT_APP_API_URL}/b/hasactivegway/${match.params.itemid}`);
    const val = await validate.json();
    setActiveItem(val);
};

  const onChange = e => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const onSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    try {
      const res = await axios.post(`${process.env.REACT_APP_CDN_URL}/i/itemupload`, formData, {
      //const res = await axios.post(`${process.env.REACT_APP_CDN_URL}/b/itemupload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      const { fileName, filePath } = res.data;
      setUploadedFile({ fileName, filePath });
      setHasUploaded(true);
    } catch (err) {
      alert('Image size too large!')
    }
  };

  async function addItemImg (data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/b/itemimg/${ match.params.itemid }`, { img1: uploadedFile.fileName }, {headers: { 'x-api-key':  localStorage.getItem('x-api-key') } } )
    .then(response => {
    return response.status
    })
    .catch(err => Promise.reject('Item image could not be added!'));
    }

function submitAddImg(event){
  if (hasUploaded === true) {
event.preventDefault();
addItemImg()
.then(token => window.location = `/imagedash/${ match.params.itemid }`)
.catch(err => alert(err));
  } else {
    return alert('Please Select an Image to Upload!')
  }
};

function isActive() {
  return activeItem === true;
}

// returns name of file -> need to put into form and submit link into db as pic
const sponuser = JSON.parse(localStorage.getItem('sponuser'));
  return (
    <Fragment>
      <br />
      <br />
      <br />
      {
        (loading) ? ( <Spinner /> ) : (
      <div className="glass-account-edit">
        { isActive() ? (<div><h2>Product image cannot be edited! Product is currently in a Giveaway!</h2><br /><h3><Link to={`/viewmyproduct/${match.params.itemid}`}>Back to Product</Link></h3></div>) : (<div>
      <h1>{api.item} {sponuser.name}!</h1>
      <h3>Select an image of the Product and then set as Primary Image</h3>
      <form onSubmit={onSubmit}>
        <div>
          <input
            type='file'
            className='custom-file-input'
            id='customFile'
            onChange={onChange}
          />
          
        </div>
        <button type="submit" className="february-form-submit-button"> Upload </button>
      </form>
      {uploadedFile ? (
        <div>
          <div>
            <br />
            <img src={`${process.env.REACT_APP_CDN_URL}/${uploadedFile.fileName}`} alt='' height="200" width="200" />
          </div>
          <div>
          <button onClick={submitAddImg} className="january-form-submit-button"> Set Image </button>
        </div>
        </div>) : null}
        <br />
        </div>)}
        </div>)
}
        <br />
            <br />
            <br />
            <br />      
    </Fragment>
  );
}

export default ItemUploadOne;