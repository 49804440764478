import React, {useState, useEffect} from 'react';
import { isAuthenticated } from './repository';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Spinner from './Spinner';
import SpinnerEntry from './SpinnerEntry';

function InstantWinDetails ({ match }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

   const [loading, setLoading] = useState(true);
   const [gwayInfo, setGwayInfo] = useState([]);
   const [user] = useState(() => JSON.parse(localStorage.getItem("user")) || "");
   const [userlocation, setUserLocation] = useState("");
   const [region, setRegion] = useState("");
   const [elligible, setElligible] = useState(null);
   const [entered, setEntered] = useState("");
   const [clicked, setClicked] = useState(false);  
   const [message, setMessage] = useState([]);
   const [tier, setTier] = useState([]);
   const [isSet, setIsSet] = useState(false);
   const [won, setWon] = useState(null);
   const [winnerList, setWinnerList] = useState([]);
   const [alreadyWon, setAlreadyWon] = useState(false);
   const [howManyHaveWon, setHowManyHaveWon] = useState(0);
   const [ended, setEnded] = useState(false);
   const [arr, setArr] = useState([]);  
   const [thinking, setThinking] = useState(false);

   const today = new Date().toISOString().slice(0, 10);

   const fetchArr = async () => {
    if (tier > 0) {
    const fetchArrInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/sidegiveawaysonereduxcombo/${gwayInfo.sponsorid}/${today}/${match.params.gwayid}`);
    const info = await fetchArrInfo.json();
    setArr(info);
  } else {
    const fetchArrInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/sidegiveawayszerocombo/${today}`);
    const info = await fetchArrInfo.json();
    setArr(info);
  }
};

   const fetchWinners = async (winners) => {
    const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/entwinner/${ match.params.gwayid }`);
    const item = await fetchItem.json();
    if (item === false) {
      return
    } if (item.length >= winners) {
    setWinnerList(item);
    setHowManyHaveWon(item.length);
    setEnded(true);
    } else {
      setWinnerList(item);
      setHowManyHaveWon(item.length);
    }
};
   
   const fetchInfo = async () => {
    const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/activeinstant/${ match.params.gwayid }`);
    if (fetchItem.status === 400) {
      window.location = "/notfound"
    }
    const item = await fetchItem.json();
    if (item.instantwinactive === false) {
      setGwayInfo(item);
      setRegion(item.location);
      setTier(item.spontier);
      fetchWinners(item.numberwinners);
      setIsSet(true);
      setEnded(true);
    } else {
      setGwayInfo(item);
      setRegion(item.location);
      setTier(item.spontier);
      fetchWinners(item.numberwinners);
      setIsSet(true);
    }
};

const userLocationFetcher = () => {
  if (localStorage.getItem('x-access-token') === null) {
    setUserLocation("")
  } else {
  try {
  axios.get(`${process.env.REACT_APP_API_URL}/b/mylocation/${user.entrantid}`, { headers: { 'x-access-token': localStorage.getItem('x-access-token')} })
  .then(res => {
    if (res.data === []) {
      setUserLocation("")
    } else {
      setUserLocation(res.data);
    }
  }) } catch (error){
      return setUserLocation("")
  }
}
};

const fetchEntered = async () => {
  if (localStorage.getItem('x-access-token') === null) {
    setUserLocation("")
    setLoading(false);
  } else {
  try {
  const fetchIt = await fetch(`${process.env.REACT_APP_API_URL}/b/amiinstantentered/${ match.params.gwayid }`, { headers: { 'x-access-token': localStorage.getItem('x-access-token')} });
  const it = await fetchIt.json();
  setEntered(it);
  setLoading(false);
  } catch {
    setEntered("");
    setLoading(false);
  }
}
};

useEffect(() => {
  let newInt = setInterval(() => {

      if (user) {
          clearInterval(newInt);
          return userLocationFetcher();
      } else return
  }, 1000)

  return () => {
      clearInterval(newInt);
  }
}, [user])

  useEffect(() => {
    fetchInfo();
    fetchEntered();
  }, [])

  useEffect(() => {  
    if (userlocation.state === region || region === "United States") {
      setElligible(true);
    } else {
      setElligible(false);
    }
  }, [userlocation, region])

  /**
  const fetchWinnersAgain = async () => {
    const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/entwinner/${ match.params.gwayid }`);
    const item = await fetchItem.json();
    if (item === false) {
      return
    } if (item.length >= gwayInfo.numberwinners) {
    setWinnerList(item);
    setHowManyHaveWon(item.length);
    setEnded(true);
    } else {
      setWinnerList(item);
      setHowManyHaveWon(item.length);
    }
    return item;
};
*/
const messageCreate = async (gwayid, bizid, winnerid) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/b/instantmail/${gwayid}/${bizid}/${winnerid}`)
  .catch(err => Promise.reject('Message could not be sent!'));
  }  

  async function createEntWinner (data) {
    axios.post(`${process.env.REACT_APP_API_URL}/b/entwinner/${ match.params.gwayid }`, { instantwinnerid: user.entrantid }, {headers: { 'x-access-token':  localStorage.getItem('x-access-token') } } )
    .then(response => {
      messageCreate(match.params.gwayid, gwayInfo.sponsorid, user.entrantid)
       return response.status
      })
      .catch(err => Promise.reject('Error!'));
      } 

  async function createEntInstantWin (data) {
    axios.get(`${process.env.REACT_APP_API_URL}/b/entwinner/${ match.params.gwayid }`)
    .then(response => {
      if (response.data === false) {
        setHowManyHaveWon(0);
        return axios.post(`${process.env.REACT_APP_API_URL}/b/entinstantwin/${ match.params.gwayid }`, { apientrantid: user.entrantid, total: gwayInfo.numberentrants, winners: gwayInfo.numberwinners, winningnumber: gwayInfo.winningnumber }, {headers: { 'x-access-token':  localStorage.getItem('x-access-token') } } )
      } if (response.data.length >= gwayInfo.numberwinners) {
      setHowManyHaveWon(response.data.length);
      setEnded(true);
      } else {
        setHowManyHaveWon(response.data.length);
      return axios.post(`${process.env.REACT_APP_API_URL}/b/entinstantwin/${ match.params.gwayid }`, { apientrantid: user.entrantid, total: gwayInfo.numberentrants, winners: gwayInfo.numberwinners, winningnumber: gwayInfo.winningnumber }, {headers: { 'x-access-token':  localStorage.getItem('x-access-token') } } )
      }
    }).then(response => {
        if (response.data.number === gwayInfo.winningnumber) {
          createEntWinner();
          setThinking(false);
          setMessage(`Congratulations! You've won!`);
          setWon(true);
        } else {
          setThinking(false);
          setMessage(`Sorry, you didn't win this time!`);
          setWon(false);
        }
      })
      .catch(err => Promise.reject('instant Win Giveaway could not be entered!'));
      } 
  
function submitCreate(event){
  event.preventDefault();
  setThinking(true);
  setClicked(true);
  createEntInstantWin()
  .catch(err => alert(err));
  };

  useEffect(() => {
    if (winnerList.some(w => w.instantwinnerid === user.entrantid)) {
      setAlreadyWon(true);
    } else return
  }, [winnerList])

  useEffect(() => {
    if ((howManyHaveWon >= gwayInfo.numberwinners) && (gwayInfo.instantwinactive === true)) {
    axios.get(`${process.env.REACT_APP_API_URL}/b/instantwinend/${ match.params.gwayid }`)
  } else return
  }, [gwayInfo, howManyHaveWon, winnerList, won])

  useEffect(() => {
    if (isSet === true) {
      fetchArr();
    } else return
  }, [isSet])

  function numberMath (first, second) {
    return first - second;
  }

  const howManyLeft = numberMath(gwayInfo.numberwinners, howManyHaveWon);

  return (
    <div>
      <br />
      <br />
      <br />
      {
        (loading) ? ( <Spinner /> ) : (
    <div className="glass-giveaway">
    <br />
    <div className="glass-giveaway-details">
    {ended ? (<h3 className="focus-ended">⚠️ Giveaway is not live! ⚠️</h3>) : ('')}
      <Link to={`/products/${gwayInfo.prizeid}`}><img id="image" src={`${process.env.REACT_APP_CDN_URL}/${gwayInfo.img1}`} alt="" height="180" width="180" /></Link>
       <h1>{gwayInfo.itemname}</h1>
        <h2>Giveaway by <Link to={`/business/${gwayInfo.sponsorid}`}>{gwayInfo.name}</Link></h2>
        <h3>Prize: <Link to={`/products/${gwayInfo.prizeid}`}>{gwayInfo.itemname}</Link></h3>
        <p>Category: {gwayInfo.itemcategory}</p>
        <p>Description: {gwayInfo.description}</p>
         <p>Value: ${gwayInfo.value}</p>
         </div>
         { alreadyWon === true ?
        (<div className="additional-giveaway-info">
          <h2>You won this Giveaway!</h2><h3><Link to={`/writereviewinstant/${ match.params.gwayid }`}>After you receive your Prize, please leave a Review for this Business</Link></h3>
                <p>or</p>
         <h3><Link to={`/composemailinstant/${gwayInfo.sponsorid}/${ match.params.gwayid }`}>Send a Message to {gwayInfo.name}</Link></h3>
        </div>) : ('')}
        <div className="giveaway-countdown">
          <h3>Giveaway ends: </h3>
               {(howManyLeft === 0) ? (<h3>Giveaway has ended!</h3>) : (howManyLeft === 1) ? (<h3>{howManyLeft} prize left!</h3>) : (<h3>{howManyLeft} prizes left!</h3>) }
         </div>
         <div className="additional-giveaway-info">
         <h3>Additional Infomation:</h3>
        <p>This Giveaway is exclusively available to {gwayInfo.location} residents.</p>
        <p>Winner(s) chosen at random.</p>
        </div>
        <div className="additional-giveaway-info">
          <h3>Rules: </h3>
        <p className="focus-rules-text">{gwayInfo.rules}</p>
          </div>
        <div className="giveaway-button">
         <br />
         { (thinking === true) ? <div><SpinnerEntry /></div> :
         <div>
         { ( isAuthenticated() && elligible && (entered === false) && (!clicked) && (ended !== true ) ) ? <button className="january-form-submit-button" onClick={submitCreate}> Enter Giveaway </button> : ( isAuthenticated() && elligible && (entered === false) && (clicked) ) ? (<h2>{message}</h2>) : (isAuthenticated() && (entered === true) && (!clicked)) && (alreadyWon !== true) ? (<h2>Sorry, you didn't win this time!</h2>) : (isAuthenticated() && (entered === true) && (!clicked)) && (won === true) ? (<h2>Congratulations, you've won!</h2>) : (isAuthenticated() && (entered === true) && (!clicked)) && (alreadyWon === true) ? (<h2>Congratulations, you've won!</h2>) : (ended && won === false) ? (<h2>Giveaway has ended!</h2>) : (ended && alreadyWon === false) ? (<h2>Giveaway has ended!</h2>) : (<h2><Link to='/create'>Create an account to enter this Giveaway and so much more!</Link></h2>) }
         </div> }
         <br />
         <br />
         </div>
        <br />
        <div>  {(tier > 0) ? (<h3>View More Giveaways from {gwayInfo.name}:</h3>) : (<h3>View More Giveaways:</h3>)}</div>
        <div className="home-giveaways"> 
        {arr.map((giveaway) => {
                    return (
                        <div key={giveaway.gwayid} className="glass-front">
                          <div className="container-gwayswithbiz">
                          <div className="container-gwaytitle">
                          { giveaway.origintable === 'apigway' ? <Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link> : <Link target="_blank" to={`/instant/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link> }
                          { giveaway.origintable === 'apigway' ? <h3 className="title"><Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> : <h3 className="title"><Link target="_blank" to={`/instant/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> }
                            </div>
                            <p>by <Link target="_blank" to={`/business/${giveaway.sponsorid}`}>{giveaway.name}</Link></p>
                            </div>
                          { giveaway.origintable === 'apigway' ? <p><small>Ends: {new Date(giveaway.endon).toDateString()}</small></p> : <p><small>Instant Win</small></p> }
                        </div>
                    )
                })}
                </div>
        <br />
        <p>Does this Giveaway break the rules?</p>
        <Link to={`/reportinstant/${match.params.gwayid}`}><p>Report</p></Link>
        <br />
        </div>
        )
}
            <br />
            <br />
            <br />
            <br />
    </div>
  );
}

export default InstantWinDetails;