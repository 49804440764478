import React, { useState, useEffect }  from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import ExampleChart from './ExampleChart';
import ExampleChartPremium from './ExampleChartPremium';
import Compare2 from './Compare2';

const BizAbout = (props) => {

    const [item, setItem] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isToggled, setIsToggled] = useState(false);

    const onToggle = () => setIsToggled(!isToggled);

    useEffect(()=>{
        fetchItem();
    }, [])

    const fetchItem = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/content/bizabout`);
        const item = await fetchItem.json();
        setItem(item);
        setLoading(false);
    };

    return (
    <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
        <h1>{item.bizInfo}</h1>
        <div className="focus-about">
        <h2>No cost digital marketing. Or low cost digital marketing. You pick.</h2>
        <br />
        <h3>Givmap is the Online Giveaway Marketplace.</h3>
        <div className="focus-text-small">
        <p>Givmap is a platform for businesses of all sizes (S,M,L,XL) to connect to new customers.</p>
        <p>Giveaways help promote small business products in a more hands-on way than traditional advertising does.</p>
        <p>By giving customers a chance to win their product, a business is able to communicate valuable information about their products to those most interested.</p>
        <p>This dynamic solves the problem of people becoming desensitized to traditional online advertising (banner blindness).</p>
        <p>Givmap can dramatically improve your Cost-per-Click ratio.</p>
        </div>
        <br />
        <h3>Givmap makes running a Giveaway easy.</h3>
        <div className="focus-text-small">
        <p>We want to democratize digital marketing by providing free tools to small businesses everywhere.</p>
        <p>The Givmap platform is simple and effective; any business can create a free profile, upload their product catalog, and start running Giveaways.</p>
        <p>It's also free and easy to view analytics about views of your shop, products, and Giveaways.</p>
        <p>Get to know what your customers want through Givmap.</p>
        </div>
        <br />
        <h3>Givmap as an Analytics Hub</h3>
        <div className="focus-text-small">
        <p>Your Business Dashboard will immediately be available upon creating a Business Account.</p>
        <p>Your CPC and other statistics will be easily accessible on your Dashboard, including charts to visualize your data.</p>
        </div>
        {isToggled ? ( <h3>Premium</h3>) : ( <h3>Free</h3>)}
        <label className="toggle-switch">
            <input type="checkbox" checked={isToggled} onChange={onToggle} />
            <span className="switch" />
        </label>
        <div className="glass-chart">
        {isToggled ? <ExampleChartPremium /> : <ExampleChart />}
        </div>
        <br />
        <h3>$29 per Month. Premium Accounts do more.</h3>
        <div className="focus-text-small">
        <p>With a Premium Account, you have access to more analytics and insights.</p>
        <p>Premium also allows you to schedule giveaways in advance.</p>
        <p>Premium businesses' giveaways and profiles may appear on the front page.</p>
        <p>For only $29 per month, you can supercharge your organization's marketing mix.</p>
        </div>
        <br />
        <Compare2 />
        <br />
        <span>🎁</span>
        <h3>Let's get started!</h3>
        </div>
        <h3><Link to="/businesscreate">Business Sign Up</Link><br /></h3>
        <h3><Link to="/businesslogin">Business Login</Link><br /></h3>
        </div>)}
        <br />
            <br />
            <br />
            <br />
    </div>
    )
}

export default BizAbout;