import React, { useEffect, useState } from 'react';
import Spinner from './Spinner';

const RandomSponsor = (props) => {

    const [loading, setLoading] = useState(true);
    const [biz, setBiz] = useState([]);
    const [isSet, setIsSet] =useState(false);

    const getBiz = async () => {
        const fetchBiz = await fetch(`${process.env.REACT_APP_API_URL}/b/randomsponsor`);
        const bizInfo = await fetchBiz.json();
        setBiz(bizInfo);
        setLoading(false);
        setIsSet(true);
    }

    useEffect(() => {
      getBiz()
    }, [])

    useEffect(() => {
        if (biz && loading === false && isSet === true) {
            window.location = `/business/${biz.sponsorid}`
        } else return
    }, [isSet])

    return (
        <div>
            <br />
            <br />
            <br />
            <Spinner />
        </div>
    )

}

export default RandomSponsor;