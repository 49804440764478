import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import Spinner from './Spinner';

const Feed = () => {

    const [loading, setLoading] = useState(true);

    const [user] = useState(JSON.parse(localStorage.getItem('user')) || [])
    const [img, setImg] = useState([]);

    const [feed, setFeed] = useState([]);
    const [data, setData] = useState(feed);

    const [isOpen, setIsOpen] = useState(false);

    const [currentItems, setCurrentItems] = useState([]);
  
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(60);
  
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

    const [sortType, setSortType] = useState("recent");

    const today = new Date().toISOString().slice(0, 10);
  
    const newFeedFetcher = async () => {
      const fetchEntered = await fetch(`${process.env.REACT_APP_API_URL}/b/mygwayscombonolog/${user.entrantid}`, { headers: { 'x-access-token': localStorage.getItem('x-access-token')} })
      const enteredArr = await fetchEntered.json();
      const fetchFeed = await fetch(`${process.env.REACT_APP_API_URL}/b/gwayfeedcombo/${user.entrantid}/${today}`, { headers: { 'x-access-token': localStorage.getItem('x-access-token')} })
      const feedArr = await fetchFeed.json();
      const result = enteredArr.map((g) => {return g.gwayid;});
      if (feedArr !== false) {
        feedArr.map(g => {
          if (result.includes(g.gwayid)) {
            g.entered = true;
          } else {
            g.entered = false;
          } return g;
        });
          setFeed(feedArr);
          setData(feedArr);
          setLoading(false);
      }
        setFeed(feedArr);
        setData(feedArr);
        setLoading(false);
    }

    const fetchImg = async () => {
        const fetchIt = await fetch(`${process.env.REACT_APP_API_URL}/b/entimg/${ user.entrantid }`);
        const img = await fetchIt.json();
        setImg(img);
    };

    useEffect(() => {
      fetchImg();      
      newFeedFetcher();
    }, [])

    function isFollowing() {
        return feed !== false;
    }

    const renderData = (giveaways) => {
        return (
            <div className="focus-invisible-array">
            {giveaways.map(giveaway => (
                <div key={giveaway.gwayid} className="focus-array-item">
                             { giveaway.origintable === 'apigway' ? <Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link> : <Link target="_blank" to={`/instant/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link>}
                               { giveaway.origintable === 'apigway' ? <h3 className="title"><Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> : <h3 className="title"><Link target="_blank" to={`/instant/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> }
                                <p>by <Link target="_blank" to={`/business/${giveaway.sponsorid}`}>{giveaway.name}</Link></p>
                                { giveaway.origintable === 'apigway' ? <p><small>Ends: {new Date(giveaway.endon).toDateString()}</small></p> : <p><small>Instant Win</small></p> }
                                { giveaway.entered === true ? <p><small><span>Entered ✔️</span></small></p> : ''}
                            </div>
            ))}
          </div>
        );
      };
  
  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  
  useEffect(() => {
    if (data === false) {
      return
    } else {
     const oldCurrent = data.slice(indexOfFirstItem, indexOfLastItem);
     setCurrentItems(oldCurrent);
    }
  }, [data, currentPage, itemsPerPage])

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  const handleLoadMore = () => {
    setitemsPerPage(itemsPerPage + 5);
  };

  function isArrBig() {
    return data.length > 60;
  }

const handleFilterChange = (e, filterType) => {
  switch (filterType) {
      case "sort":
          setSortType(e.target.value)
          break;
      default: break;
  }
}

useEffect(()=> {
  let a = [...data]
  let arr = a.map(x => ({
    ...x,
    created: new Date(x.created_at).getTime(),
      newValue: parseFloat(x.value)
}))
  if (sortType === "recent") {
    arr.sort((a, b) => b.created - a.created)
  } if (sortType === "old") {
    arr.sort((a, b) => a.created - b.created)
  } if (sortType === "high") {
    arr.sort((a, b) => b.newValue - a.newValue)
  } if (sortType === "low") {
    arr.sort((a, b) => a.newValue - b.newValue)
  }
  setData(arr)
}, [sortType])

    return (
    <div>
                <br />
                <br />
                <br />
                {
            (loading) ? ( <Spinner /> ) : (
    <div className="focus-div">
      <div className="dash-header">
    <Link to={`/upload`}><img id="image" src={`${process.env.REACT_APP_CDN_URL}/${img.avatar}`} alt="" height="50" width="50" /></Link>
        <h1>Welcome, {user.username}!</h1>
              </div>
              {(feed === false) ? ('') : (
        <div className="focus-invisible-array">
        <form className="focus-container">
                <label htmlFor="sort"><h3>Sort by: </h3></label>
                <select name="sort" id="sort" onChange={(e) => handleFilterChange(e, "sort")}>
                <option value="recent">Recent</option>
                <option value="old">Older</option>
                <option value="high">Value (High to Low)</option>
                <option value="low">Value (Low to High)</option>
                </select>
            </form>
        </div>)
        }
            { ( isFollowing() ) ? (<div><h2>Active Giveaways by Companies you follow: </h2><div>
            {renderData(currentItems)}
            {isArrBig() ? (
      <div className="pageNumbersDiv">
      <button onClick={handleLoadMore} className="loadmore">
        Load More
      </button>
      </div>) : ('')}
            </div></div>) : (<div><br /><br /><br /><h2>Follow businesses and giveaways will appear here!</h2></div>)}
            </div>)
    }
                <br />
                <br />
                <br />
                <br />
    </div>
    )

}

export default Feed;