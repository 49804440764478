import React from "react";
import { AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Area, ResponsiveContainer} from "recharts";

const ExampleChartPremium = (props) => {

  var yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  var dayBeforeYes = new Date();
  dayBeforeYes.setDate(dayBeforeYes.getDate() - 2);
  var threeDaysAgo = new Date();
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
  var fourDaysAgo = new Date();
  fourDaysAgo.setDate(fourDaysAgo.getDate() - 4);
  var fiveDaysAgo = new Date();
  fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);
  var sixDaysAgo = new Date();
  sixDaysAgo.setDate(sixDaysAgo.getDate() - 6);
  var sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  var eightDaysAgo = new Date();
  eightDaysAgo.setDate(eightDaysAgo.getDate() - 8);
  var nineDaysAgo = new Date();
  nineDaysAgo.setDate(nineDaysAgo.getDate() - 9);
  var tenDaysAgo = new Date();
  tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
  var elevenDaysAgo = new Date();
  elevenDaysAgo.setDate(elevenDaysAgo.getDate() - 11);
  var twelveDaysAgo = new Date();
  twelveDaysAgo.setDate(twelveDaysAgo.getDate() - 12);
  var thirteenDaysAgo = new Date();
  thirteenDaysAgo.setDate(thirteenDaysAgo.getDate() - 13);
  var fourteenDaysAgo = new Date();
  fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);
  var fifteenDaysAgo = new Date();
  fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);
  var sixteenDaysAgo = new Date();
  sixteenDaysAgo.setDate(sixteenDaysAgo.getDate() - 16);
  var seventeenDaysAgo = new Date();
  seventeenDaysAgo.setDate(seventeenDaysAgo.getDate() - 17);
  var eighteenDaysAgo = new Date();
  eighteenDaysAgo.setDate(eighteenDaysAgo.getDate() - 18);
  var nineteenDaysAgo = new Date();
  nineteenDaysAgo.setDate(nineteenDaysAgo.getDate() - 19);
  var twentyDaysAgo = new Date();
  twentyDaysAgo.setDate(twentyDaysAgo.getDate() - 20);
  var twentyOneDaysAgo = new Date();
  twentyOneDaysAgo.setDate(twentyOneDaysAgo.getDate() - 21);
  var twentyTwoDaysAgo = new Date();
  twentyTwoDaysAgo.setDate(twentyTwoDaysAgo.getDate() - 22);
  var twentyThreeDaysAgo = new Date();
  twentyThreeDaysAgo.setDate(twentyThreeDaysAgo.getDate() - 23);
  var twentyFourDaysAgo = new Date();
  twentyFourDaysAgo.setDate(twentyFourDaysAgo.getDate() - 24);
  var twentyFiveDaysAgo = new Date();
  twentyFiveDaysAgo.setDate(twentyFiveDaysAgo.getDate() - 25);
  var twentySixDaysAgo = new Date();
  twentySixDaysAgo.setDate(twentySixDaysAgo.getDate() - 26);
  var twentySevenDaysAgo = new Date();
  twentySevenDaysAgo.setDate(twentySevenDaysAgo.getDate() - 27);
  var twentyEightDaysAgo = new Date();
  twentyEightDaysAgo.setDate(twentyEightDaysAgo.getDate() - 28);
  var twentyNineDaysAgo = new Date();
  twentyNineDaysAgo.setDate(twentyNineDaysAgo.getDate() - 29);
  var thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const logData = [{
    date: thirtyDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 51,
    "Total Views": 114
  },{
    date: twentyNineDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 78,
    "Total Views": 162
  },{
    date: twentyEightDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 85,
    "Total Views": 170
  },{
    date: twentySevenDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 87,
    "Total Views": 184
  },{
    date: twentySixDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 162,
    "Total Views": 322
  },{
    date: twentyFiveDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 90,
    "Total Views": 189
  },{
    date: twentyFourDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 75,
    "Total Views": 167
  },{
    date: twentyThreeDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 191,
    "Total Views": 297
  },{
    date: twentyTwoDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 199,
    "Total Views": 340
  },{
    date: twentyOneDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 125,
    "Total Views": 270
  },{
    date: twentyDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 133,
    "Total Views": 258
  },{
    date: nineteenDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 222,
    "Total Views": 399
  },{
    date: eighteenDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 126,
    "Total Views": 236
  },{
    date: seventeenDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 135,
    "Total Views": 307
  },{
    date: sixteenDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 148,
    "Total Views": 339
  },{
    date: fifteenDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 221,
    "Total Views": 491
  },{
    date: fourteenDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 157,
    "Total Views": 319
  },{
    date: thirteenDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 130,
    "Total Views": 246
  },{
    date: twelveDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 399,
    "Total Views": 603
  },{
    date: elevenDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 277,
    "Total Views": 579
  },{
    date: tenDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 184,
    "Total Views": 351
  },{
    date: nineDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 234,
    "Total Views": 440
  },{
    date: eightDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 329,
    "Total Views": 649
  },{
    date: sevenDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 290,
    "Total Views": 592
  },{
    date: sixDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 379,
    "Total Views": 783
  },{
    date: fiveDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 333,
    "Total Views": 752
  },{
    date: fourDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 422,
    "Total Views": 884
  },{
    date: threeDaysAgo.toISOString().slice(0, 10),
    "Profile Views": 601,
    "Total Views": 1202
  },{
    date: dayBeforeYes.toISOString().slice(0, 10),
    "Profile Views": 458,
    "Total Views": 996
  },{
    date: yesterday.toISOString().slice(0, 10),
    "Profile Views": 575,
    "Total Views": 1151
  }];

    return (
        <div>
        <div className="glass-chart-section">
            <h3>Total Views Past 30 Days Chart (Premium)</h3>
           <h3 style={{ color: '#82ca9d' }}>Total Views (Giveaways, Products, and Business Profile)</h3>
           <h3 style={{ color: '#8884d8' }}>Business Profile Views</h3>
           <ResponsiveContainer width="90%" height={250}>
        <AreaChart data={logData}>
        <defs>
            <linearGradient id="colorProfile" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.75}/>
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0.2}/>
            </linearGradient>
            <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.75}/>
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.2}/>
            </linearGradient>
        </defs>
        <XAxis dataKey="date" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="Total Views" stroke="#82ca9d" fillOpacity={1} fill="url(#colorTotal)" />
        <Area type="monotone" dataKey="Profile Views" stroke="#8884d8" fillOpacity={1} fill="url(#colorProfile)" />
        </AreaChart>
        </ResponsiveContainer>
      </div>
        </div>
    )
}

export default ExampleChartPremium;