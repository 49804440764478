import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Spinner from './Spinner';

const WriteReview = ({ match }) => {

    const initialInputState = { rating: '', comment: '' };
    const [eachEntry, setEachEntry] = useState(initialInputState);
    const {rating, comment} = eachEntry;
    const [user] = useState(JSON.parse(localStorage.getItem('user')));
    const [sponuser, setSponuser] = useState([]);
    const [api, setApi] = useState([]);
    const [loading, setLoading] = useState(true);
    const [gway, setGway] = useState("")

    useEffect(()=>{
        fetchApi();
        fetchItem();
    }, [])

    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/writereview`);
        const api = await fetchApi.json();
        setApi(api);
        setLoading(false);
    };

    const fetchItem = async () => {
          const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/active/${ match.params.gwayid }`);
          const item = await fetchItem.json();
          setGway(item);
          setSponuser(item.sponsorid);
      };

    const reviewCreate = async (data) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/b/ratings`, { apigwayid: gway.gwayid, apisponid: sponuser, apientrantid: gway.winnerid, rating: data.rating, comment: data.comment }, {headers: { 'x-access-token':  localStorage.getItem('x-access-token') } } )
        .then(response => {
        return response.status
        })
        .catch(err => Promise.reject('Review could not be created!'));
        }  

    const handleInputChange = e => {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
      };
    
      const handleFinalSubmit = e => {
          e.preventDefault();
          reviewCreate(eachEntry)
          .then(token => window.location = '/return')
          .catch(err => alert(err));
      };

    return (
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="glass-account-edit">
            <h1>{api.review}</h1>
            <div className="container">
        <form onSubmit={handleFinalSubmit}>    
        <div className="form-group">
        <label>Rating: </label>
        <select name="rating" className="form-control" onChange={handleInputChange}>
                 <option value="select">{rating}</option>
                 <option value="0">0</option>
                 <option value="1">1 ⭐</option>
                 <option value="2">2 ⭐⭐</option>
                 <option value="3">3 ⭐⭐⭐</option>
                 <option value="4">4 ⭐⭐⭐⭐</option>
                 <option value="5">5 ⭐⭐⭐⭐⭐</option>
               </select>
        </div>
        <div className="form-group">
        <label>Comment: </label>
        <input type="text" className="form-control" name="comment"
        onChange={handleInputChange}/>
        </div>
        <button type="submit" className="btn btn-default">Submit</button>
        </form>
        <br />
        </div>
            </div>)
}
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default WriteReview;