import React, { useState, useEffect } from 'react';
import Spinner from './Spinner';
import { Link } from 'react-router-dom';

const FAQ = (props) => {

    const [api, setApi] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        fetchApi();
    }, [])

    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/faq`);
        const api = await fetchApi.json();
        setApi(api);
        setLoading(false);
    };

    return ( <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
                <h1>{api.faq}</h1>
                <div className="focus-about">
                <h2>What are Giveaways?</h2>
                <div className="focus-rules-text">
                <p>Giveaways are Sweepstakes. In the United States, Sweepstakes are promotions where prizes may be won by chance and no consideration is required for people to enter. Giveaways are an underutlized tool for digital marketers to grow their brand.</p>
                </div>
                <h2>Who can run a Giveaway?</h2>
                <div className="focus-rules-text">
                <p>Any business can. Giveaways on Givmap are from the products or services typically sold to consumers by businesses; Givmap is not for anyone to get rid of their old sofa that's been sitting in the garage since the 1990s.</p>
                </div>
                <h2>How do I run a Giveaway on Givmap?</h2>
                <div className="focus-rules-text">
                <p>Simply create a business account, enter your business's information, including products/services, and start. </p>
                </div>
                <h2>Are there laws about Giveaways?</h2>
                <div className="focus-rules-text">
                <p>Yes, there are quite a few laws at both the federal and state level which govern Sweepstakes and other promotions. Your state may have different requirements from other states.</p>
                </div>
                <h2>What prizes can be Giveaways?</h2>
                <div className="focus-rules-text">
                <p>As mentioned, Giveaways on Givmap are from the products or services typically sold to consumers by businesses. If a business sells it, then they can also give it away; a boutique clothing shop can run Giveaways on products from their latest collection, an artist can run Giveaways on their works of art, and restaurants can even run Giveaways on their offerings. Gift cards make wonderful Giveaways too. Prizes cannot be given away on Givmap if their retail value exceeds $499. Alcohol, marijuana, guns, and other 18+ items may not be given away on Givmap.</p>
                </div>
                <h2>When I enter a Giveaway, what happens to my personal info like my email?</h2>
                <div className="focus-rules-text">
                <p>When you enter a Giveaway on Givmap, your personal information (email, address, etc.) is NOT passed along to the Business running the Giveaway. However, if you win a Giveaway, then your address, email, and name are provided to the Business so that they may ship you the prize you won and potentially contact you if necessary.</p>
                </div>
                <h2>If I won a Giveaway, will the prize be shipped to me?</h2>
                <div className="focus-rules-text">
                <p>Yes, when a user wins a Giveaway, the prize will be shipped to you by the business running the Giveaway. In the case of digital prizes, a business may deliver the prize electronically.</p>
                </div>
                <h2>I have not received my prize, what can I do?</h2>
                <div className="focus-rules-text">
                <p>Winners of Giveaways may message businesses to check on the status of their prize. Winners are encouraged to rate a business based on their satisfaction as well. If a business is unresponsive and a winner still hasn't received their prize, they are encouraged to use the 'Report Giveaway' feature and notify us. We reserve the right to take action against businesses who do not ship their prizes. However, Paseo Applications, LLC, through its Givmap.com service, is not responsible for a business's breach and failure to supply a prize to the winner of a Giveaway.</p>
                </div>
               </div>
               <h3><Link to="/about">About</Link><br /></h3>
            </div>)
            }
            <br />
            <br />
            <br />
           </div>
    )
};

export default FAQ;