import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Spinner from './Spinner';

function ReportDetails({ match }) {

    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState({});
    const [pressed, setPressed] = useState(false);

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    
      useEffect(() => {
          fetchItem();
      },[]);

    const fetchItem = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/onereport/${ match.params.reportid }`, { headers: { 'x-admin-key': localStorage.getItem('x-admin-key')} });
        const item = await fetchItem.json();
        setItem(item[0]);
        setLoading(false);
    };

    function isReviewed() {
        return item.adminreviewed === true;
    }

    async function approveGway (data) {
        setPressed(true)
        const url = `${process.env.REACT_APP_API_URL}/b/batchadminreview/${item.apigwayid}`;
        const head = {'x-admin-key': localStorage.getItem('x-admin-key')};
        return axios.patch(url, head)
        .then(response => {
        return response.status
        })
        .catch(err => Promise.reject('Failed!'));
        }
  
    async function removeGway (data) {
        setPressed(true)
        const url1 = `${process.env.REACT_APP_API_URL}/b/batchadminreview/${item.apigwayid}`;
        const url2 = `${process.env.REACT_APP_API_URL}/b/deactivategway/${item.apigwayid}`;
        const head = {'x-admin-key': localStorage.getItem('x-admin-key')};
        axios.patch(url1, head)
        return axios.patch(url2, head)
          .then(response => {
          return response.status
          })
          .catch(err => Promise.reject('Failed!'));
          }

    function submitApprove(event){
        event.preventDefault();
        approveGway()
        .catch(err => alert(err));
        };

        function submitRemove(event){
            event.preventDefault();
            removeGway()
            .catch(err => alert(err));
            };

  return (
    <div>
      <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div>
                {isReviewed() ? (<div>
                <h2>Administrator Has Reviewed Report</h2>
                </div>) : (<div>
                <h2>Report is Unreviewed!</h2>
                </div>) }
         <h2>Report of {item.itemname} Giveaway</h2>
         <p>Report ID: {item.reportid}</p>
         <p>Report Category: {item.reportcategory}</p>
         <p>Report Text: {item.report}</p>
         <p>Report Created: {item.created_at}</p>
         <Link target="_blank" to={`/giveaways/${item.apigwayid}`}><p>View Giveaway</p></Link>
         <br />
         {(!pressed) ? (<div>
         <button onClick={submitApprove}> Approve </button> <button onClick={submitRemove}> Remove </button>
         </div>) : ('') }
         <br />
      <h3><Link to={`/adminreports`}>Back to Admin Reports List</Link></h3>
            </div>)
}
    <br />
    <br />
    <br />
    <br />
    </div>
  );
}

export default ReportDetails;