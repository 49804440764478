import React, { useMemo, useState, useEffect } from "react";
import { useTable, useFilters, useSortBy, usePagination  } from "react-table";
import axios from "axios";
import {Link} from 'react-router-dom';
import Spinner from './Spinner';
import "./TestTableStyle.css"

function Table({ columns, data }) {
    
  const [filterInput, setFilterInput] = useState("");

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 12 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setFilter("itemname", value);
    setFilterInput(value);
  };

  return (
    <>
    <br />
    <br />
    <br />
      <input
        value={filterInput}
        onChange={handleFilterChange}
        placeholder={"Search name"}
      />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                     {cell.column.Header === 'Created' ? new Date(cell.value).toDateString() : cell.column.Header === 'Scheduled Start' ? new Date(cell.value).toDateString() : cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <span>
          Page{' '}
            {pageIndex + 1} of {pageOptions.length}
        </span>
        {' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[12, 24, 48, 96].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

const SponAll = (props) => {

  const [loading, setLoading] = useState(true);
  const [sponuser, setUser] = useState(JSON.parse(localStorage.getItem('sponuser')) || []);
  const [giveaways, setGiveaways] = useState([]);
  const [data, setData] = useState([]);

  const fetcher = () => {
    try {
    axios.get(`${process.env.REACT_APP_API_URL}/b/spongwaycombo/${sponuser.sponsorid}`)
    .then(res => {
        setGiveaways(res.data);
        setData(res.data);
        setLoading(false);
    }) } catch (error){
        return
    }
}

useEffect(() => {
    fetcher();
}, [])

  const columns = useMemo(
    () => [
      {
        Header: "All Giveaways",
        columns: [
          {
            Header: "View",
            accessor: "origintable",
            Cell: (props) => {
              if (props.value === "apigway") {
                return(
                <Link target="_blank" to={`/gwayview/${props.row.original.gwayid}`}>{'View Giveaway'}</Link>
                )
              } else {
                return(
                <Link target="_blank" to={`/instantview/${props.row.original.gwayid}`}>{'View Giveaway'}</Link>
                )
              }
            }
          },
          {
            Header: "Name",
            accessor: "itemname"
          },
          {
            Header: "Created",
            accessor: "created_at"
          },
          {
            Header: "Scheduled Start",
            accessor: "starton"
          },
          {
            Header: "Scheduled End",
            accessor: "endon",
            Cell: (props) => {
              if (props.row.original.origintable === "apigway") {
                return(
               <span>{new Date(props.value).toDateString()}</span>
                )
              } else {
                return(
                  <span>N/A</span>
                )
              }
            }
          },
          {
            Header: "Location Available",
            accessor: "location"
          }
        ]
      }
    ],
    []
  );

  function noGiveaways() {
    return giveaways === false && loading === false;
  }

  return (
    <div>
       {
        (loading) ? (<div>
          <br />
          <br />
          <br />
          <br />
           <Spinner />
           </div> ) : (
             <div>
                  <br />
                  <br />
                  <br />
                  <br />
                   { noGiveaways() ? (<div className="glass-chart">
        <h1>You have no Giveaways yet!</h1>
        </div>) :
      (
        <div className="glass-chart">
    <div className="App-tab">
      <Table columns={columns} data={data} />
    </div>
    </div>)}
    </div>)}
    </div>
  );
}

export default SponAll;