import React, { useState, useEffect } from "react";
import Spinner from './Spinner';
import axios from 'axios';

const ForgotPass = (props) => {

    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState('');
    const initialInputState = { email: '' };
    const [eachEntry, setEachEntry] = useState(initialInputState);
    const {email} = eachEntry;

    useEffect(()=>{
        fetchItem();
    }, [])

    const fetchItem = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/content/entforgot`);
        const item =  await fetchItem.json();
        setItem(item);
        setLoading(false);
    };

    const handleInputChange = e => {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
      };

      const handleFinalSubmit = e => {
        e.preventDefault();
        entrantForgot(eachEntry)
        .then(token => window.location = '/forgotpassword')
        .catch(err => alert(err));
    };

    function entrantForgot (data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/b/passwordemail`, { email: data.email })
        .then(response => {
            return response.data
            })
            .catch(err => Promise.reject('Email could not be sent!'));
        }

    return (
        <div>
            <br />
            <br />
            <br />
               {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
                <div>
                <h1>
                    {item.title} my Password:
                </h1>
                </div>
                <div className="focus-login">
                <h2>Step 1</h2>
                <h3>
                    Enter the email address linked to your Givmap Account.
                </h3>
                <br />
                <h2>Step 2</h2>
                <h3>
                    Check your email and click the link we sent you.
                </h3>
                <br />
                <h2>Step 3</h2>
                <h3>
                    Enter a new password for your Givmap Account.
                </h3>
                <br />
             <form onSubmit={handleFinalSubmit}>
                <div className="form-group">
                    <label><h2>Email: </h2></label>
                         <input type="text" className="form-control" name="email"
                         onChange={handleInputChange}/>
                </div>
                <br />
                <button type="submit" className="february-form-submit-button">Send Email</button>
                <br />
            </form>
            </div>
            <br />
            </div>) }
        </div>
    )
};

export default ForgotPass;