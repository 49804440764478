import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Spinner from './Spinner';

function ReportGiveaway({ match }) {

    const initialInputState = { reportcategory: '', report: '' };
    const [eachEntry, setEachEntry] = useState(initialInputState);
    const {reportcategory, report} = eachEntry;
    const [loading, setLoading] = useState(true);
    const [pressed, setPressed] = useState(false);
    const [api, setApi] = useState([]);
    const [submission, setSubmission] = useState([])

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    useEffect(()=>{
        fetchApi();
    }, [])

    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/report`);
        const api = await fetchApi.json();
        setApi(api);
        setLoading(false);
    };

    async function reportGway () {
        setPressed(true)
        setLoading(true)
        return axios.post(`${process.env.REACT_APP_API_URL}/b/createreport/${match.params.gwayid}`, { reportcategory: reportcategory, report: report })
        .then(response => {
            setSubmission(response.data)
            setLoading(false)
        return response.status
        })
        .catch(err => Promise.reject('Failed!'));
        }

    function submitReport(event){
        event.preventDefault();
        reportGway()
        .catch(err => alert(err));
        };

        const handleInputChange = e => {
            setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
          };

  return (
    <div>
      <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="glass-account-edit">
               <h1>{api.title}</h1>
              { (!pressed) ? (<div>
         <br />
         <div>
         <form onSubmit={submitReport}>        
         <div className="form-dash">
        <label>Category: </label>
        <select name="reportcategory" className="form-control" onChange={handleInputChange}>
                 <option value="select">{reportcategory}</option>
                 <option value="Breaks Rules">Breaks Givmap Rules</option>
                 <option value="Copyright Violation">Copyright Violation</option>
               </select>
        </div>
        <div className="form-group">
        <input type="text"  placeholder="Comment" className="form-control" name="report"
        onChange={handleInputChange}/>
        </div>
       {(!pressed) ? (<div>
            <button type="submit" className="btn btn-default"> Submit Report </button>
         </div>) : (<h2>{submission.message}</h2>) }
        </form>
         <br />
         </div>
         </div>) : (<h2>{submission.message}</h2>) }
      <h3><Link to={`/giveaways/${match.params.gwayid}`}>Back</Link></h3>
            </div>)
}
    <br />
    <br />
    <br />
    <br />
    </div>
  );
}

export default ReportGiveaway;