import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import Spinner from './Spinner';

const AccountSettings = () => {

    const [loading, setLoading] = useState(true);

    const [user] = useState(JSON.parse(localStorage.getItem('user')) || [])
    const [img, setImg] = useState([]);
    const [info, setInfo] = useState([]);

    const today = new Date().toISOString().slice(0, 10);

    useEffect(() => {
        fetchImg();
    }, [])

    const fetchImg = async () => {
        const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/content/acctset`);
        const info = await fetchInfo.json();
        const fetchIt = await fetch(`${process.env.REACT_APP_API_URL}/b/entimg/${ user.entrantid }`);
        const img = await fetchIt.json();
        setImg(img);
        setInfo(info);
        setLoading(false);
    };
    
    return (
    <div>
                <br />
                <br />
                <br />
                {
            (loading) ? ( <Spinner /> ) : (
    <div className="focus-div">
      <div className="dash-header">
      <Link to={`/upload`}><img id="image" src={`${process.env.REACT_APP_CDN_URL}/${img.avatar}`} alt="" height="50" width="50" /></Link>
         <h1>{info.title}</h1>
      </div>
      <div className="focus-invisible-array">
      <div className="focus-stat-item"><h2><Link to='/upload'>Change profile image</Link></h2></div>
      <div className="focus-stat-item"><h2><Link to='/editmyprofile'>Edit Profile</Link></h2></div>
      <div className="focus-stat-item"><h2><Link to='/editmyemail'>Edit Login Credentials</Link></h2></div>
      </div>
      <br />
            </div>)
    }
                <br />
                <br />
                <br />
                <br />
    </div>
    )

}

export default AccountSettings;