import React, {useState, useEffect} from 'react';
import Spinner from './Spinner';
import { Link } from 'react-router-dom';

const ViewWinnerInstant = ({ match }) => {

    const [winners, setWinners] = useState([]);
    const [sponuser, setUser] = useState(JSON.parse(localStorage.getItem('sponuser')))
    const [api, setApi] = useState([]);
    const [loading, setLoading] = useState(true);
  
    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/viewwinnerinstant`);
        const api = await fetchApi.json();
        setApi(api);
    };

      const fetchItem = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/entwinnerwithdetails/${ match.params.gwayid }`, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} });
        const item = await fetchItem.json();
        setWinners(item);
        setLoading(false);
    };

    const renderData = (arr) => {
        return (
            <div className="glass-container-array">
            {arr.map(winner => (
                <div key={winner.apigwayid} className="glass-container-gway">
                                <h3><Link to={`/viewwinnerdetails/${winner.instantwinnerid}/${match.params.gwayid}`}>{winner.firstname} {winner.lastname}</Link></h3>
                </div>
        
            ))}
          </div>
        );
      };

    useEffect(()=>{
        fetchApi();
        fetchItem();
    }, [])
    
      function ready() {
        return winners.length > 0 && winners.map(w => w.sponsorid === sponuser.sponsorid)
      }

      function notReady() {
        return winners.length === 0 && winners.map(w => w.sponsorid === sponuser.sponsorid)
      }
      
    return ( 
    <div>
      <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="glass-account-edit">
      { ( ready() ) ? (
          <div>
      <div>
<h1>Winners:</h1>
      </div>
       <div>
       {renderData(winners)}
       </div>
       </div>
      ) : ( notReady() ) ? (<h1>Waiting for {api.winner} to add address information...</h1>) : (<h1> Unavailable </h1>) }
   </div>)
}
    <br />
            <br />
            <br />
            <br />
    </div>
    )
};

export default ViewWinnerInstant;