import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const GiveawayGuide = (props) => {

    const [api, setApi] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        fetchApi();
    }, [])

    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/gwayguide`);
        const api = await fetchApi.json();
        setApi(api);
        setLoading(false);
    };

    return (
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
                <h1>{api.guide}</h1>
                <div className="focus-about">
                <h2>Step 1: Create a Product</h2>
                <div className="focus-rules-text">
                <p>Choose a Product you want to run a Giveaway on.</p>
                <p>This Product should be something you sell in your store, service you offer, or a gift card to your store.</p>
                <p>Enter the product's name, its category, a brief description, the price you offer the product, select your business name, and submit.</p>
                <p>Don't be afraid to describe it in great detail - color, size, age, etc. are all helpful</p>
                <p>You've just added an Product to your Givmap Shop!</p>
                </div>
                <h2>Step 2: Upload an Image for your Product</h2>
                <div className="focus-rules-text">
                <p>Open the sidebar and view your Products.</p>
                <p>Navigate to your new Product's page and select "Add Images to this Product."</p>
                <p>From your Product Image Dashboard, select "Add or Change Product Image."</p>
                <p>Browse your files and select an Image for your Product. Select "Upload" to see what your Product Image will look like on Givmap.</p>
                <p>If you are happy with this Image, set it as the Primary Image for this Product.</p>
                <p>If you want to change an Product's Image, simply repeat the steps above. You will be unable to edit your Product while a Giveaway featuring that Product is active.</p>
                <h3>If a Product does not have an Image, the Product will not appear in your Givmap Shop!</h3>
                <p>Product Images should be square (30x30, 50x50, etc.). Product Images with white/no backgrounds will look better on Givmap.</p>
                </div>
                <h2>Step 3: Create a Giveaway</h2>
                <div className="focus-rules-text">
                <p>From the sidebar, navigate to "Create a new Giveaway."</p>
                <p>Select the type of Giveaway you wish to create - Classic/Countdown Giveaways will end at a predetermined date. Instant Win Giveaways end after the predetermined number of winners have been randomly selected.</p>
                <p>For Classic/Countdown Giveaways, select the date you want to end the Giveaway. Typically, you want your Giveaway to only last a couple of weeks.</p>
                <p>For Instant Win Giveaways, selecting the total number of winners and the number of desired entries into the Giveaway determines the Giveaway's odds of winning. Total number of entries cannot exceed 9,999,999. Keep your desired Cost-per-click (CPC) in mind.</p>
                <p>Choose a Product from your Shop that will be the Giveaway Prize.</p>
                <p>Select an available location for your Giveaway. This will only allow people whose listed address is in the location you select to enter the Giveaway.</p>
                <p>If you are looking to target local customers, you can limit your Giveaway to people from your state.</p>
                <p>Or, if your business is entirely online, you can open your Giveaway to anyone residing in the United States.</p>
                <p>Select your business and submit your Giveaway.</p>
                <p>Your Giveaway is live!</p>
                </div>
                <h2>Step 4: Ship your Product to the Winner of the Giveaway</h2>
                <div className="focus-rules-text">
                <p>After your Giveaway has ended, navigate back to that Giveaway.</p>
                <p>Click on the Giveaway. It is now expired and will appear in "Expired Giveaways" on the sidebar.</p>
                <p>To view a winner's shipping information, select "View Winner Information."</p>
                <p>You should be able to see the winner's name, address, city, state, and zip code.</p>
                <p>Use that information to ship the Giveaway Prize to the winner.</p>
                <p>Please send the winner of the Giveaway shipping confirmation through the email listed.</p>
                <p>Shipping the prize to the winner is the responsibility of the business which created the Giveaway.</p>
                <p>If there are any problems shipping, immediately contact the winner.</p>
                <h3>Congrats! You've completed a Giveaway! Keep it up!</h3>
                </div>
                </div>
                <h3><Link to="creategiveaway">Create a new Giveaway!</Link></h3>
                </div>) }
                <br />
                <br />
                <br />
                <br />
           </div>
        )
};

export default GiveawayGuide;