import React from 'react';

const SpinnerSearching = (props) => {
    return (
        <div>
             <div className="loading-process">
            <h2>Searching...</h2>
        </div>
        <div className="loading-spinner-processing">
            
        </div>
        </div>
    )
}

export default SpinnerSearching;