import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import SpinnerSearching from './SpinnerSearching';

const SearchFromNav = ({ match }) => {

    const { search } = window.location;
    const query = new URLSearchParams(search).get('s');

    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);

    const [searchResults, setSearchResults] = useState([]);
    const [results, setResults] = useState(false);
    const [rows, setRows] = useState(searchResults);
    const [gways, setGways] = useState([]);
    const [biz, setBiz] = useState([]);
    const [displayGways, setDisplayGways] = useState(gways);
    const [displayBusinesses, setDisplayBusinesses] = useState(biz);
    const initialInputState = { term: query };
    const [eachEntry, setEachEntry] = useState(initialInputState);
    const {term} = eachEntry;
    const [origintable, setOrigintable] = useState('all');
    const [category, setCategory] = useState('all');
    const [location, setLocation] = useState('all');
    const [searched, setSearched] = useState(false);
    const [originFilter, setOriginFilter] = useState(false);
    const [categoryFilter, setCategoryFilter] = useState(false);
    const [locationFilter, setLocationFilter] = useState(false);
    const [gwaysRef, setGwaysRef] = useState([]);
    const [bizRef, setBizRef] = useState([]);

    const [data, setData] = useState(searchResults);

    const [currentGways, setCurrentGways] = useState([]);
    const [currentBusinesses, setCurrentBusinesses] = useState([]);
  
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(60);
  
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    
    const [pageData, setPageData] = useState([]);

    useEffect(()=> {
      navSearchCreate();
    }, [])

    async function navSearchCreate () {
      setLoading(false)
      setDisplayGways([])
      setDisplayBusinesses([])
      setProcessing(true);
      return axios.get(`${process.env.REACT_APP_API_URL}/b/navsearch?term=${query}`)
      .then(response => {
      setSearchResults(response.data)
      setData(response.data)
      setProcessing(false);
      })
      .catch(err => Promise.reject('Search could not be completed!'));
      }

      const groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key);
               if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    }

      useEffect(() => {
        if (searchResults === false) {
            setOrigintable("all");
            setCategory("all");
            setLocation("all");
            setGways(false);
            setBiz(false);
            setRows(false);
            setSearched(true);
            return setResults(false);
        } else {
        let grouped = groupBy(searchResults, searchResult => searchResult.origintable);
        setGways(grouped.get('apigway'));
        setBiz(grouped.get('apisponsor'));
        setRows(searchResults);
        setOrigintable("all");
        setCategory("all");
        setLocation("all");
        setSearched(true);
        return setResults(true);
        }
    }, [searchResults])

    useEffect(() => {
      setSearched(false)
        return setResults(false);
    }, [])

    useEffect(() => {
        return setDisplayBusinesses(biz);
    }, [biz])

    useEffect(() => {
        return setDisplayGways(gways);
    }, [gways])

      async function searchCreate (data) {
        setDisplayGways([])
        setDisplayBusinesses([])
        const params = {term};
        setProcessing(true);
        return axios.get(`${process.env.REACT_APP_API_URL}/b/fuzzysearch/`, { params })
        .then(response => {
        setSearchResults(response.data)
        setData(response.data)
        setProcessing(false);
        })
        .catch(err => Promise.reject('Search could not be completed!'));
        }

      const handleInputChange = e => {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
      };

      const handleFinalSubmit = e => {
        e.preventDefault();
        searchCreate(eachEntry)
        .catch(err => alert(err));
    };

    function noResults() {
        return rows === false;
    }

    function showFilter() {
        return results === true;
    }

    function noTypeFilter() {
        return gways === undefined | biz === undefined;
    }

    function noDisplay() {
        return searched === false;
    }

    function isProcessing() {
      return processing === true && searched === true;
    }

    function noFilterResults() {
      return (originFilter === true || categoryFilter === true || locationFilter === true) && (bizRef.length < 1 && gwaysRef.length < 1);
    }

    const renderData = (displayGways, displayBusinesses) => {
        return (
          <div>
            <div className="search-container">
                {noFilterResults() ? (<div><h2>No matches!</h2></div>) : ''}
                </div>
                <div className="focus-invisible-array">
            {displayGways && displayGways.map((gway) => {
                     return (
                         <div key={gway.id} className="focus-array-item">
                             <div>
                             {gway.type === 'classic' ? <Link target="_blank" to={`/giveaways/${gway.id}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${gway.image}`} alt="" height="75" width="75" /></Link> : <Link target="_blank" to={`/instant/${gway.id}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${gway.image}`} alt="" height="75" width="75" /></Link>}
                             {gway.type === 'classic' ? <h3><Link target="_blank" to={`/giveaways/${gway.id}`}>{gway.name}</Link></h3> : <h3><Link target="_blank" to={`/instant/${gway.id}`}>{gway.name}</Link></h3>}
                             </div>
                             {gway.type === 'classic' ? <p><small>Classic Giveaway</small></p> : <p><small>Instant Win</small></p>}
                         </div>
                     )
                 })}
              {displayBusinesses && displayBusinesses.map((displayBusiness, key) => {
                     return (
                         <div key={displayBusiness.id} className="focus-array-item">
                             <div>
                             <Link target="_blank" to={`/business/${displayBusiness.id}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${displayBusiness.image}`} alt="" height="75" width="75" /></Link>
                             <h3><Link target="_blank" to={`/business/${displayBusiness.id}`}>{displayBusiness.name}</Link></h3>
                             </div>
                             <p><small>Business</small></p>
                         </div>
                     )
                 })}</div></div>
        );
      };
  
  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(pageData.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  
  useEffect(() => {
    if (searched === false) {
      setPageData([]);
      setCurrentGways([]);
      setCurrentBusinesses([]);
    } if (searched === true && displayGways === undefined) {
      const currentBiz = displayBusinesses.slice(indexOfFirstItem, indexOfLastItem);
      const numPages = displayBusinesses;
      setPageData(numPages);
      setCurrentBusinesses(currentBiz);
    } if (searched === true && displayBusinesses === undefined) {
      const currentGiv = displayGways.slice(indexOfFirstItem, indexOfLastItem);
      const numPages = displayGways;
      setPageData(numPages);
      setCurrentGways(currentGiv);
    } if (searched === true && displayBusinesses !== undefined && displayGways !== undefined && data !== false) {
      const currentGiv = displayGways.slice(indexOfFirstItem, indexOfLastItem);
      const currentBiz = displayBusinesses.slice(indexOfFirstItem, indexOfLastItem);
      const numPages = displayGways.concat(displayBusinesses);
      setPageData(numPages);
      setCurrentGways(currentGiv);
      setCurrentBusinesses(currentBiz);
    }
  }, [searchResults, displayGways, displayBusinesses, currentPage, itemsPerPage])

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  const handleFilterChange = (e, filterType) => {
    switch (filterType) {
        case "origintable":
            setOrigintable(e.target.value)
            break;
        case "category":
            setCategory(e.target.value)
            break;
        case "location":
            setLocation(e.target.value)
            break;
        default: break;
    }
}
  
useEffect(()=> {
  if (gways === undefined) {
    let filteredBusinesses = biz
    let filteredGiveaways = []
  
    if (origintable !== "all") {
      filteredBusinesses = filteredBusinesses.filter(biz => biz.origintable === origintable)
    }     
    if (category !== "all") {
      filteredBusinesses = filteredBusinesses.filter(biz => biz.category === category)
    } 
    if (location !== "all") {
      filteredBusinesses = filteredBusinesses.filter(biz => biz.location === location)
    }     
    setGwaysRef(filteredGiveaways)
    setBizRef(filteredBusinesses)
    return setDisplayBusinesses(filteredBusinesses)
  }
  if (biz === undefined) {
    let filteredGiveaways = gways
    let filteredBusinesses = []

  if (origintable !== "all") {
    filteredGiveaways = filteredGiveaways.filter(giveaway => giveaway.origintable === origintable)
  }     
  if (category !== "all") {
    filteredGiveaways = filteredGiveaways.filter(giveaway => giveaway.category === category)   
  } 
  if (location !== "all") {
    filteredGiveaways = filteredGiveaways.filter(giveaway => giveaway.location === location)
  }     
  setGwaysRef(filteredGiveaways)
  setBizRef(filteredBusinesses)
  return setDisplayGways(filteredGiveaways)
  } else {
  let filteredGiveaways = gways
  let filteredBusinesses = biz

  if (origintable !== "all") {
    filteredGiveaways = filteredGiveaways.filter(giveaway => giveaway.origintable === origintable)
    filteredBusinesses = filteredBusinesses.filter(biz => biz.origintable === origintable)
  }     
  if (category !== "all") {
    filteredGiveaways = filteredGiveaways.filter(giveaway => giveaway.category === category)
    filteredBusinesses = filteredBusinesses.filter(biz => biz.category === category)
  } 
  if (location !== "all") {
    filteredGiveaways = filteredGiveaways.filter(giveaway => giveaway.location === location)
    filteredBusinesses = filteredBusinesses.filter(biz => biz.location === location)
  }     
  setGwaysRef(filteredGiveaways)
  setBizRef(filteredBusinesses)
  setDisplayGways(filteredGiveaways)
  setDisplayBusinesses(filteredBusinesses)
}
}, [origintable, category, location, rows])

useEffect(() => {
  if (results === true && origintable !== 'all') {
    setOriginFilter(true)
  } else setOriginFilter(false)
 }, [origintable])

 useEffect(() => {
  if (results === true && category !== 'all') {
    setCategoryFilter(true)
  } else setCategoryFilter(false)
 }, [category])

 useEffect(() => {
  if (results === true && location !== 'all') {
    setLocationFilter(true)
  } else setLocationFilter(false)
 }, [location])

 function isArrBig() {
  return (data.length > 60) && (bizRef.length < 1 && gwaysRef.length < 1);
}

    return (
        <div>
            <br />
            <br />
            <br />
        {(loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
        <div className="glass-search-container">
           <h1>Search Givmap 🔍</h1>
           <div className="search-form">
           <form onSubmit={handleFinalSubmit}>
                    <div className="form-group">
                            <input type="text" className="form-control" name="term" defaultValue={query}
                            onChange={handleInputChange}/>
                    </div>
                    <br/>
                <button type="submit" className="march-form-submit-button"> Search </button>
                </form>
                <br/>
           </div>
           </div>
           <br />
           {
    (isProcessing()) ? (<div><br /><br /><br /><SpinnerSearching /></div> ) : (
           <div>
           {(showFilter()) ? (<div className="focus-invisible-array">
          {(noTypeFilter()) ? (<div></div>) : (
             <form className="focus-container">
             <label htmlFor="origintable"><h3>Filter Results: </h3></label>
             <select name="origintable" id="origintable" onChange={(e) => handleFilterChange(e, "origintable")}>
             <option value="all">All</option>
             <option value="apigway">Giveaways</option>
             <option value="apisponsor">Businesses</option>
             </select>
         </form>)}
         <form className="focus-container">
             <label htmlFor="category"><h3>Filter by Category: </h3></label>
             <select name="category" id="category" onChange={(e) => handleFilterChange(e, "category")}>
             <option value="all">All</option>
             <option value="Antiques/Collectibles">Antiques/Collectibles</option>
             <option value="Art">Art</option>
             <option value="Athletic/Sports Gear">Athletic/Sports Gear</option>
             <option value="Automotive">Automotive</option>
             <option value="Beauty/Personal Care">Beauty/Personal Care</option>
             <option value="Books">Books</option>
             <option value="Clothing">Clothing</option>
             <option value="Computers/Electronics">Computers/Electronics</option>
             <option value="Crypto">Crypto</option>
             <option value="Fitness/Gym">Fitness/Gym</option>
             <option value="Food/Restaurant">Food/Restaurant</option>
             <option value="Hats/Headwear">Hats/Headwear</option>
             <option value="Health">Health</option>
             <option value="Home/Kitchen Goods">Home/Kitchen Goods</option>
             <option value="Kids/Toys">Kids/Toys</option>
             <option value="Luggage/Travel Gear">Luggage/Travel Gear</option>
             <option value="Magazines/Media">Magazines/Media</option>
             <option value="Movies/TV">Movies/TV</option>
             <option value="Music">Music</option>
             <option value="Musical Instruments">Musical Instruments</option>
             <option value="NFTs">NFTs (Non-Fungible Tokens)</option>
             <option value="Outdoors">Outdoors</option>
             <option value="Pets">Pets</option>
             <option value="Shoes">Shoes</option>
             <option value="Software">Software</option>
             <option value="Tickets">Tickets</option>
             <option value="Video Games/E-Sports">Video Games/E-Sports</option>
             <option value="Other">Other</option>
             </select>
         </form>
         <form className="focus-container">
             <label htmlFor="location"><h3>Filter by Location: </h3></label>
             <select name="location" id="location" onChange={(e) => handleFilterChange(e, "location")}>
             <option value="all">All</option>
             <option value="United States">United States</option>
             <option value="Alabama">Alabama</option>
             <option value="Alaska">Alaska</option>
             <option value="Arizona">Arizona</option>
             <option value="Arkansas">Arkansas</option>
             <option value="California">California</option>
             <option value="Colorado">Colorado</option>
             <option value="Connecticut">Connecticut</option>
             <option value="Delaware">Delaware</option>
             <option value="Florida">Florida</option>
             <option value="Georgia">Georgia</option>
             <option value="Hawaii">Hawaii</option>
             <option value="Idaho">Idaho</option>
             <option value="Illinois">Illinois</option>
             <option value="Indiana">Indiana</option>
             <option value="Iowa">Iowa</option>
             <option value="Kansas">Kansas</option>
             <option value="Kentucky">Kentucky</option>
             <option value="Louisiana">Louisiana</option>
             <option value="Maine">Maine</option>
             <option value="Maryland">Maryland</option>
             <option value="Massachusetts">Massachusetts</option>
             <option value="Michigan">Michigan</option>
             <option value="Minnesota">Minnesota</option>
             <option value="Mississippi">Mississippi</option>
             <option value="Missouri">Missouri</option>
             <option value="Montana">Montana</option>
             <option value="Nebraska">Nebraska</option>
             <option value="Nevada">Nevada</option>
             <option value="New Hampshire">New Hampshire</option>
             <option value="New Jersey">New Jersey</option>
             <option value="New Mexico">New Mexico</option>
             <option value="New York">New York</option>
             <option value="North Carolina">North Carolina</option>
             <option value="North Dakota">North Dakota</option>
             <option value="Ohio">Ohio</option>
             <option value="Oklahoma">Oklahoma</option>
             <option value="Oregon">Oregon</option>
             <option value="Pennsylvania">Pennsylvania</option>
             <option value="Rhode Island">Rhode Island</option>
             <option value="South Carolina">South Carolina</option>
             <option value="South Dakota">South Dakota</option>
             <option value="Tennessee">Tennessee</option>
             <option value="Texas">Texas</option>
             <option value="Utah">Utah</option>
             <option value="Vermont">Vermont</option>
             <option value="Virginia">Virginia</option>
             <option value="Washington">Washington</option>
             <option value="West Virginia">West Virginia</option>
             <option value="Wisconsin">Wisconsin</option>
             <option value="Wyoming">Wyoming</option>
             <option value="D.C.">District of Columbia</option>
             <option value="Puerto Rico">Puerto Rico</option>
             <option value="Guam">Guam</option>
             <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
             <option value="Northern Mariana Islands">Northern Mariana Islands</option>
             </select>
         </form>
         <br />
         </div>) : (<div> </div>)}
           <div>
           {( (noResults()) ? (<div><h1>No results for that search!</h1></div>) :
           (<div>
            {(noDisplay()) ? (<div> </div>) :
        (<div className="page-section-navs">
            {renderData(currentGways, currentBusinesses)}
            {isArrBig() ? (
      <div className="pageNumbersDiv">
      <ul className="pageNumbers">
        <li>
          <button
            onClick={handlePrevbtn}
            disabled={currentPage == pages[0] ? true : false}
          >
            Prev
          </button>
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}

        <li>
          <button
            onClick={handleNextbtn}
            disabled={currentPage == pages[pages.length - 1] ? true : false}
          >
            Next
          </button>
        </li>
      </ul>
      </div>) : ('')}
      </div>)}
            </div>))}
        </div>
        </div>)}
        <br />
        </div>)}
        <br />
        <br />
        <br />
        </div>
    )
}

export default SearchFromNav;