import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import GiveawayViewsChart from './GiveawayViewsChart';
import GiveawayEntriesChart from './GiveawayEntriesChart';
import SpinnerFetching from './SpinnerFetching';

const GiveawayLanding = ({ match }) => {

    const [processing, setProcessing] = useState(true);
    const [loading, setLoading] = useState(true);
    const [gway, setGway] = useState({});
    const [views, setViews] = useState([]);
    const [entries, setEntries] = useState(0);
    const [sponuser, setSponuser] = useState(() => JSON.parse(localStorage.getItem("sponuser")) || "");
    const [expired, setExpired] = useState(false);
    const [winner, setWinner] = useState(false);
    const [tier, setTier] = useState(sponuser.spontier);
    const [startTime, setStartTime] = useState([]);
    const [endTime, setEndTime] = useState([]);
    const [viewsLogData, setViewsLogData] = useState([]);
    const [entriesLogData, setEntriesLogData] = useState([]);
    const [timeSet, setTimeSet] = useState(false);
    const [time, setTime] = useState([]);
    const [startUtc, setStartUtc] = useState([]);
    const [endUtc, setEndUtc] = useState([]);
    const [endReal, setEndReal] = useState(endTime)

    const today = new Date().toISOString().slice(0, 10);
    var tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1);
    
    const fetchGway = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/gwayview/${ match.params.gwayid }`);
        const item = await fetchItem.json();
        setGway(item);
        if (today < item.endon) {
            var yesterday = new Date()
            yesterday.setDate(yesterday.getDate() - 1);
        setStartTime((new Date(item.starton)).toISOString().split('T')[0]);
        setEndTime(yesterday.toISOString().slice(0,10));
        setEndReal((new Date(item.endon)).toISOString().split('T')[0]);
        setTimeSet(true);
        const starter = new Date(item.starton).getTime();
        const countdownDate = new Date(item.endon).getTime();
        const now = new Date().getTime();
        setTime(now);
        setEndUtc(countdownDate);
        setStartUtc(starter);
        const distance = countdownDate - now;
        if (distance < 0 && item.gwayactive === true && item.winnerid === null) {
            const getWinner = await fetch(`${process.env.REACT_APP_API_URL}/b/random/${ match.params.gwayid }`);
            const winr = getWinner.json();
            setWinner(winr);
            return setExpired(true);
         } if (distance < 0 && item.gwayactive === false && item.winnerid !== null) {
            setWinner(item.winnerid);
            return setExpired(true);
         } if (distance > 0 && item.gwayactive === true && item.winnerid === null) {
            return setExpired(false);
         }
        } else {
        setStartTime((new Date(item.starton)).toISOString().split('T')[0]);
        setEndTime((new Date(item.endon)).toISOString().split('T')[0]);
        setEndReal((new Date(item.endon)).toISOString().split('T')[0]);
        setTimeSet(true);
        const starter = new Date(item.starton).getTime();
        const countdownDate = new Date(item.endon).getTime();
        const now = new Date().getTime();
        setTime(now);
        setEndUtc(countdownDate);
        setStartUtc(starter);
        const distance = countdownDate - now;
        if (distance < 0 && item.gwayactive === true && item.winnerid === null) {
            const getWinner = await fetch(`${process.env.REACT_APP_API_URL}/b/random/${ match.params.gwayid }`);
            const winr = getWinner.json();
            setWinner(winr);
            return setExpired(true);
         } if (distance < 0 && item.gwayactive === false && item.winnerid !== null) {
            setWinner(item.winnerid);
            return setExpired(true);
         } if (distance > 0 && item.gwayactive === true && item.winnerid === null) {
            return setExpired(false);
         }
        }
    };

     useEffect(() => {
        fetchGway();
     },[]);

     const fetchViews = async () => {
        const fetchViews = await fetch(`${process.env.REACT_APP_ANALYTICS_URL}/g/totalgwayviewsint/${startTime}/${endTime}/${ match.params.gwayid }`);
        const views = await fetchViews.json();
        setViews(views);
    };

    const fetchEntries = async () => {
        const fetchEnt = await fetch(`${process.env.REACT_APP_API_URL}/b/entgways/${ match.params.gwayid }`);
        const ents = await fetchEnt.json();
        if (ents === false) {
             return setLoading(false);
        } else
        setEntries(ents.length);
        return setLoading(false);
    };

    useEffect(() => {
        fetchEntries();
     },[]);

     useEffect(() => {
         if (timeSet === true) {
             return fetchViews();
         } else return
     }, [timeSet])

     const fetchViewsLogData = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_ANALYTICS_URL}/g/fullgwayviewsdates/${startTime}/${endTime}/${match.params.gwayid}`);
        const item = await fetchItem.json();
        setViewsLogData(item);
      }

      const fetchEntriesLogData = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_ANALYTICS_URL}/g/fullgwayentriesdates/${startTime}/${endTime}/${match.params.gwayid}`);
        const item = await fetchItem.json();
        setEntriesLogData(item);
      }
    
      useEffect(() => {
        if (timeSet === true && tier === 1){
       fetchViewsLogData();
       fetchEntriesLogData();
       setProcessing(false);
        } else return
      }, [timeSet])

     function isExpired() {
         return expired === true;
     }

     function isWinner() {
        return winner !== false;
     }

     function tierOne() {
        return tier === 1;
    }

    function isMyGway() {
        return sponuser.sponsorid === gway.sponsorid;
      }

    return (
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div>
            {isMyGway()? (
            <div className="focus-div">
            <div>
            {((expired === false) && (time > startUtc)) ? (<h3>Giveaway is live!</h3>) : ((expired === true) && (time > startUtc)) ? (<h3>Giveaway has ended!</h3>) : ((startUtc > time) && (expired === false)) ? (<h3>Giveaway is scheduled to begin {startTime}!</h3>) : ''}
            <img id="image" src={`${process.env.REACT_APP_CDN_URL}/${gway.img1}`} alt="" height="180" width="180" />
            <Link to={`/giveaways/${match.params.gwayid}`}><h3>Public View</h3></Link>
           <h1>{gway.itemname}</h1>
            <div className="focus-invisible-array">
                <div className="focus-stat-item"><h1>${gway.value}</h1><h3>Value</h3></div>
                <div className="focus-stat-item"><h1>{views.views}</h1><h3>Giveaway Views</h3></div>
                <div className="focus-stat-item"><h1>{entries}</h1><h3>Giveaway Entries</h3></div>
            </div>
            <div className="focus-invisible-array">
                <div className="focus-stat-item"><h1>{startTime}</h1><h3>Giveaway Start</h3></div>
                <div className="focus-stat-item"><h1>{endReal}</h1><h3>Giveaway End</h3></div>
            </div>
            {(isWinner()) ? (  <Link to={`/viewwinner/${match.params.gwayid}`}><h2>View Winner</h2></Link>) : ('')}
            <br />
            </div>
            {(startTime < today) ? (
            <div>
            {(tierOne() ? (
            <div className="glass-chart">
            
                { (processing) ? ( <SpinnerFetching /> ) :(
                <div>
                <GiveawayViewsChart 
                    gwayid={ match.params.gwayid }
                    name={ gway.itemname }
                    created={ startTime }
                    endon={ endTime }
                    viewsLogData={ viewsLogData }/>
                    <GiveawayEntriesChart 
                    gwayid={ match.params.gwayid }
                    name={ gway.itemname }
                    created={ startTime }
                    endon={ endTime }
                    entriesLogData={ entriesLogData }/>
                    </div>
                )}
            </div>
            ) : (''))}
            </div>) : ('')}
            </div>) : (<div><h2>Please login to view this!</h2></div>)}
            </div>)
}
            <br />
            <br />
            <br />
            <br />
        </div>
      );
}

export default GiveawayLanding;