import React, { useState, useEffect } from 'react';
import Spinner from './Spinner';

const VerifyLandingSpon = ({ match }) => {

    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(localStorage.getItem('x-api-key') || []);
    const [item, setItem] = useState('')

    useEffect(()=>{
        fetchInfo();
    }, [])

    const fetchInfo = async () => {
        const setVerified = await fetch(`${process.env.REACT_APP_API_URL}/b/verifysponemail/${match.params.token}`);
        const items = await setVerified.json();
        if (items === false) {
            setItem(false)
            setLoading(false);
        } else {
        setItem(true);
        setLoading(false);
        }
      };

      function isVerified () {
          return item === true;
      }

      return (
          <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
                <br />
                {isVerified() ? <div><h1>You have verified your Business account!</h1><h2>Please Login.</h2></div> : <div><h1>Failed to verify your Business account!</h1></div>}
                <br />
            </div>) }
          </div>
      )
}

export default VerifyLandingSpon;