import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Spinner from './Spinner';

const SponsorReturnHooks = (props) => {

    const [sponuser, setUser] = useState(JSON.parse(localStorage.getItem('sponuser')))
    const [api, setApi] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        fetchApi();
    }, [])

    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/sponreturn`);
        const api = await fetchApi.json();
        setApi(api);
        setLoading(false);
    };

    function tierOne() {
        return sponuser.spontier === 1;
    }

    return(
        <div>
            <br />
            <br />
            <br />
            {
            (loading) ? ( <Spinner /> ) : (
            <div className="glass-account-edit">
            <h1>{api.return}, {sponuser.name}!</h1>
            <div className="glass-chart">
                        <h3>Manage Giveaways</h3>
                        <p><Link to='/livegiveaways'>Live Giveaways</Link></p>
                        { tierOne() ? (<p><Link to='/scheduledgiveaways'>Scheduled Giveaways</Link></p>) : '' }
                        <p><Link to='/expiredgiveaways'>Expired Giveaways</Link></p>
                        <p><Link to='/allgiveaways'>All Giveaways</Link></p>
                        <p><Link to='/creategiveaway'>Create Giveaway</Link></p>
                        <h3>Manage Products</h3>
                        <p><Link to='/myshop'>All Products</Link></p>
                        <p><Link to='/createproduct'>Create Product</Link></p>
                        <h3>Other</h3>
                        <p><Link to='/accountsettings'>Account Settings</Link></p>
            </div>            
            <br />
            </div>)
            }
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default SponsorReturnHooks;