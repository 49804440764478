import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Spinner from './Spinner';

const VerifyAgainSpon = (props) => {

    const [loading, setLoading] = useState(true);
    const [token] = useState((localStorage.getItem('x-api-key')))
    const [sponuser] = useState(JSON.parse(localStorage.getItem('sponuser')))
    const [pressed, setPressed] = useState(false);
    const [info, setInfo] = useState({});

    useEffect(() => {
        fetchInfo();
    }, [])

    const fetchInfo = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/content/sponverify`);
        const item = await fetchItem.json();
        setInfo(item);
        setLoading(false);
    }

    async function verifyE () {
        setPressed(true)
        return axios.post(`${process.env.REACT_APP_API_URL}/b/sendsponemail`, { email: sponuser.email, token: token })
        .then(response => {
        return response.status
        })
        .catch(err => Promise.reject('Failed to send verification email'));
        }

    async function sbmt(event){
        event.preventDefault();
        verifyE()
        .catch(err => alert(err));
        };

    return (
        <div>
            <br />
            <br />
            <br />
            {
            (loading) ? ( <Spinner /> ) : (
                <div className="focus-div">
                <div>
            <h1>{info.title} to Givmap!</h1>
            { (!pressed) ? (
            <div className="focus-login">
            <br />
            <h2>Please verify your Business account!</h2>
            <br />
            <button className="february-form-submit-button" onClick={sbmt}> Verify Email </button>
            <br />
            </div>) : (<div className="focus-login">
                <br />
                <h2>Email sent to {sponuser.email}!</h2>
                <br />
                <br />
            </div>) }
            </div>
            <br />
            <br />
            </div>) }
            <br />
            <br />
            <br />
        </div>
    )
}

export default VerifyAgainSpon;