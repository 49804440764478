import React, { useEffect, useState } from 'react';
import Spinner from './Spinner';
import {Link} from 'react-router-dom';

const AdminReports = (props) => {

  const [api, setApi] = useState([]);
  const [loading, setLoading] = useState(true);

  const [items, setItems] = useState([]);
  const [data, setData] = useState(items);
  
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(60);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const today = new Date().toISOString().slice(0, 10);

    useEffect(()=>{
        fetchApi();
        fetchReports();
    }, [])

    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/adminreports`);
        const api =  await fetchApi.json();
        setApi(api);
    };

    const fetchReports = async () => {
        const fetchGiveaways = await fetch(`${process.env.REACT_APP_API_URL}/b/unreviewedreportscombo`, { headers: { 'x-admin-key': localStorage.getItem('x-admin-key')} });
        const items = await fetchGiveaways.json();
        setItems(items);
        setData(items);
        setLoading(false);
      };

  const logOut = () => {
    localStorage.removeItem('x-admin-key');
    localStorage.removeItem('x-admin-key-expiration');
  };

  const renderData = (reports) => {
    return (
        <div className="glass-container-array">
        {reports.map(report => (
            <div key={report.apigwayid} className="glass-container-gway">
                              {report.origintable === 'apireports' ? <h3 className="title"><Link target="_blank" to={`/adminreport/${report.reportid}`}>{report.itemname}</Link></h3> : <h3 className="title"><Link target="_blank" to={`/instantreport/${report.reportid}`}>{report.itemname}</Link></h3> }
                                <p>by <Link target="_blank" to={`/business/${report.sponsorid}`}>{report.name}</Link></p>
                                <p><small>category: {report.reportcategory}</small></p>
                                <p><small>created: {new Date(report.created_at).toDateString()}</small></p>
                         </div>
    
        ))}
      </div>
    );
  };

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  
  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  function isArrThere() {
    return data.length > 0;
  }

  function isArrBig() {
    return data.length > 60;
  }

return (
    <div>
      <br />
      <br />
      <br />
      {
        (loading) ? ( <Spinner /> ) : (
      <div className="glass-account-edit">
        <h2 onClick={logOut}><a href="/">Log Out</a></h2>
      <h1>{api.title}</h1>
      <br />
      {isArrThere() ? (
      <div className="glass-chart">
     <h2>Unreviewed Reports ({data.length}):</h2>
     <div>
     {renderData(currentItems)}
     </div>
     {isArrBig() ? (
      <div className="pageNumbersDiv">
      <ul className="pageNumbers">
        <li>
          <button onClick={handlePrevbtn} disabled={currentPage == pages[0] ? true : false}>
            Prev
          </button>
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}
        <li>
          <button onClick={handleNextbtn} disabled={currentPage == pages[pages.length - 1] ? true : false}>
            Next
          </button>
        </li>
      </ul>
      </div>) : ('')}
      </div>) : (<h2>Nothing to see here!</h2>) }
      <br />
      <h3><Link to={`/admindash`}>Back to Admin Dash</Link></h3>
      </div>)
    }
      <br />
            <br />
            <br />
            <br />
    </div>
)

}

export default AdminReports;