import React from "react";
import { AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Area, ResponsiveContainer} from "recharts";

const GiveawayViewsChart = (props) => {  
    if (props.viewsLogData.length === 0) {
        return (
            <div></div>
        )
    } else {
      return (
          <div>
            <div className="glass-chart-section">
             <h3>Views of {props.name} Giveaway</h3>
             <ResponsiveContainer width="90%" height={250}>
                <AreaChart
                    width={730}
                    height={250}
                    data={props.viewsLogData}
                    margin={{ top: 5, right: 100, left: 100, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Area type="monotone" dataKey="views" stroke="#8884d8" />
                </AreaChart>
                </ResponsiveContainer>
            </div>
          </div>
      )
    }
  }
  
  export default GiveawayViewsChart;