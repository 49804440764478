import React, { useState, useEffect } from 'react';
import Spinner from './Spinner';

const Privacy = (props) => {

    const [api, setApi] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        fetchApi();
    }, [])

    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/privacy`);
        const api = await fetchApi.json();
        setApi(api);
        setLoading(false);
    };

    return (
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
                <div>
                <h1>{api.privacy}</h1>
                </div>
                <div className="focus-text">
                <p>The Givmap platform collects biographical information about an individual’s name, email address, shipping address, and date of birth. This information is necessary to ensure that any individual who wins a prize can be shipped and received their prize. Givmap may provide Third Party Business Users of the platform an individual’s biographical information so that they may send you a prize in the event that an individual has won a promotion/contest/giveaway/sweepstake. The Givmap platform collects information about which Third Party Business Users an individual follows, which items an individual has favorited, and which promotions/contests/giveaways/sweepstakes an individual has entered. Only a Third Party Business User who has been followed by an individual, whose items have been favorited by an individual, or whose giveaways have been entered into by an individual are provided this information. The Givmap platform also collects anonymized data regarding analytics. This anonymized data is provided to Third Party Business Users so they can see how many times their profile pages, items, and giveaways have been viewed.
                </p>
                </div>
                <br />
            </div>)
            }
        </div>
    )
}

export default Privacy;