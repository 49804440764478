import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from "recharts";

const GiveawayEntriesChart = (props) => {  
  if (props.entriesLogData.length === 0) {
      return (
          <div></div>
      )
  } else {
      return (
          <div>
            <div className="glass-chart-section">
             <h3>Entries in {props.name} Giveaway</h3>
             <ResponsiveContainer width="90%" height={250}>
                <BarChart
                    width={730}
                    height={250}
                    data={props.entriesLogData}
                    margin={{ top: 5, right: 100, left: 100, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar type="monotone" dataKey="entries" fill="#2BA880" />
                </BarChart>
                </ResponsiveContainer>
            </div>
          </div>
      )
  }
  }
  
  export default GiveawayEntriesChart;