import React from "react";
import SpinnerFetching from './SpinnerFetching';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

const SponModeChart = (props) => {

    function isProcessing() {
        return props.loading === true;
    }

    return (
        <div>
           {
    isProcessing() ? ( <SpinnerFetching /> ) :(
                 <div className="glass-chart-section">
                     <ResponsiveContainer width="90%" height={250}>
        <BarChart
          data={props.modeLogs}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="site" />
          <YAxis />
          <Tooltip />
          <Bar type="monotone" dataKey="clicks" fill="#Fe5c00" />
        </BarChart>
        </ResponsiveContainer>
      </div>
      )}
        </div>
    )
}

export default SponModeChart;