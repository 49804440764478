import React, { useMemo, useState, useEffect } from "react";
import { useTable, useFilters, useSortBy } from "react-table";

function Table({ columns, data }) {

  const [filterInput, setFilterInput] = useState("");
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data
    },
    useFilters,
    useSortBy
  );

  // Render the UI for your table
  return (
    <>
    <br />
    <br />
    <br />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                     {cell.value === "true" ? '✔️' : cell.value === "false" ? '❌' : cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

const Compare = (props) => {

  const data = [{
    "feature": "Upload Business Products/Offerings",
    "advanced" : "true",
    "basic": "true"
  },
  {
      "feature": "Create Custom Giveaways",
      "advanced" : "true",
      "basic": "true"
  },
  {
    "feature": "Schedule Future Giveaways",
    "advanced" : "true",
    "basic": "false"
},
{
  "feature": "Business Analytics Dashboard",
  "advanced" : "true",
  "basic": "true"
},
{
    "feature": "Breakdown of Cost-Per-Click Ratio Past 30 Days",
    "advanced" : "true",
    "basic": "true"
},
{
    "feature": "Chart of Total Views Past 30 Days (Free)",
    "advanced" : "false",
    "basic": "true"
},
{
    "feature": "Chart of Total Views Past 30 Days (Premium)",
    "advanced" : "true",
    "basic": "false"
},
{
    "feature": "Chart of Giveaway Entries Past 30 Days",
    "advanced" : "true",
    "basic": "false"
},
{
    "feature": "Chart of Product Favorites Past 30 Days",
    "advanced" : "true",
    "basic": "false"
},
{
    "feature": "Chart of New Followers Past 30 Days",
    "advanced" : "true",
    "basic": "false"
},
{
    "feature": "View Total Follower Count",
    "advanced" : "true",
    "basic": "true"
},
{
  "feature": "Chart of Top Referrer Websites Past 30 Days",
  "advanced" : "true",
  "basic": "false"
},
{
    "feature": "Individual Basic Giveaway Analytics",
    "advanced" : "true",
    "basic": "true"
},
{
    "feature": "Individual Giveaway Views Dashboard",
    "advanced" : "true",
    "basic": "false"
},
{
    "feature": "Individual Giveaway Entries Dashboard",
    "advanced" : "true",
    "basic": "false"
},
{
    "feature": "Business Profile Appears in Search",
    "advanced" : "true",
    "basic": "true"
},
{
    "feature": "Giveaways Appear in Search",
    "advanced" : "true",
    "basic": "true"
},
{
    "feature": "Business Profile May Appear in 'Featured'",
    "advanced" : "true",
    "basic": "false"
},
{
    "feature": "Giveaways May Appear in 'Featured'",
    "advanced" : "true",
    "basic": "false"
}]



  const columns = useMemo(
    () => [
      {
        Header: "Compare Free & Premium Business Accounts",
        columns: [
          {
            Header: "Givmap Service",
            accessor: "feature"
          },
          {
            Header: "Premium Account",
            accessor: "advanced"
          },
          {
            Header: "Free Account",
            accessor: "basic"
          }]
      }
    ],
    []
  );

  return (
    <div className="App-tab">
        <br />
        <br />
        <br />
        <br />
      <Table columns={columns} data={data} />
    </div>
  );
}

export default Compare;