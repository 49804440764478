import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import InstantViewsChart from './GiveawayViewsChart';
import InstantEntriesChart from './GiveawayEntriesChart';
import SpinnerFetching from './SpinnerFetching';

const InstantLanding = ({ match }) => {
  
    const [processing, setProcessing] = useState(true);
    const [loading, setLoading] = useState(true);
    const [gway, setGway] = useState({});
    const [views, setViews] = useState([]);
    const [entries, setEntries] = useState(0);
    const [sponuser, setSponuser] = useState(() => JSON.parse(localStorage.getItem("sponuser")) || "");
    const [expired, setExpired] = useState(false);
    const [tier, setTier] = useState(sponuser.spontier);
    const [startTime, setStartTime] = useState([]);
    const [endChart, setEndChart] = useState([]);
    const [viewsLogData, setViewsLogData] = useState([]);
    const [entriesLogData, setEntriesLogData] = useState([]);
    const [timeSet, setTimeSet] = useState(false);
    const [time, setTime] = useState([]);
    const [startUtc, setStartUtc] = useState([]);
    const [isSet, setIsSet] = useState(false);
    const [hasLogs, setHasLogs] = useState(false);
    const [winners, setWinners] = useState([]);
    const [activeStatus, setActiveStatus] = useState([]);

    const today = new Date().toISOString().slice(0, 10);
    var tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1);
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    // if ended yesterday or before, load (startdate through today). If live or ended today, load (startdate to yesterday).  If not yet started, load nothing.
    // updated_at column functional on InstantWins when Instant Win Ends. Could calculate that as endChart

    const fetchGway = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/instantview/${ match.params.gwayid }`);
        const item = await fetchItem.json();
        setIsSet(true);
        setGway(item);
        setActiveStatus(item.instantwinactive)
        if (new Date(item.updated_at).getTime() <= yesterday.getTime() && item.instantwinactive === false) {
            setStartTime((new Date(item.instantwinstarton)).toISOString().split('T')[0]);
            setEndChart((new Date(item.updated_at)).toISOString().split('T')[0]);
            setHasLogs(true);
            setTimeSet(true);
        } else {
            setStartTime((new Date(item.instantwinstarton)).toISOString().split('T')[0]);
            setEndChart(yesterday.toISOString().slice(0,10));
            setTimeSet(true);
            if (new Date(today).getTime() <= new Date(item.instantwinstarton).getTime()) {
                setHasLogs(false);
            } else {
                setHasLogs(true);
            }
        }
    };

    useEffect(() => {
        if (gway.instantwinactive === true && isSet === true) {
            return setExpired(false);
        } if (gway.instantwinactive === false && isSet === true) {
            return setExpired(true);
        } else return
    }, [gway])

    /**
    const fetchGway = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/instantview/${ match.params.gwayid }`);
        const item = await fetchItem.json();
        setGway(item);
        if (item.instantwinactive === false) {
            setStartTime((new Date(item.instantwinstarton)).toISOString().split('T')[0]);
            setEndChart(today);
        } if (item.instantwinactive === true) {
            setStartTime((new Date(item.instantwinstarton)).toISOString().split('T')[0]);
            setEndChart(today);
        }
        if (item.instantwinactive === true) {
            return setExpired(false);
        } if (item.instantwinactive === false) {
            return setExpired(true);
        }
    };
*/
     useEffect(() => {
        fetchGway();
     },[]);

     const fetchViews = async () => {
        const fetchViews = await fetch(`${process.env.REACT_APP_ANALYTICS_URL}/g/totalinstantviewsint/${startTime}/${endChart}/${ match.params.gwayid }`);
        const views = await fetchViews.json();
        setViews(views);
    };

    const fetchEntries = async () => {
        const fetchEnt = await fetch(`${process.env.REACT_APP_API_URL}/b/entinstantwin/${ match.params.gwayid }`);
        const ents = await fetchEnt.json();
        if (ents === false) {
             return setLoading(false);
        } else
        setEntries(ents.length);
        return setLoading(false);
    };

    const fetchWins = async () => {
        const fetchw = await fetch(`${process.env.REACT_APP_API_URL}/b/entwinner/${ match.params.gwayid }`);
        const wins = await fetchw.json();
        setWinners(wins);
    };

    useEffect(() => {
        fetchEntries();
     },[]);

     useEffect(() => {
         if (timeSet === true) {
            fetchWins();
             return fetchViews();
         } else return
     }, [timeSet])

     const fetchViewsLogData = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_ANALYTICS_URL}/g/fullinstantviewsdates/${startTime}/${endChart}/${match.params.gwayid}`);
        const item = await fetchItem.json();
        setViewsLogData(item);
      }

      const fetchEntriesLogData = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_ANALYTICS_URL}/g/fullinstantentriesdates/${startTime}/${endChart}/${match.params.gwayid}`);
        const item = await fetchItem.json();
        setEntriesLogData(item);
      }
    
      useEffect(() => {
        if (timeSet === true && tier === 1){
       fetchViewsLogData();
       fetchEntriesLogData();
       setProcessing(false);
        } else return
      }, [timeSet])

     function isExpired() {
         return expired === true;
     }

     function ended() {
         return activeStatus === false;
     }

     function tierOne() {
        return tier === 1;
    }

    function isMyGway() {
        return sponuser.sponsorid === gway.sponsorid;
      }

    function hasWinners() {
        return winners.length > 0;
    }

    const odds = (gway.numberwinners/gway.numberentrants) * 100;

    return (
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div>
            {isMyGway()? (
            <div className="focus-div">
            <div>
            {ended() ? (<h3>Giveaway has ended!</h3>) : ((activeStatus === true) && (hasLogs === true)) ? (<h3>Giveaway is live!</h3>) : ((activeStatus === true) && (hasLogs === false)) ? (<h3>Giveaway is scheduled to begin {startTime}!</h3>) : ''}
            <img id="image" src={`${process.env.REACT_APP_CDN_URL}/${gway.img1}`} alt="" height="180" width="180" />
            <Link to={`/instant/${match.params.gwayid}`}><h3>Public View</h3></Link>
            <h1>{gway.itemname}</h1>
            <div className="focus-invisible-array">
                <div className="focus-stat-item"><h1>${gway.value}</h1><h3>Value</h3></div>
                <div className="focus-stat-item"><h1>{gway.numberentrants}</h1><h3>Target Number of Entries</h3></div>
                <div className="focus-stat-item"><h1>{gway.numberwinners}</h1><h3>Total Number of Winners</h3></div>
                <div className="focus-stat-item"><h1>{gway.numberwinners} in {gway.numberentrants}</h1><p>({odds.toLocaleString("en-US")}% per entry)</p><h3>Odds of winning</h3></div>
            </div>
            {(hasLogs === true) ? (
             <div className="focus-invisible-array">
             <div className="focus-stat-item"><h1>{views.views}</h1><h3>Giveaway Views</h3></div>
             <div className="focus-stat-item"><h1>{entries}</h1><h3>Giveaway Entries</h3></div>
             <div className="focus-stat-item"><h1>{startTime}</h1><h3>Giveaway Start</h3></div>
             {ended() ? (<div className="focus-stat-item"><h1>{(new Date(gway.updated_at)).toISOString().split('T')[0]}</h1><h3>Giveaway End</h3></div>) : ('')}
            </div>
            ) : (<h2>Analytics will become available soon!</h2>)}
            {(hasWinners()) ? (<Link to={`/viewwinnerinstant/${match.params.gwayid}`}><h2>View Winner(s)</h2></Link>) : ('')}
            <br />
            </div>
            {(hasLogs === true) ? (
            <div>
            {(tierOne() ? (
            <div className="glass-chart">
            
                   {
    (processing) ? ( <SpinnerFetching /> ) :(
                <div>
                <InstantViewsChart 
                    gwayid={ match.params.gwayid }
                    name={ gway.itemname }
                    created={ startTime }
                    endon={ endChart }
                    viewsLogData={ viewsLogData }/>
                    <InstantEntriesChart 
                    gwayid={ match.params.gwayid }
                    name={ gway.itemname }
                    created={ startTime }
                    endon={ endChart }
                    entriesLogData={ entriesLogData }/>
                    </div>
                )}
            </div>
            ) : (''))}
            </div>) : ('')}
            </div>) : (<div><h2>Please login to view this!</h2></div>)}
            </div>)
}
            <br />
            <br />
            <br />
            <br />
        </div>
      );
}

export default InstantLanding;