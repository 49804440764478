import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from './Spinner';
import { Link } from 'react-router-dom';

const SponUpload = (props) => {

  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('Choose File');
  const [uploadedFile, setUploadedFile] = useState({});
  const [api, setApi] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasUploaded, setHasUploaded] = useState(false);

    useEffect(()=>{
        fetchApi();
    }, [])

    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/sponupload`);
        const api = await fetchApi.json();
        setApi(api);
        setLoading(false);
    };

  const onChange = e => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const onSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    try {
      const res = await axios.post(`${process.env.REACT_APP_CDN_URL}/i/sponupload`, formData, {
      //const res = await axios.post(`${process.env.REACT_APP_CDN_URL}/b/sponupload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      const { fileName, filePath } = res.data;
      setUploadedFile({ fileName, filePath });
      setHasUploaded(true);
    } catch (err) {
      alert('Image size too large!')
    }
  };

  async function addSponProfileImg (data) {
    return axios.post(`${process.env.REACT_APP_API_URL}/b/sponimg`, { apisponid: sponuser.sponsorid, image: uploadedFile.fileName }, {headers: { 'x-api-key':  localStorage.getItem('x-api-key') } } )
    .then(response => {
    return response.status
    })
    .catch(err => Promise.reject('Profile image could not be added!'));
    }

function submitAddImg(event){
  if (hasUploaded === true) {
event.preventDefault();
addSponProfileImg()
.then(token => window.location = '/businessreturn')
.catch(err => alert(err));
  } else {
    return alert('Please Select an Image to Upload!')
  }
};

// returns name of file -> need to put into form and submit link into db as pic
const sponuser = JSON.parse(localStorage.getItem('sponuser'));
  return (
    <Fragment>
      <br />
      <br />
      <br />
      {
        (loading) ? ( <Spinner /> ) : (
      <div className="focus-div">
      <div>
      <h1>{api.upload} {sponuser.name}!</h1>
      </div>
      <div>
      <h3>Please add an image so your Business will appear on Givmap!</h3>
      </div>
      <div>
      <form onSubmit={onSubmit}>
        <div>
          <input
            type='file'
            className='custom-file-input'
            id='customFile'
            onChange={onChange}
          />
       
        </div>
        <button type="submit" className="february-form-submit-button"> Upload </button>
      </form>
      {uploadedFile ? (
        <div>
          <div>
            <br />
            <img src={`${process.env.REACT_APP_CDN_URL}/${uploadedFile.fileName}`} alt='' height="200" width="200" />
          </div>
          <br />
          <div>
          <button onClick={submitAddImg} className="january-form-submit-button"> Set Image </button>
        </div>
        </div>) : null}
        </div>
        <br />
        <h3><Link to={`/accountsettings`}>Account Settings</Link></h3>
        <br />
        </div>)
}
        <br />
            <br />
            <br />
            <br />
    </Fragment>
  );
}

export default SponUpload;