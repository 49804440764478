import React from 'react';

const SpinnerEntry = (props) => {
    return (
        <div>
             <div className="loading-process">
        </div>
        <div className="loading-spinner-entry">
            
        </div>
        </div>
    )
}

export default SpinnerEntry;