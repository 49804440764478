import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from './Spinner';

const ComposeMessage = ({ match }) => {

const [header, setHeader] = useState({});    
const [loading, setLoading] = useState(true);
const [gway, setGway] = useState([]);
const initialInputState = { message: '' };
const [eachEntry, setEachEntry] = useState(initialInputState);
const {message} = eachEntry;

const fetchContext = async () => {
    const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/composegway/${ match.params.gwayid }`);
    const item = await fetchItem.json();
    setGway(item);
    setLoading(false);
};

useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

useEffect(() => {
   fetchContext();
  }, [])

  const messageCreate = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/b/mail`, { apisponsorid: gway.sponsorid, apientrantid: gway.winnerid, senderid: gway.winnerid, recipientid: gway.sponsorid, message: data.message }, {headers: { 'x-access-token':  localStorage.getItem('x-access-token') } } )
    .then(response => {
    return response.status
    })
    .catch(err => Promise.reject('Message could not be sent!'));
    }  

const handleInputChange = e => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  const handleFinalSubmit = e => {
      e.preventDefault();
      messageCreate(eachEntry)
      .then(token => window.location = '/return')
      .catch(err => alert(err));
  };

return (
    <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="glass-account-edit">
                    <h1>Message {gway.name}:</h1>
                    <div className="glass-giveaway-details">
                        <h2>Compose Message:</h2>
                            <div className="container">
                            <form onSubmit={handleFinalSubmit}>
                            <div className="form-group">
                            <label>Message Body: </label>
                            <input type="text" className="form-control" name="message"
                            onChange={handleInputChange}/>
                            </div>
                            <button type="submit" className="btn btn-default">Send Message</button>
                            </form>
                            <br />
                            </div>
                </div>
                <br />
      <br />
      </div>)
}
            <br />
            <br />
            <br />
            <br />
    </div>
)

}

export default ComposeMessage;