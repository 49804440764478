import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import Spinner from './Spinner';
import Modal from './Modal';

const CancelSubscription = (props) => {

    const [loading, setLoading] = useState(true);
    const [sponuser] = useState(JSON.parse(localStorage.getItem('sponuser')) || []);
    const [info, setInfo] = useState([]);
    const [sub, setSub] = useState([]);
    const [cancelled, setCancelled] = useState([]);
    const [periodEnd, setPeriodEnd] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
            axios.get(`${process.env.REACT_APP_API_URL}/b/fetchcustomersub/${sponuser.stripeid}`)
            .then(res => {
                setSub(res.data.data[0]);
                setCancelled(res.data.data[0].cancel_at_period_end);
                setPeriodEnd((res.data.data[0].current_period_end)*1000);
                setLoading(false);
            }).catch(err => Promise.reject('Request failed!'));
    }, [])

    async function cancelSub (data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/b/cancelatperiodend`, { subscriptionId: sub.id }, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} } )
        .then(response => {
        return response.status
        })
        .catch(err => Promise.reject('Could not cancel subscription!'));
        }

        async function setTierZero (data) {
            const url = `${process.env.REACT_APP_API_URL}/b/tierzero/${sponuser.sponsorid}`;
            const head = { 'x-api-key': localStorage.getItem('x-api-key')};
            return axios.patch(url, head)
            .then(response => {
            return response.status
            })
            .catch(err => Promise.reject('Could not cancel subscription!'));
            }

        function submitCreate(event){
            event.preventDefault();
            cancelSub()
            .then(token => window.location = '/businessreturn')
            .catch(err => alert(err));
            };

            useEffect(() => {
                fetchInfo();
            }, [])
            
            const fetchInfo = async () => {
                const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/content/cancel`);
                const info = await fetchInfo.json();
                setInfo(info);
            };

            function isCancelled() {
                return cancelled === true;
            }

            async function unCancelSub (data) {
                return axios.post(`${process.env.REACT_APP_API_URL}/b/uncancelatperiodend`, { subscriptionId: sub.id }, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} } )
                .then(response => {
                return response.status
                })
                .catch(err => Promise.reject('Could not cancel subscription!'));
                }

            function submitUncancel(event){
                event.preventDefault();
                unCancelSub()
                .then(token => window.location = '/businessreturn')
                .catch(err => alert(err));
                };
    
                useEffect(() => {
                    fetchInfo();
                }, [])
                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                const end = new Date(periodEnd).toLocaleDateString(undefined, options);
                const endTime = new Date(periodEnd).toLocaleTimeString();

                const toggleModal = async (e) => {
                    e.preventDefault();
                    setModalOpen(!modalOpen);
                  }            

return (
<div>
    <br />
    <br />
    <br />
    {
        (loading) ? ( <Spinner /> ) : (
    <div>
    <div className="focus-div">
    <h1>{info.title}</h1>
        {isCancelled() ? (
        <div className="focus-login">
        <h2>Would you like to un-cancel your subscription?</h2>      
        <h3>Your subscription is set to expire {end} at {endTime}.</h3>
        <br />
        <button onClick={toggleModal}> Keep Subscription </button>  
        <br />
        </div>) :
        (
        <div className="focus-login">
        <h3>You are currently being billed monthly.</h3>   
        <h3>Your card will be charged $29.99 on {end}.</h3>
        <br />
        <button onClick={toggleModal}> Cancel Subscription </button>  
        <br />
        </div> )}
        <h3><Link to={`/accountsettings`}>Account Settings</Link></h3>
        <br />
        </div>
        {modalOpen && <Modal
                    content={<>
                    {isCancelled() ? (<div>
                        <h2>Un-Cancel Your Subscription?</h2>
                        <p>Would you like to un-cancel your subscription?</p>
                        <button onClick={submitUncancel}> Keep Subscription </button>
                    </div>) : (<div>
                        <h2>Cancel Your Subscription?</h2>
                        <p>Cancelling your subscription will set your user tier to "free" - you will still be a Givmap user.</p>
                        <button onClick={submitCreate}> Cancel Subscription </button>
                    </div>)}
                    <br />
                    </>}
                    handleClose={toggleModal}
                    />}
        </div>)
}
        <br />
            <br />
            <br />
            <br />
</div>
)

}

export default CancelSubscription;