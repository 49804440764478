import React from "react";
import { AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Area, ResponsiveContainer} from "recharts";

const ExampleChart = (props) => {

  var yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  var dayBeforeYes = new Date();
  dayBeforeYes.setDate(dayBeforeYes.getDate() - 2);
  var threeDaysAgo = new Date();
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
  var fourDaysAgo = new Date();
  fourDaysAgo.setDate(fourDaysAgo.getDate() - 4);
  var fiveDaysAgo = new Date();
  fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);
  var sixDaysAgo = new Date();
  sixDaysAgo.setDate(sixDaysAgo.getDate() - 6);
  var sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  var eightDaysAgo = new Date();
  eightDaysAgo.setDate(eightDaysAgo.getDate() - 8);
  var nineDaysAgo = new Date();
  nineDaysAgo.setDate(nineDaysAgo.getDate() - 9);
  var tenDaysAgo = new Date();
  tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
  var elevenDaysAgo = new Date();
  elevenDaysAgo.setDate(elevenDaysAgo.getDate() - 11);
  var twelveDaysAgo = new Date();
  twelveDaysAgo.setDate(twelveDaysAgo.getDate() - 12);
  var thirteenDaysAgo = new Date();
  thirteenDaysAgo.setDate(thirteenDaysAgo.getDate() - 13);
  var fourteenDaysAgo = new Date();
  fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);
  var fifteenDaysAgo = new Date();
  fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);
  var sixteenDaysAgo = new Date();
  sixteenDaysAgo.setDate(sixteenDaysAgo.getDate() - 16);
  var seventeenDaysAgo = new Date();
  seventeenDaysAgo.setDate(seventeenDaysAgo.getDate() - 17);
  var eighteenDaysAgo = new Date();
  eighteenDaysAgo.setDate(eighteenDaysAgo.getDate() - 18);
  var nineteenDaysAgo = new Date();
  nineteenDaysAgo.setDate(nineteenDaysAgo.getDate() - 19);
  var twentyDaysAgo = new Date();
  twentyDaysAgo.setDate(twentyDaysAgo.getDate() - 20);
  var twentyOneDaysAgo = new Date();
  twentyOneDaysAgo.setDate(twentyOneDaysAgo.getDate() - 21);
  var twentyTwoDaysAgo = new Date();
  twentyTwoDaysAgo.setDate(twentyTwoDaysAgo.getDate() - 22);
  var twentyThreeDaysAgo = new Date();
  twentyThreeDaysAgo.setDate(twentyThreeDaysAgo.getDate() - 23);
  var twentyFourDaysAgo = new Date();
  twentyFourDaysAgo.setDate(twentyFourDaysAgo.getDate() - 24);
  var twentyFiveDaysAgo = new Date();
  twentyFiveDaysAgo.setDate(twentyFiveDaysAgo.getDate() - 25);
  var twentySixDaysAgo = new Date();
  twentySixDaysAgo.setDate(twentySixDaysAgo.getDate() - 26);
  var twentySevenDaysAgo = new Date();
  twentySevenDaysAgo.setDate(twentySevenDaysAgo.getDate() - 27);
  var twentyEightDaysAgo = new Date();
  twentyEightDaysAgo.setDate(twentyEightDaysAgo.getDate() - 28);
  var twentyNineDaysAgo = new Date();
  twentyNineDaysAgo.setDate(twentyNineDaysAgo.getDate() - 29);
  var thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const logData = [{
    name: thirtyDaysAgo.toISOString().slice(0, 10),
    views: 114
  },{
    name: twentyNineDaysAgo.toISOString().slice(0, 10),
    views: 162
  },{
    name: twentyEightDaysAgo.toISOString().slice(0, 10),
    views: 170
  },{
    name: twentySevenDaysAgo.toISOString().slice(0, 10),
    views: 184
  },{
    name: twentySixDaysAgo.toISOString().slice(0, 10),
    views: 322
  },{
    name: twentyFiveDaysAgo.toISOString().slice(0, 10),
    views: 189
  },{
    name: twentyFourDaysAgo.toISOString().slice(0, 10),
    views: 167
  },{
    name: twentyThreeDaysAgo.toISOString().slice(0, 10),
    views: 297
  },{
    name: twentyTwoDaysAgo.toISOString().slice(0, 10),
    views: 340
  },{
    name: twentyOneDaysAgo.toISOString().slice(0, 10),
    views: 270
  },{
    name: twentyDaysAgo.toISOString().slice(0, 10),
    views: 258
  },{
    name: nineteenDaysAgo.toISOString().slice(0, 10),
    views: 399
  },{
    name: eighteenDaysAgo.toISOString().slice(0, 10),
    views: 236
  },{
    name: seventeenDaysAgo.toISOString().slice(0, 10),
    views: 307
  },{
    name: sixteenDaysAgo.toISOString().slice(0, 10),
    views: 339
  },{
    name: fifteenDaysAgo.toISOString().slice(0, 10),
    views: 491
  },{
    name: fourteenDaysAgo.toISOString().slice(0, 10),
    views: 319
  },{
    name: thirteenDaysAgo.toISOString().slice(0, 10),
    views: 246
  },{
    name: twelveDaysAgo.toISOString().slice(0, 10),
    views: 603
  },{
    name: elevenDaysAgo.toISOString().slice(0, 10),
    views: 579
  },{
    name: tenDaysAgo.toISOString().slice(0, 10),
    views: 351
  },{
    name: nineDaysAgo.toISOString().slice(0, 10),
    views: 440
  },{
    name: eightDaysAgo.toISOString().slice(0, 10),
    views: 649
  },{
    name: sevenDaysAgo.toISOString().slice(0, 10),
    views: 592
  },{
    name: sixDaysAgo.toISOString().slice(0, 10),
    views: 783
  },{
    name: fiveDaysAgo.toISOString().slice(0, 10),
    views: 752
  },{
    name: fourDaysAgo.toISOString().slice(0, 10),
    views: 884
  },{
    name: threeDaysAgo.toISOString().slice(0, 10),
    views: 1202
  },{
    name: dayBeforeYes.toISOString().slice(0, 10),
    views: 996
  },{
    name: yesterday.toISOString().slice(0, 10),
    views: 1151
  }];

    return (
        <div>
                 <div className="glass-chart-section">
           <h3>Total Views Past 30 Days Chart (Free)</h3>
           <ResponsiveContainer width="90%" height={250}>
        <AreaChart
          data={logData}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="views" stroke="#8884d8" />
        </AreaChart>
        </ResponsiveContainer>
      </div>
        </div>
    )
}

export default ExampleChart;