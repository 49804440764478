import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from './Spinner';
import UpgradeHome from './UpgradeHome';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51IT9KpHi2nxFr9fEKEPNszes8y5e39y6lExVKnGRYI0UIgUG6xgwS3Dv6orwN2jBGbOtl7aViSYxyxP9mEfqvcjB00wQY6OtAN');

const SetTier = (props) => {

const [sponuser, setUser] = useState(JSON.parse(localStorage.getItem('sponuser')) || [])
const [item, setItem] = useState([]);
const [info, setInfo] = useState([]);
const [loading, setLoading] = useState(true);
const [currentTier, setCurrentTier] = useState(sponuser.spontier);
const [sponID, setSponID] = useState(sponuser.sponsorid);
const [addressLine, setAddressLine] = useState([]);
const [addressCity, setAddressCity] = useState([]);
const [addressState, setAddressState] = useState([]);
const [addressCountry, setAddressCountry] = useState([]);

useEffect(()=>{
    fetchInfo();
}, [])

const fetchInfo = async () => {
    const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/content/selecttier`);
    const info = await fetchInfo.json();
    setInfo(info);
    setLoading(false);
};

  useEffect(() => {
   axios.get(`${process.env.REACT_APP_API_URL}/b/spondetails/${sponuser.sponsorid}`)
   .then(response => {
       setAddressLine(response.data.street)
       setAddressCity(response.data.city)
       setAddressState(response.data.state)
       setAddressCountry(response.data.country)
     })
  .catch(err => Promise.reject('Request Failed!'));
}, [])

    useEffect(() => {
        let int = setInterval(() => {
            setItem(localStorage.setItem("sponuser", JSON.stringify(sponuser)));
            clearInterval(int);
        }, 1000)  
        return () => {
            clearInterval(int);
        }
    }, [fetchInfo]);

    function isTierOne() {
      return currentTier === 1;
  }

return (
    <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
    <div className="focus-div">
       <h1>{info.set}</h1>
      {isTierOne() ? (<div><h3>You have already upgraded!</h3></div>) : 
      ( <div className="focus-login">
    <h3>To upgrade your account, enter your credit card and create subscription!</h3>
  <Elements stripe={stripePromise}>
      <UpgradeHome 
        addressLine={addressLine}
        addressCity={addressCity}
        addressState={addressState}
        addressCountry={addressCountry}
        email={sponuser.email}
        stripeid={sponuser.stripeid}
        name={sponuser.name}
        gid={sponID}
      />
    </Elements>
 </div>)}
      <br />
      </div>)
}
      <br />
      <br />
            <br />
            <br />
            <br />
    </div>
)

}

export default SetTier;