import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const SponAccountSettings = (props) => {

    const [sponuser] = useState(JSON.parse(localStorage.getItem('sponuser')) || [])
    const [img, setImg] = useState([]);
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tier] = useState(sponuser.spontier);

useEffect(() => {
    fetchInfo();
}, [])

const fetchInfo = async () => {
    const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/content/sponacctset`);
    const info = await fetchInfo.json();
    const fetchIt = await fetch(`${process.env.REACT_APP_API_URL}/b/sponimg/${ sponuser.sponsorid }`);
    const img = await fetchIt.json();
    setInfo(info);
    setImg(img);
    setLoading(false);
};

function isPremium() {
    return tier > 0;
}

return (
    <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
    <div className="focus-div">
   <div className="dash-header">
   <Link to={`/businessupload`}><img id="image" src={`${process.env.REACT_APP_CDN_URL}/${img.image}`} alt="" height="50" width="50" /></Link>
    <h1>{info.title}</h1>
    </div>
    <div className="focus-invisible-array">
    <div className="focus-stat-item"><h2><Link to='/businessupload'>Change profile image</Link></h2></div>
    <div className="focus-stat-item"><h2><Link to='/editbusiness'>Edit Profile</Link></h2></div>
    <div className="focus-stat-item"><h2><Link to='/editbusinessemail'>Edit Login Credentials</Link></h2></div>
    <div className="focus-stat-item"><h2><Link to='/paymenthistory'>Payment History</Link></h2></div>
      {isPremium() ? ( <div className="focus-stat-item"><h2><Link to='/managesubscription'>Manage Subscription</Link></h2></div>) : ( <div className="focus-stat-item"><h2><Link to='/upgradeaccount'>Purchase Premium</Link></h2></div>) }   
      </div>   
      <br />
      </div>)
}
      <br />
      <br />
            <br />
            <br />
            <br />
    </div>
)

}

export default SponAccountSettings;