import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';
import SponDrawerToggleButton from './SponDrawerToggleButton';
import useOutside from "./useOutside";
import Backdrop from './Backdrop';
import Backdrop2 from './Backdrop2';
import TinySpinner from './TinySpinner';
import backlesswhite from './backlesswhite.png';

function isAdminAuthenticated(){
  return localStorage.getItem('x-admin-key') && localStorage.getItem('x-admin-key-expiration') > Date.now()
  }

  const logOut = () => {
    localStorage.removeItem('x-admin-key');
    localStorage.removeItem('x-admin-key-expiration');
  }

const Toolbar = (props) => {

  const ref = useRef();
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const [term, setTerm] = useState("");
  const [searchClosed, setSearchClosed] = useState(true);

  const [results, setResults] = useState([]);
  const [gways, setGways] = useState([]);
  const [biz, setBiz] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (term === "") {
      return setSearchClosed(true)
    } else {
      return setSearchClosed(false)
    }
  }, [term])

  function isSearchClosed() {
    return searchClosed === true;
    }

  function handleInput (e) { 
    setTerm(e.target.value);   
    instantSearch(); 
  }

  const groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
           if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

useEffect(() => {
  if (searchResults === false) {
      setGways(false);
      setBiz(false);
      return setResults(false);
  } else {
  let grouped = groupBy(searchResults, searchResult => searchResult.origintable);
  setGways(grouped.get('apigway'));
  setBiz(grouped.get('apisponsor'));
  return setResults(true);
  }
}, [searchResults])

async function instantSearch() {
  const params = { term };
  return axios.get(`${process.env.REACT_APP_API_URL}/b/instantsearch/`, { params })
  .then(response => {
 setSearchResults(response.data)
  })
  .catch(err => Promise.reject('Search could not be completed!'));
  }

  const drawerToggleClickHandler = () => {
        return setSideDrawerOpen(true);
      };

      useOutside(ref, () => {
        if (sideDrawerOpen) setSideDrawerOpen(false);
      });

      let backdrop;

      if (sideDrawerOpen === true) {
        backdrop = <Backdrop />
      } else {
        backdrop = <div></div>
      }

      useOutside(ref, () => {
        if (searchClosed === false) setSearchClosed(true);
      });

      let searchdrop;

      if (searchClosed === false) {
        searchdrop = <Backdrop2 />
      } else {
        searchdrop = <div></div>
      }

      const renderData = (displayGways, displayBusinesses) => {
        if (term.length < 3)  {
          return (
            <div className="search-spinbox">
          <TinySpinner />
          </div>
          )
        }
        if ((term.length >= 3) && (results === true) && (searchResults !== false)) {
        return (
            <div className="search-link-box"><div>
            {displayGways && displayGways.map((gway, key) => {
                     return (
                         <ul className="search-link">
                             <li className="search-result-link">
                              <div className="search-result-image">
                              {gway.type === 'classic' ? <Link target="_blank" to={`/giveaways/${gway.id}`}><img className="search-img-array" id="image" src={`${process.env.REACT_APP_CDN_URL}/${gway.image}`} alt="" height="75" width="75" /></Link> : <Link target="_blank" to={`/instant/${gway.id}`}><img className="search-img-array" id="image" src={`${process.env.REACT_APP_CDN_URL}/${gway.image}`} alt="" height="75" width="75" /></Link>}
                             </div>
                             {gway.type === 'classic' ? <h3><Link target="_blank" to={`/giveaways/${gway.id}`}>{gway.name}</Link></h3> : <h3><Link target="_blank" to={`/instant/${gway.id}`}>{gway.name}</Link></h3>}
                             {gway.type === 'classic' ? <p><small>Classic Giveaway</small></p> : <p><small>Instant Win</small></p>}
                             </li>
                         </ul>
                     )
                 })}
            </div>
            <div>{displayBusinesses && displayBusinesses.map((displayBusiness, key) => {
                     return (
                         <ul className="search-link">
                             <li className="search-result-link">
                             <div className="search-result-image">
                             <Link target="_blank" to={`/business/${displayBusiness.id}`}><img className="search-img-array" id="image" src={`${process.env.REACT_APP_CDN_URL}/${displayBusiness.image}`} alt="" height="75" width="75" /></Link>
                             </div>
                             <h3><Link target="_blank" to={`/business/${displayBusiness.id}`}>{displayBusiness.name}</Link></h3>
                             <p><small>Business</small></p>
                             </li>
                         </ul>
                     )
                 })}</div>
            <h3 className="more-results"><a href={'/quicksearch/?s='+term} target="_blank">More results...</a></h3>
                 </div>
        );
                } if ((term.length >= 3) && (searchResults === false)) {
                  return (
                    <div className="search-spinbox">
                 <p>No Results Found!</p>
                  </div>
                  )
                }
      };

      function isDefined(arr) {
        if (arr === undefined) {
          return []
        } if (arr === false) {
          return arr
        } else return arr.slice(0,3)
      }

      const newGways = isDefined(gways)

      const newBiz = isDefined(biz)

return (
  <header className="toolbar">
    <div ref={ref}>
    <Sidebar show={sideDrawerOpen} />
    </div>
    {backdrop}
    {searchdrop}
    <nav className="spontoolbar__navigation">
    <div className="spontoolbar__toggle-button">
            <SponDrawerToggleButton click={drawerToggleClickHandler}/>
        </div>
      <div />
      <div className="spontoolbar__logo">
      <Link to="/"><img className="navimg" id="backlesswhite" src={backlesswhite} alt="Givmap" height="37" width="120" /></Link>
      </div>
      <div className="spacer" />
      <div className="navsearchbox">
        <div className="navform">
      <form action="/quicksearch/" method="get" autoComplete="off" className="form">
        <input
            type="text"
            id="header-search"
            placeholder="🔍"
            name="s" 
            onInput={handleInput}
            value={ term }
        />
        <button type="submit" className="navsearch-button">
        </button>
    </form>
    </div>
    { ( isSearchClosed() ) ? '' : (<div className="results-drop">
        <div className="dropdown-content">
        {renderData(newGways, newBiz)}
          </div>
        </div>)}
    </div>
    <div className="spacer" />
      { ( isAdminAuthenticated() ) ? (<div className="spontoolbar_navigation-items"><ul><li onClick={logOut}><a href="/">Logout</a></li></ul></div>) :
      (<div className="spontoolbar_navigation-items">
        <ul>
          <li>
          <Link to="/giveaways">Giveaways</Link><br />
          </li>
          <li><Link to="/create">Sign Up</Link><br /></li>
          <li><Link to="/login">Login</Link><br /></li>
        </ul>
      </div>) }
    </nav>
  </header>
      )
}

export default Toolbar;