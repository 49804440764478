import React, { useState, useEffect } from "react";
import { itemCreate } from './repository';
import Spinner from './Spinner';

const CreateItemHooks = (props) => {

        const initialInputState = { itemname: '', itemcategory: '', description: '', value: '' };
        const [eachEntry, setEachEntry] = useState(initialInputState);
        const {itemname, itemcategory, description, value} = eachEntry;
        const [sponuser, setUser] = useState(JSON.parse(localStorage.getItem('sponuser')))
        const [info, setInfo] = useState([]);
        const [loading, setLoading] = useState(true);

        useEffect(()=>{
            fetchInfo();
        }, [])

        const fetchInfo = async () => {
            const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/content/createnewiteminfo`);
            const info = await fetchInfo.json();
            setInfo(info);
            setLoading(false);
        };

        const handleInputChange = e => {
            setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
          };
        
          const handleFinalSubmit = e => {
              e.preventDefault();
              itemCreate(eachEntry, sponuser.sponsorid)
              .then(token => window.location = '/businessreturn')
              .catch(err => alert(err));
          };

    return(
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
        <h1>{info.itemInfo}, {sponuser.name}!</h1>
        <div className="focus-login">
        <form onSubmit={handleFinalSubmit}>
        <div className="form-group">
        <label><h3>1. Item Name: </h3></label>
        <input type="text" className="form-control" name="itemname"
        onChange={handleInputChange}/>
        </div>
        <div className="form-group">
        <label><h3>2. Product Category: </h3></label>
        <select name="itemcategory" className="form-control" onChange={handleInputChange}>
                 <option value=""></option>
                 <option value="Antiques/Collectibles">Antiques/Collectibles</option>
                 <option value="Art">Art</option>
                 <option value="Athletic/Sports Gear">Athletic/Sports Gear</option>
                 <option value="Automotive">Automotive</option>
                 <option value="Beauty/Personal Care">Beauty/Personal Care</option>
                 <option value="Books">Books</option>
                 <option value="Clothing">Clothing</option>
                 <option value="Computers/Electronics">Computers/Electronics</option>
                 <option value="Food/Restaurant">Food/Restaurant</option>
                 <option value="Gift Cards">Gift Cards</option>
                 <option value="Hats/Headwear">Hats/Headwear</option>
                 <option value="Health">Health</option>
                 <option value="Home/Kitchen Goods">Home/Kitchen Goods</option>
                 <option value="Kids/Toys">Kids/Toys</option>
                 <option value="Luggage/Travel Gear">Luggage/Travel Gear</option>
                 <option value="Magazines/Media">Magazines/Media</option>
                 <option value="Movies/TV">Movies/TV</option>
                 <option value="Music">Music</option>
                 <option value="Musical Instruments">Musical Instruments</option>
                 <option value="NFTs">NFTs (Non-Fungible Tokens)</option>
                 <option value="Outdoors">Outdoors</option>
                 <option value="Pets">Pets</option>
                 <option value="Shoes">Shoes</option>
                 <option value="Software">Software</option>
                 <option value="Tickets">Tickets</option>
                 <option value="Video Games/E-Sports">Video Games/E-Sports</option>
                 <option value="Other">Other</option>
               </select>
        </div>
        <div className="form-group">
        <label><h3>3. Description: </h3></label>
        <input type="text" className="form-control" name="description"
        onChange={handleInputChange}/>
        </div>
         <div className="form-group">
        <label><h3>4. Value ($): </h3></label>
        <input type="text" className="form-control" name="value"
        onChange={handleInputChange}/>
        </div>
        <br />
        <button type="submit" className="february-form-submit-button"> Create </button>
        </form>
        <br />
        </div>
        <br />
        </div>)
}
        <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default CreateItemHooks;