import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SponChartBasic from './SponChartBasic';
import SponChartTotalThree from './SponChartTotalThree';
import SponEntriesChartThree from './SponEntriesChartThree';
import SponFavsBarChartThree from './SponFavsBarChartThree';
import SponFollowsBarChartThree from './SponFollowsBarChartThree';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import SpinnerFetching from './SpinnerFetching';
import SponsorModeChart from './SponsorModeChart';

const SponsorDash = (props) => {

    const [sponuser] = useState(JSON.parse(localStorage.getItem('sponuser')))
    const [img, setImg] = useState([]);
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tier, setTier] = useState(sponuser.spontier);
    const [expired, setExpired] = useState(false);
    const [stripeId] = useState(sponuser.stripeid);
    const [subscription, setSubscription] = useState([]);
    const [isSet, setIsSet] = useState(false);
    const [expiresSoon, setExpiresSoon] = useState(false);
    const [subSet, setSubSet] = useState(false)
    const [linksOpen, setLinksOpen] = useState(false);
    const [gwayInfo, setGwayInfo] = useState([]);
    const [data, setData] = useState([]);
    const [givyViews, setGivyViews] = useState([]);
    const [itemViews, setItemViews] = useState([]);
    const [givyNumber, setGivyNumber] = useState(0);
    const [itemNumber, setItemNumber] = useState(0);
    const [sponNumber, setSponNumber] = useState(0);
    const [gwayLogs, setGwayLogs] = useState([]);
    const [itemLogs, setItemLogs] = useState([]);
    const [sponLogs, setSponLogs] = useState([]);
    const [allLogs, setAllLogs] = useState([]);
    const [processing, setProcessing] = useState(true);
    const [display, setDisplay] = useState('Profile Views');
    const [entriesSet, setEntriesSet] = useState(false);
    const [favsSet, setFavsSet] = useState(false);
    const [followsSet, setFollowsSet] = useState(false);
    const [entriesLogs, setEntriesLogs] = useState([]);
    const [favsLogs, setFavsLogs] = useState([]);
    const [followsLogs, setFollowsLogs] = useState([]);
    const [entriesLoading, setEntriesLoading] = useState(true);
    const [favsLoading, setFavsLoading] = useState(true);
    const [followsLoading, setFollowsLoading] = useState(true);
    const [modeLogs, setModeLogs] = useState([]);
    const [modesLoading, setModesLoading] = useState(true);
    const [modeSet, setModeSet] = useState(false);

    var yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1);
    var thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    useEffect(() => {
        fetchInfo();
   }, [])

      const getDaysBetweenDates = function(startDate, endDate) {
        var listDate = [];
        var dateMove = new Date(startDate);
        var strDate = startDate;
        while (strDate < endDate){
          var strDate = dateMove.toISOString().slice(0,10);
          listDate.push(strDate);
          dateMove.setDate(dateMove.getDate()+1);
        };
        return listDate;
      }

useEffect(() => {
    if (isSet === true) {
    fetchGwayInfo();
    } else return
}, [isSet])

const fetchGwayInfo = async () => {
    const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/gwaysfromcombo/${sponuser.sponsorid}/${thirtyDaysAgo.toISOString().slice(0, 10)}`);
    const info = await fetchInfo.json();
    if (info === false) {
        setGwayInfo([])
        fetchLogs();
        setLoading(false);
    } else {
    const result = await info.map(x=>parseFloat(x.value));
    const reducer = await result.reduce(function(acc, cur) {
        return acc + cur;
    });
    setGwayInfo(reducer);
    fetchLogs();
    setLoading(false);
    }
};

const fetchLogs = async () => {
    var yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1);
    var thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    try {
    const fetchGway = await fetch(`${process.env.REACT_APP_ANALYTICS_URL}/g/newselectgwaylogs/${thirtyDaysAgo.toISOString().slice(0, 10)}/${yesterday.toISOString().slice(0, 10)}/${sponuser.sponsorid}`);
    const gway = await fetchGway.json();
    setGwayLogs(gway);
    setGivyViews(gway);
    const fetchItem = await fetch(`${process.env.REACT_APP_ANALYTICS_URL}/g/newselectitemlogs/${thirtyDaysAgo.toISOString().slice(0, 10)}/${yesterday.toISOString().slice(0, 10)}/${sponuser.sponsorid}`);
    const item = await fetchItem.json();
    setItemLogs(item);
    setItemViews(item);
  const fetchSpon = await fetch(`${process.env.REACT_APP_ANALYTICS_URL}/g/newselectsponlogs/${thirtyDaysAgo.toISOString().slice(0, 10)}/${yesterday.toISOString().slice(0, 10)}/${sponuser.sponsorid}`);
    const spon = await fetchSpon.json();
    setSponLogs(spon);
    setData(spon);
    const newBiz = spon.map(function(row) {
      return { "date" : row.date, "Profile Views" : row.views }
   })
    const mergedArr = gway.concat(item, spon).reduce(function(acc, curr) {
      if (curr.date) {
        var fromMap = acc.map[curr.date];
        if (!fromMap) {
          acc.map[curr.date] = fromMap = {
            views: 0,
            date: curr.date
          }
          acc.result.push(fromMap);
        }
        fromMap.views += parseFloat(curr.views);
      } else {
        acc.result.push(curr);
      }
      return acc;
    }, {
      map: {},
      result: []
    }).result;
    const newNew = mergedArr.map(function(row) {
      return { "date" : row.date, "Total Views" : row.views }
   })
   const result = await newBiz.map(v => ({ ...v, ...newNew.find(sp => sp.date === v.date) }));
   const sumGway = gway.map(date => date.views).reduce((prev, curr) => prev + curr, 0);
   const sumItem = item.map(date => date.views).reduce((prev, curr) => prev + curr, 0);
   const sumSpon = spon.map(date => date.views).reduce((prev, curr) => prev + curr, 0);
   setGivyNumber(sumGway)
   setItemNumber(sumItem);
   setSponNumber(sumSpon);
   setAllLogs(result);
    setProcessing(false);
    } catch {
      return
    }
  }

const fetchInfo = async () => {
    const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/content/dash`);
    const info = await fetchInfo.json();
    const fetchIt = await fetch(`${process.env.REACT_APP_API_URL}/b/sponimg/${sponuser.sponsorid}`);
    const img = await fetchIt.json();
    setImg(img);
    setInfo(info);
    setIsSet(true);
};

async function setTierZero (data) {
    const url = `${process.env.REACT_APP_API_URL}/b/tierzero/${sponuser.sponsorid}`;
    const head = { 'x-api-key': localStorage.getItem('x-api-key')};
    return axios.patch(url, head)
    .then(response => {
    return response.status
    })
    .catch(err => Promise.reject('Could not cancel subscription!'));
    }

useEffect(() => {
 if (isSet === true && tier > 0) {
        axios.get(`${process.env.REACT_APP_API_URL}/b/customerstatusdash/${stripeId}`)
        .then(res => {
            if (res.data.length === 0) {
                setExpired(true);
                setTierZero();
                setSubscription({'cancel_at_period_end': false})
                setTier(0);
                return setSubSet(true)
            } else {
                if (res.data[0].cancel_at_period_end) {
                    setSubscription(res)
                    setExpiresSoon(true);
                    return setSubSet(true)
                } else {
              setSubscription(res)
              return setSubSet(true)
                }
            }
        })
      } else return
}, [isSet])

    const linkToggler = () => {
        if (linksOpen === false) {
            return setLinksOpen(true);
        } else {
            return setLinksOpen(false);
        }
    }

    function tierOne() {
        return tier === 1;
    }

    function tierZero() {
        return tier === 0;
    }

    const roundToHundredth = (value) => {
        return Number(value.toFixed(2));
      };

    function hasGwayViews() {
        return givyNumber > 0;
    }

    function hasItemViews() {
        return itemNumber > 0;
    }

    function hasSponViews() {
        return sponNumber > 0;
    }

    const totalViews = (+sponNumber) + (+givyNumber) + (+itemNumber);

    const sum = gwayInfo/totalViews;

    const costPerClick = roundToHundredth(sum);

    const costSubbed = (gwayInfo + 29);

    const sumSubbed = costSubbed/totalViews;

    const costPerClickSubbed = roundToHundredth(sumSubbed);

    const alternative = 29/totalViews;

    const cpcSubNoGways = roundToHundredth(alternative);

    const fetchEntryLogs = async () => {
        var yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1);
        var thirtyDaysAgo = new Date()
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        try {
        const fetchEntries = await fetch(`${process.env.REACT_APP_ANALYTICS_URL}/g/newselectentrylogs/${thirtyDaysAgo.toISOString().slice(0, 10)}/${yesterday.toISOString().slice(0, 10)}/${sponuser.sponsorid}`);
        const entries = await fetchEntries.json();
        const newNew = entries.map(function(row) {
        return { "date" : row.date, "Entries" : row.views }
     })
        setEntriesLogs(newNew);
        setEntriesLoading(false);
        setEntriesSet(true);
        } catch {
          return
        }
      }

      const fetchFavLogs = async () => {
        var yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1);
        var thirtyDaysAgo = new Date()
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        try {
        const fetchFavs = await fetch(`${process.env.REACT_APP_ANALYTICS_URL}/g/newselectfavlogs/${thirtyDaysAgo.toISOString().slice(0, 10)}/${yesterday.toISOString().slice(0, 10)}/${sponuser.sponsorid}`);
        const favs = await fetchFavs.json();
        const newNew = favs.map(function(row) {
        return { "date" : row.date, "Favorites" : row.views }
     })
     setFavsLogs(newNew);
     setFavsLoading(false);
     setFavsSet(true);
        } catch {
          return
        }
      }

  const fetchFollowLogs = async () => {
    var yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1);
    var thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    try {
    const fetchFollows = await fetch(`${process.env.REACT_APP_ANALYTICS_URL}/g/newselectfollowlogs/${thirtyDaysAgo.toISOString().slice(0, 10)}/${yesterday.toISOString().slice(0, 10)}/${sponuser.sponsorid}`);
    const follows = await fetchFollows.json();
    const newNew = follows.map(function(row) {
    return { "date" : row.date, "Followers" : row.views }
 })
 setFollowsLogs(newNew);
 setFollowsLoading(false);
 setFollowsSet(true);
    } catch {
      return
    }
  }

  const fetchModeLogs = async () => {
    var yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1);
    var thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    try {
    const fetchFollows = await fetch(`${process.env.REACT_APP_ANALYTICS_URL}/g/toprefmonth/${thirtyDaysAgo.toISOString().slice(0, 10)}/${yesterday.toISOString().slice(0, 10)}/${sponuser.sponsorid}`);
    const follows = await fetchFollows.json();
    const newNew = follows.map(function(row) {
    return { "site" : row.site, "clicks" : row.clicks }
 })
 setModeLogs(newNew);
 setModesLoading(false);
 setModeSet(true);
    } catch {
      return
    }
  }

    const handleInputChange = e => {
        setDisplay(e.target.value);
      };

      useEffect(()=> {
        if (display === 'New Entries' && entriesSet === false && tier === 1) {
            return fetchEntryLogs();
        } if (display === 'New Favorites' && favsSet === false && tier === 1) {
            return fetchFavLogs();
        } if (display === 'New Follows' && followsSet === false && tier === 1) {
            return fetchFollowLogs();
        } if (display === 'Top Referrers' && modeSet === false && tier === 1) {
            return fetchModeLogs();
        }
      }, [display])

    function showProfileViews () {
        return display === 'Profile Views'
    }

    function showNewEntries () {
        return display === 'New Entries'
    }

    function showNewFavs () {
        return display === 'New Favorites'
    }

    function showNewFollows () {
        return display === 'New Follows'
    }

    function showTopReferrers () {
        return display === 'Top Referrers'
  }

return (
    <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
    <div className="focus-div">
    <div className="dash-header">
    <Link to={`/businessupload`}><img id="image" src={`${process.env.REACT_APP_CDN_URL}/${img.image}`} alt="" height="50" width="50" /></Link>
    <h1> {info.dash}, {sponuser.name}!</h1>
    </div>
      { (expiresSoon) ? (<div className='glass-dropdown-box'><p>***Your subscription is set to expire soon!***</p>
            <p><Link to={'/managesubscription'}>Manage Subscription</Link></p>
            </div>) : ('')}
      <div className="glass-dropdown-box">
              <button className="glass-sort-button" onClick={() => linkToggler()}>Links:</button>
                {linksOpen && (
                <div>
                     <div>
                        <h3>Manage Giveaways</h3>
                        <p><Link to='/livegiveaways'>Live Giveaways</Link></p>
                        { tierOne() ? (<p><Link to='/scheduledgiveaways'>Scheduled Giveaways</Link></p>) : '' }
                        <p><Link to='/expiredgiveaways'>Expired Giveaways</Link></p>
                        <p><Link to='/allgiveaways'>All Giveaways</Link></p>
                        <p><Link to='/creategiveaway'>Create Giveaway</Link></p>
                        <h3>Manage Products</h3>
                        <p><Link to='/myshop'>All Products</Link></p>
                        <p><Link to='/createproduct'>Create Product</Link></p>
                        <h3>Other</h3>
                        <p><Link to='/accountsettings'>Account Settings</Link></p>
                    </div>
                </div>
                )}
            </div>
            {(processing) ? ('') : (
            <div>
            {tierOne() ? (
      <div>
      <div className="focus-invisible-array">
      {(hasGwayViews() && hasItemViews() && hasSponViews()) ? (<div className="focus-stat-item"><h1>${costPerClickSubbed}</h1><h3>Cost-Per-Click Ratio (CPC) last 30 days.*</h3></div>) : (<div className="focus-array-item"><h1>${cpcSubNoGways}</h1><h3>Cost-Per-Click Ratio (CPC) last 30 days.*</h3></div>)}
      <div className="focus-stat-item"><h1>{sponNumber.toLocaleString("en-US")}</h1><h3>Profile views last 30 days.</h3></div>
      <div className="focus-stat-item"><h1>{givyNumber.toLocaleString("en-US")}</h1><h3>Giveaway views last 30 days.</h3></div>
      <div className="focus-stat-item"><h1>{itemNumber.toLocaleString("en-US")}</h1><h3>Product views last 30 days.</h3></div>
      </div>  
      </div>
      ) : (
      <div>
      <div className="focus-invisible-array">
      {(hasGwayViews() && hasItemViews() && hasSponViews()) ? (<div className="focus-stat-item"><h1>${costPerClick}</h1><h3>Cost-Per-Click Ratio (CPC) last 30 days.</h3></div>) : (<div className="focus-array-item"><h1>N/A</h1><h3>Cannot calculate overall Cost-Per-Click (CPC) yet!</h3></div>)}
      <div className="focus-stat-item"><h1>{sponNumber.toLocaleString("en-US")}</h1><h3>Profile views last 30 days.</h3></div>
      <div className="focus-stat-item"><h1>{givyNumber.toLocaleString("en-US")}</h1><h3>Giveaway views last 30 days.</h3></div>
      <div className="focus-stat-item"><h1>{itemNumber.toLocaleString("en-US")}</h1><h3>Product views last 30 days.</h3></div>
      </div>
      </div>)}
      </div>)}
            {(processing) ? ( <SpinnerFetching /> ) : (
            <div>
            <div className="dash-glass-chart">
            <div className="form-dash">
                <label><h3>Select Data: </h3></label>
                <select className="form-control" onChange={handleInputChange}>
                        <option value={"Profile Views"}>Profile Views</option>
                        <option value={"New Entries"}>New Entries</option>
                        <option value={"New Favorites"}>New Favorites</option>
                        <option value={"New Follows"}>New Follows</option>
                        <option value={"Top Referrers"}>Top Referrers</option>
                    </select>
                </div>
                {showProfileViews() && tierOne() ? <div>
                    <SponChartTotalThree 
                        allLogs={allLogs}
                    />
                    </div> :
                    showProfileViews() && tierZero() ? <div>
                    <SponChartBasic 
                         allLogs={allLogs}
                    />
                    </div> :
       showNewEntries() && tierOne() ? <div>
           <h3>Entries into  {sponuser.name} Giveaways</h3>
           <SponEntriesChartThree 
        entryLogs={entriesLogs}
         loading={entriesLoading}
      />
           </div> :
       showNewEntries() && tierZero() ? <div>
       <h3>Upgrade to Premium to view New Entries Chart!</h3>
       </div> :
        showNewFavs() && tierOne() ? <div>
            <h3>Favorites of  {sponuser.name} Products</h3>
            <SponFavsBarChartThree 
        favLogs={favsLogs}
         loading={favsLoading}
      />
            </div> :
              showNewFavs() && tierZero() ? <div>
                    <h3>Upgrade to Premium to view New Favorites Chart!</h3>
              </div> :
         showNewFollows() && tierOne() ? <div>
             <h3>Follows of {sponuser.name}</h3>
             <SponFollowsBarChartThree 
        followLogs={followsLogs}
         loading={followsLoading}
      />
      </div> :
        showNewFollows() && tierZero() ? <div>
            <h3>Upgrade to Premium to view New Follows Chart!</h3>
 </div> :
         showTopReferrers() && tierOne() ? <div>
             <h3>Top 3 Referrers of {sponuser.name} Giveaways *</h3>
             <SponsorModeChart 
              modeLogs={modeLogs}
              loading={modesLoading}
            />
             <p><small>* This chart shows the top 3 websites from where your Giveaway clicks originated over the past 30 days.</small></p>
      </div> :
        showTopReferrers() && tierZero() ? <div>
            <h3>Upgrade to Premium to view Top Referrers Chart!</h3>
 </div>: ''}
            </div>
            <div>
            {tierOne() ? (
 <div className="dash-text">
      <p>* Overall Cost-Per-Click is calculated by adding the sum of the value of giveaways created in the past 30 days with the monthly price of your subscription divided by the total number of views of your business profile, giveaways, and products:</p>
      <p>Overall Cost-Per-Click = (Value of Giveaways last 30 days + Subscription cost) / Total Views</p>
      <p>The lower the the CPC, the better!</p>
</div>) : (
<div className="dash-text">
<p>* Overall Cost-Per-Click is calculated by adding the sum of the value of giveaways created in the past 30 days divided by the total number of views of your business profile, giveaways, and products.</p>
      <p>Overall Cost-Per-Click = Value of Giveaways created this month / Total Views</p>
      <p>The lower the CPC, the better!</p>
</div>
) }
            </div>
            </div>) }
      <br />
      </div>)
}
      <br />
      <br />
            <br />
            <br />
            <br />
    </div>
)

}

export default SponsorDash;