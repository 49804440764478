import React, { useState, useEffect }  from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const About = (props) => {

    const [item, setItem] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        fetchItem();
    }, [])

    const fetchItem = async () => {
        const fetchItem = await fetch(`${process.env.REACT_APP_API_URL}/b/content/about`);
        const item = await fetchItem.json();
        setItem(item);
        setLoading(false);
    };

    return (
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
        <div className="focus-div">
        <div>
        <h1> About Givmap: </h1>
        </div>
        <div>
        <h3>{item.Slogan}.</h3>
        </div>
        <div className="focus-about">
        <h3>Givmap is creating the best Giveaway experience out there.</h3>
        <span>🎁</span>
        <h3>Contact Givmap:</h3>
        <a href="mailto:contact@givmap.com">contact@givmap.com</a>
        <br />
        </div>
        <br />
        <div>
        <h3><Link to="/businessabout">Givmap for your Business</Link><br /></h3>
        <h3><Link to="/faq">Frequently Asked Questions</Link><br /></h3>
        <h3><Link to="/terms">Terms and Conditions</Link><br /></h3>
        <h3><Link to="/privacy">Privacy Policy</Link><br /></h3>
        </div>
       </div>)
        }
        <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default About;