import React, { useEffect, useState } from "react";
import Spinner from './Spinner';
import {Link} from 'react-router-dom';
import { isSponAuthenticated } from './repository';

const Giveaways = () => {

  const [loading, setLoading] = useState(true);

  const [items, setItems] = useState([]);
  const [data, setData] = useState(items);

  const [itemcategory, setItemCategory] = useState('all');
  const [location, setLocation] = useState('all');

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(60);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const [sortType, setSortType] = useState("near");

  const today = new Date().toISOString().slice(0, 10);

  const renderData = (giveaways) => {
    return (
      <div className="focus-invisible-array">
        {giveaways.map(giveaway => 
            <div key={giveaway.gwayid} className="focus-array-item">
                               { giveaway.origintable === 'apigway' ? <Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link> : <Link target="_blank" to={`/instant/${giveaway.gwayid}`}><img className="medium-img" id="image" src={`${process.env.REACT_APP_CDN_URL}/${giveaway.img1}`} alt="" height="75" width="75" /></Link>}
                               { giveaway.origintable === 'apigway' ? <h3 className="title"><Link target="_blank" to={`/giveaways/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> : <h3 className="title"><Link target="_blank" to={`/instant/${giveaway.gwayid}`}>{giveaway.itemname}</Link></h3> }
                                <p>by <Link target="_blank" to={`/business/${giveaway.sponsorid}`}>{giveaway.name}</Link></p>
                                { giveaway.origintable === 'apigway' ? <p><small>Ends: {new Date(giveaway.endon).toDateString()}</small></p> : <p><small>Instant Win</small></p> }
                         </div>
  )}
      </div>
    );
  };

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  useEffect(() => {
   fetchPosts();
  }, []);

  const fetchPosts = async () => {
    const fetchGiveaways = await fetch(`${process.env.REACT_APP_API_URL}/b/activegiveawayscombo/${today}`);
    const items = await fetchGiveaways.json();
    const final = await items.map(x => ({
      ...x,
      ending: new Date(x.endon).getTime(),
      newValue: parseFloat(x.value)
  }))
    setItems(final);
    setLoading(false);
  };

const handleFilterChange = (e, filterType) => {
    switch (filterType) {
        case "itemcategory":
            setItemCategory(e.target.value)
            break;
        case "location":
            setLocation(e.target.value)
            break;
        case "sort":
            setSortType(e.target.value)
            break;
        default: break;
    }
}

useEffect(()=> {
  let arr = [...data];
  if (sortType === "near") {
    arr.sort((a, b) => a.ending - b.ending)
  } if (sortType === "far") {
    arr.sort((a, b) => b.ending - a.ending)
  } if (sortType === "high") {
    arr.sort((a, b) => b.newValue - a.newValue)
  } if (sortType === "low") {
    arr.sort((a, b) => a.newValue - b.newValue)
  }
  setData(arr)
}, [sortType])

   useEffect(()=> {
    let filteredGiveaways = [...items];
    if (itemcategory !== "all") {
      filteredGiveaways = filteredGiveaways.filter(giveaway => giveaway.itemcategory === itemcategory)
     } if (location !== "all") {
      filteredGiveaways = filteredGiveaways.filter(giveaway => giveaway.location === location)
     }
     setData(filteredGiveaways)
   }, [itemcategory, location, items])

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  function isArrThere() {
    return data.length > 0;
  }

  function isArrBig() {
    return data.length > 60;
  }

  return (
    <div>
        <br />
        <br />
        <br />
        {
        (loading) ? ( <Spinner /> ) : (
        <div className="focus-div">
         { ( isSponAuthenticated() ) ? <h3><Link to="/creategiveaway">Create a Giveaway!</Link></h3> : ''}
         <div>
         <h1>Active Giveaways: </h1>
         </div>
            <div className="focus-invisible-array">
            <form className="focus-container" id="cat_form">
                <label htmlFor="itemcategory"><h3>Category: </h3></label>
                <select name="itemcategory" id="itemcategory" onChange={(e) => handleFilterChange(e, "itemcategory")}>
                <option value="all">All</option>
                 <option value="Antiques/Collectibles">Antiques/Collectibles</option>
                 <option value="Art">Art</option>
                 <option value="Athletic/Sports Gear">Athletic/Sports Gear</option>
                 <option value="Automotive">Automotive</option>
                 <option value="Beauty/Personal Care">Beauty/Personal Care</option>
                 <option value="Books">Books</option>
                 <option value="Clothing">Clothing</option>
                 <option value="Computers/Electronics">Computers/Electronics</option>
                 <option value="Food/Restaurant">Food/Restaurant</option>
                 <option value="Gift Cards">Gift Cards</option>
                 <option value="Hats/Headwear">Hats/Headwear</option>
                 <option value="Health">Health</option>
                 <option value="Home/Kitchen Goods">Home/Kitchen Goods</option>
                 <option value="Kids/Toys">Kids/Toys</option>
                 <option value="Luggage/Travel Gear">Luggage/Travel Gear</option>
                 <option value="Magazines/Media">Magazines/Media</option>
                 <option value="Movies/TV">Movies/TV</option>
                 <option value="Music">Music</option>
                 <option value="Musical Instruments">Musical Instruments</option>
                 <option value="NFTs">NFTs (Non-Fungible Tokens)</option>
                 <option value="Pets">Pets</option>
                 <option value="Outdoors">Outdoors</option>
                 <option value="Shoes">Shoes</option>
                 <option value="Software">Software</option>
                 <option value="Tickets">Tickets</option>
                 <option value="Video Games/E-Sports">Video Games/E-Sports</option>
                 <option value="Other">Other</option>
                </select>
            </form>
            <form className="focus-container" id="loc_form">
                <label htmlFor="location"><h3>Location Available: </h3></label>
                <select name="location" id="location" onChange={(e) => handleFilterChange(e, "location")}>
                <option value="all">All</option>
                <option value="United States">United States</option>
                <option value="Alabama">Alabama</option>
                <option value="Alaska">Alaska</option>
                <option value="Arizona">Arizona</option>
                <option value="Arkansas">Arkansas</option>
                <option value="California">California</option>
                <option value="Colorado">Colorado</option>
                <option value="Connecticut">Connecticut</option>
                <option value="Delaware">Delaware</option>
                <option value="Florida">Florida</option>
                <option value="Georgia">Georgia</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Idaho">Idaho</option>
                <option value="Illinois">Illinois</option>
                <option value="Indiana">Indiana</option>
                <option value="Iowa">Iowa</option>
                <option value="Kansas">Kansas</option>
                <option value="Kentucky">Kentucky</option>
                <option value="Louisiana">Louisiana</option>
                <option value="Maine">Maine</option>
                <option value="Maryland">Maryland</option>
                <option value="Massachusetts">Massachusetts</option>
                <option value="Michigan">Michigan</option>
                <option value="Minnesota">Minnesota</option>
                <option value="Mississippi">Mississippi</option>
                <option value="Missouri">Missouri</option>
                <option value="Montana">Montana</option>
                <option value="Nebraska">Nebraska</option>
                <option value="Nevada">Nevada</option>
                <option value="New Hampshire">New Hampshire</option>
                <option value="New Jersey">New Jersey</option>
                <option value="New Mexico">New Mexico</option>
                <option value="New York">New York</option>
                <option value="North Carolina">North Carolina</option>
                <option value="North Dakota">North Dakota</option>
                <option value="Ohio">Ohio</option>
                <option value="Oklahoma">Oklahoma</option>
                <option value="Oregon">Oregon</option>
                <option value="Pennsylvania">Pennsylvania</option>
                <option value="Rhode Island">Rhode Island</option>
                <option value="South Carolina">South Carolina</option>
                <option value="South Dakota">South Dakota</option>
                <option value="Tennessee">Tennessee</option>
                <option value="Texas">Texas</option>
                <option value="Utah">Utah</option>
                <option value="Vermont">Vermont</option>
                <option value="Virginia">Virginia</option>
                <option value="Washington">Washington</option>
                <option value="West Virginia">West Virginia</option>
                <option value="Wisconsin">Wisconsin</option>
                <option value="Wyoming">Wyoming</option>
                <option value="D.C.">District of Columbia</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Guam">Guam</option>
                <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                </select>
            </form>
            <form className="focus-container">
                <label htmlFor="sort"><h3>Sort by: </h3></label>
                <select name="sort" id="sort" onChange={(e) => handleFilterChange(e, "sort")}>
                <option value="near">Ending Soon</option>
                <option value="far">Ending Later</option>
                <option value="high">Value (High to Low)</option>
                <option value="low">Value (Low to High)</option>
                </select>
            </form>
              </div>
              {isArrThere() ? ('') : (<div className="glass-dropdown-box"><h2>No Giveaways!</h2></div>)}
      {renderData(currentItems)}
      {isArrBig() ? (
      <div className="pageNumbersDiv">
      <ul className="pageNumbers">
        <li>
          <button onClick={handlePrevbtn} disabled={currentPage == pages[0] ? true : false}>
            Prev
          </button>
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}
        <li>
          <button onClick={handleNextbtn} disabled={currentPage == pages[pages.length - 1] ? true : false}>
            Next
          </button>
        </li>
      </ul>
      </div>) : ('')}
      </div>) }
    <br />
    <br />
    <br />
    </div>
  );
}

export default Giveaways;