import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

const SponsorCreateHooks = (props) => {

    const initialInputState = { email: '', name: '', password: '' };
    const [eachEntry, setEachEntry] = useState(initialInputState);
    const {email, name, password} = eachEntry;
    const [api, setApi] = useState([]);
    const [loading, setLoading] = useState(true);
    const [confirmPass, setConfirmPass] = useState('');
    const [noMatch, setNoMatch] = useState(false);

    useEffect(()=>{
        fetchApi();
    }, [])

    const fetchApi = async () => {
        const fetchApi = await fetch(`${process.env.REACT_APP_API_URL}/b/content/createsponsor`);
        const api = await fetchApi.json();
        setApi(api);
        setLoading(false);
    };

    const sponsorCreate = async (data) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/b/sponregister`, { email: data.email, name: data.name, password: data.password })
        .then(response => {
            const sponItem = {
                'sponsorid': response.data.rows[0].sponsorid, 
                'email': response.data.rows[0].email,
                'name': response.data.rows[0].name,
                'stripeid': response.data.rows[0].stripeid,
                'spontier': response.data.rows[0].spontier
                }
        localStorage.setItem('x-api-key', response.data.token);
        localStorage.setItem('x-api-key-expiration', Date.now() + 2 * 60 * 60 * 1000);
        localStorage.setItem('sponuser', JSON.stringify(sponItem))
        return response.data
        })
        
        .catch(err => Promise.reject('Account could not be created!'));
        }

        const handleConfirmChange = e => {
            setConfirmPass(e.target.value)
        }

    const handleInputChange = e => {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
      };

      const handleFinalSubmit = e => {
        if (password !== confirmPass) {
            e.preventDefault();
            alert('Passwords do not match!')
        } else {
        e.preventDefault();
        sponsorCreate(eachEntry)
        .then(token => window.location = '/businesssendverification')
        .catch(err => alert(err));
        }
    };

    useEffect(() => {
        if (password !== confirmPass) {
            setNoMatch(true);
        } else setNoMatch(false);
    }, [confirmPass])

    return(
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
            <h1>{api.create} *</h1>
            <div className="focus-login">
                <form onSubmit={handleFinalSubmit}>
                    <div className="form-group">
                        <label><h3>Email: </h3></label>
                            <input type="text" className="form-control" name="email"
                            onChange={handleInputChange}/>
                    </div>
                    <div className="form-group">
                        <label><h3>Business Name: </h3></label>
                            <input type="text" className="form-control" name="name"
                            onChange={handleInputChange}/>
                    </div>
                    <div className="form-group">
                        <label><h3>Password: </h3></label>
                            <input type="password" className="form-control" name="password"
                            onChange={handleInputChange}/>
                    </div>
                    <div className="form-group">
                        <label><h3>Confirm Password: </h3></label>
                            <input type="password" className="form-control" name="confirmpass"
                            onChange={handleConfirmChange}/>
                    </div>
                    {noMatch === true ? (<div className="pass-validate">
                        <p>Passwords do not match!</p>
                    </div>) : ('')}
                    <br />
                <button type="submit" className="february-form-submit-button">Submit</button>
                </form>
                <div className="signup-agree">
            <p>* By creating a business account, you accept the <Link to='/terms'>Terms and Conditions</Link>.</p>
            </div>
            </div>
            <br />
            <br />
            </div>)
}
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default SponsorCreateHooks;