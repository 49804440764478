import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import Spinner from './Spinner';

const SponsorEditHooks = ({match}) => {

    const [loading, setLoading] = useState(true);

    const initialInputState = { street: '', city: '', state: '', country: '', zip: '' };
    const [eachEntry, setEachEntry] = useState(initialInputState);
    const {category, street, city, state, country, zip, website, bio, twitter, facebook, instagram, phone} = eachEntry;
    const [sponuser] = useState(JSON.parse(localStorage.getItem('sponuser')) || [])
    const [info, setInfo] = useState([]);

    const initialCategoryState = { category: '' };
    const initialWebsiteState = { website: '' };
    const initialBioState = { bio: '' };
    const initialTwitterState = { twitter: '' };
    const initialFacebookState = { facebook: '' };
    const initialInstagramState = { instagram: '' };
    const initialPhoneState = { phone: '' };
    const [categoryEntry, setCategoryEntry] = useState(initialCategoryState);
    const [websiteEntry, setWebsiteEntry] = useState(initialWebsiteState);
    const [bioEntry, setBioEntry] = useState(initialBioState);
    const [twitterEntry, setTwitterEntry] = useState(initialTwitterState);
    const [facebookEntry, setFacebookEntry] = useState(initialFacebookState);
    const [instagramEntry, setInstagramEntry] = useState(initialInstagramState);
    const [phoneEntry, setPhoneEntry] = useState(initialPhoneState);

    const patchCategory = async (data) => {
        return axios.patch(`${process.env.REACT_APP_API_URL}/b/sponcategory/${sponuser.sponsorid}`, data, { headers: {'x-api-key': localStorage.getItem('x-api-key')} })
        .then(response => {
          return response.status
        })
        .catch(err => Promise.reject('Failed!'));
    }

    const handleCategoryChange = e => {
        setCategoryEntry({ ...categoryEntry, [e.target.name]: e.target.value });
      };

      const handleCategorySubmit = e => {
        e.preventDefault();
        patchCategory(categoryEntry)
        .then(token => window.location = `/editbusiness`)
        .catch(err => alert(err));
    };

        async function sponsorEditAddressInfo (sponsorid, data, stripeid) {
            console.log(data)
            axios.patch(`${process.env.REACT_APP_API_URL}/b/sponaddress/${sponsorid}`, { apisponid: sponsorid, street: data.street, city: data.city, state: data.state, country: data.country, zip: data.zip }, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} })
            .then(response => {
            return response.data
            })
            .catch(err => Promise.reject('Please enter all address fields!'));
            return axios.post(`${process.env.REACT_APP_API_URL}/b/customeraddress`, { customerId: stripeid, street: data.street, city: data.city, state: data.state, country: data.country, zip: data.zip }, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} })
            .then(response => {
            return response.data
            })
            .catch(err => Promise.reject('Please enter all address fields!'));
            }

const handleInputChange = e => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  const handleFinalSubmit = e => {
    e.preventDefault();
    sponsorEditAddressInfo(sponuser.sponsorid, eachEntry, sponuser.stripeid)
    .then(token => window.location = `/editbusiness`)
    .catch(err => alert(err));
};

const patchWebsite = async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/sponwebsite/${sponuser.sponsorid}`, data, { headers: {'x-api-key': localStorage.getItem('x-api-key')} })
    .then(response => {
      return response.status
    })
    .catch(err => Promise.reject('Failed!'));
}

const handleWebsiteChange = e => {
    setWebsiteEntry({ ...websiteEntry, [e.target.name]: e.target.value });
  };

  const handleWebsiteSubmit = e => {
    e.preventDefault();
    patchWebsite(websiteEntry)
    .then(token => window.location = `/editbusiness`)
    .catch(err => alert(err));
};

const patchBio = async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/sponbio/${sponuser.sponsorid}`, data, { headers: {'x-api-key': localStorage.getItem('x-api-key')} })
    .then(response => {
      return response.status
    })
    .catch(err => Promise.reject('Failed!'));
}

const handleBioChange = e => {
    setBioEntry({ ...bioEntry, [e.target.name]: e.target.value });
  };

  const handleBioSubmit = e => {
    e.preventDefault();
    patchBio(bioEntry)
    .then(token => window.location = `/editbusiness`)
    .catch(err => alert(err));
};

const patchTwitter = async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/spontwitter/${sponuser.sponsorid}`, data, { headers: {'x-api-key': localStorage.getItem('x-api-key')} })
    .then(response => {
      return response.status
    })
    .catch(err => Promise.reject('Failed!'));
}

const handleTwitterChange = e => {
    setTwitterEntry({ ...twitterEntry, [e.target.name]: e.target.value });
  };

  const handleTwitterSubmit = e => {
    e.preventDefault();
    patchTwitter(twitterEntry)
    .then(token => window.location = `/editbusiness`)
    .catch(err => alert(err));
};

const patchFacebook = async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/sponfacebook/${sponuser.sponsorid}`, data, { headers: {'x-api-key': localStorage.getItem('x-api-key')} })
    .then(response => {
      return response.status
    })
    .catch(err => Promise.reject('Failed!'));
}

const handleFacebookChange = e => {
    setFacebookEntry({ ...facebookEntry, [e.target.name]: e.target.value });
  };

  const handleFacebookSubmit = e => {
    e.preventDefault();
    patchFacebook(facebookEntry)
    .then(token => window.location = `/editbusiness`)
    .catch(err => alert(err));
};

const patchInstagram = async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/sponinstagram/${sponuser.sponsorid}`, data, { headers: {'x-api-key': localStorage.getItem('x-api-key')} })
    .then(response => {
      return response.status
    })
    .catch(err => Promise.reject('Failed!'));
}

const handleInstagramChange = e => {
    setInstagramEntry({ ...instagramEntry, [e.target.name]: e.target.value });
  };

  const handleInstagramSubmit = e => {
    e.preventDefault();
    patchInstagram(instagramEntry)
    .then(token => window.location = `/editbusiness`)
    .catch(err => alert(err));
};

const patchPhone = async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/b/sponphone/${sponuser.sponsorid}`, data, { headers: {'x-api-key': localStorage.getItem('x-api-key')} })
    .then(response => {
      return response.status
    })
    .catch(err => Promise.reject('Failed!'));
}

const handlePhoneChange = e => {
    setPhoneEntry({ ...phoneEntry, [e.target.name]: e.target.value });
  };

  const handlePhoneSubmit = e => {
    e.preventDefault();
    patchPhone(phoneEntry)
    .then(token => window.location = `/editbusiness`)
    .catch(err => alert(err));
};

useEffect(() => {
    fetchInfo();
}, [])

const fetchInfo = async () => {
    const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/spondetails/${ sponuser.sponsorid }`);
    const info = await fetchInfo.json();
    setInfo(info);
    setLoading(false);
};

    return(
<div>
<br />
<br />
<br />
{
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
                <div>
                <h1>Add Business Account Details, <Link to={`/business/${sponuser.sponsorid}`} >{sponuser.name}</Link>!</h1>
                </div>

 <div>
 <h2>Please add your information so your Business will appear on Givmap!</h2>
 </div>
 <div className="focus-edit">
                    <div className="focus-edit-box">
                    <form onSubmit={handleCategorySubmit}>
                    <div className="form-group">
                    <h3>Current Business Category: </h3><h3 className="focus-contrast">{info.category}</h3>
                    <label><h3>Update Business Category: </h3></label>
                    <select name="category" className="form-control" onChange={handleCategoryChange}>
                    <option value={info.category}>{info.category}</option>
                    <option value="Antiques/Collectibles">Antiques/Collectibles</option>
                    <option value="Art">Art</option>
                    <option value="Athletic/Sports Gear">Athletic/Sports Gear</option>
                    <option value="Automotive">Automotive</option>
                    <option value="Beauty/Personal Care">Beauty/Personal Care</option>
                    <option value="Books">Books</option>
                    <option value="Clothing">Clothing</option>
                    <option value="Computers/Electronics">Computers/Electronics</option>
                    <option value="Crypto">Crypto</option>
                    <option value="Fitness/Gym">Fitness/Gym</option>
                    <option value="Food/Restaurant">Food/Restaurant</option>
                    <option value="Hats/Headwear">Hats/Headwear</option>
                    <option value="Health">Health</option>
                    <option value="Home/Kitchen Goods">Home/Kitchen Goods</option>
                    <option value="Kids/Toys">Kids/Toys</option>
                    <option value="Luggage/Travel Gear">Luggage/Travel Gear</option>
                    <option value="Magazines/Media">Magazines/Media</option>
                    <option value="Movies/TV">Movies/TV</option>
                    <option value="Music">Music</option>
                    <option value="Musical Instruments">Musical Instruments</option>
                    <option value="NFTs">NFTs (Non-Fungible Tokens)</option>
                    <option value="Outdoors">Outdoors</option>
                    <option value="Pets">Pets</option>
                    <option value="Shoes">Shoes</option>
                    <option value="Software">Software</option>
                    <option value="Video Games/E-Sports">Video Games/E-Sports</option>
                    <option value="Other">Other</option>
                    </select>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handleFinalSubmit}>
                    <div className="form-group">
                        <h3>Current Address: </h3><h3 className="focus-contrast">{info.street}</h3><h3 className="focus-contrast">{info.city}</h3><h3 className="focus-contrast">{info.state}</h3><h3 className="focus-contrast">{info.country}</h3><h3 className="focus-contrast">{info.zip}</h3>
                    <label><h3>Update Street Address: </h3></label>
                    <input type="text" className="form-control" name="street"
                    onChange={handleInputChange}/>
                    </div>
                    <div className="form-group">
                    <label><h3>Update City: </h3></label>
                    <input type="text" className="form-control" name="city"
                    onChange={handleInputChange}/>
                    </div>
                    <div className="form-group">
                    <label><h3>Update State: </h3></label>
                    <select name="state" className="form-control" onChange={handleInputChange}>
                    <option value=""></option>
                    <option value="Alabama">Alabama</option>
                    <option value="Alaska">Alaska</option>
                    <option value="Arizona">Arizona</option>
                    <option value="Arkansas">Arkansas</option>
                    <option value="California">California</option>
                    <option value="Colorado">Colorado</option>
                    <option value="Connecticut">Connecticut</option>
                    <option value="Delaware">Delaware</option>
                    <option value="Florida">Florida</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Idaho">Idaho</option>
                    <option value="Illinois">Illinois</option>
                    <option value="Indiana">Indiana</option>
                    <option value="Iowa">Iowa</option>
                    <option value="Kansas">Kansas</option>
                    <option value="Kentucky">Kentucky</option>
                    <option value="Louisiana">Louisiana</option>
                    <option value="Maine">Maine</option>
                    <option value="Maryland">Maryland</option>
                    <option value="Massachusetts">Massachusetts</option>
                    <option value="Michigan">Michigan</option>
                    <option value="Minnesota">Minnesota</option>
                    <option value="Mississippi">Mississippi</option>
                    <option value="Missouri">Missouri</option>
                    <option value="Montana">Montana</option>
                    <option value="Nebraska">Nebraska</option>
                    <option value="Nevada">Nevada</option>
                    <option value="New Hampshire">New Hampshire</option>
                    <option value="New Jersey">New Jersey</option>
                    <option value="New Mexico">New Mexico</option>
                    <option value="New York">New York</option>
                    <option value="North Carolina">North Carolina</option>
                    <option value="North Dakota">North Dakota</option>
                    <option value="Ohio">Ohio</option>
                    <option value="Oklahoma">Oklahoma</option>
                    <option value="Oregon">Oregon</option>
                    <option value="Pennsylvania">Pennsylvania</option>
                    <option value="Rhode Island">Rhode Island</option>
                    <option value="South Carolina">South Carolina</option>
                    <option value="South Dakota">South Dakota</option>
                    <option value="Tennessee">Tennessee</option>
                    <option value="Texas">Texas</option>
                    <option value="Utah">Utah</option>
                    <option value="Vermont">Vermont</option>
                    <option value="Virginia">Virginia</option>
                    <option value="Washington">Washington</option>
                    <option value="West Virginia">West Virginia</option>
                    <option value="Wisconsin">Wisconsin</option>
                    <option value="Wyoming">Wyoming</option>
                    <option value="D.C.">District of Columbia</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Guam">Guam</option>
                    <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                    </select>
                    </div>
                    <div className="form-group">
                    <label><h3>Update Country: </h3></label>
                    <select name="country" className="form-control" onChange={handleInputChange}>
                    <option value=""></option>
                    <option value="United States">United States</option>
                    </select>
                    </div>
                    <div className="form-group">
                    <label><h3>Update Zip Code: </h3></label>
                    <input type="text" className="form-control" name="zip"
                    onChange={handleInputChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handleWebsiteSubmit}>
                    <div className="form-group">
                    <h3>Current Website URL: </h3><h3 className="focus-contrast">{info.website}</h3>
                    <label><h3>Update Website URL: </h3></label>
                    <input type="text" className="form-control" name="website"
                    onChange={handleWebsiteChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handleBioSubmit}>
                    <div className="form-group">
                    <h3>Current Business Biography: </h3><h3 className="focus-contrast">{info.bio}</h3>
                    <label><h3>Update Business Biography: </h3></label>
                    <input type="text" className="form-control" name="bio"
                    onChange={handleBioChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handleTwitterSubmit}>
                    <div className="form-group">
                    <h3>Current Twitter: </h3><h3 className="focus-contrast">{info.twitter}</h3>
                    <label><h3>Update Twitter: </h3></label>
                    <input type="text" className="form-control" name="twitter"
                    onChange={handleTwitterChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handleFacebookSubmit}>
                    <div className="form-group">
                    <h3>Current Facebook: </h3><h3 className="focus-contrast">{info.facebook}</h3>
                    <label><h3>Update Facebook: </h3></label>
                    <input type="text" className="form-control" name="facebook"
                    onChange={handleFacebookChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handleInstagramSubmit}>
                    <div className="form-group">
                    <h3>Current Instagram: </h3><h3 className="focus-contrast">{info.instagram}</h3>
                    <label><h3>Update Instagram: </h3></label>
                    <input type="text" className="form-control" name="instagram"
                    onChange={handleInstagramChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handlePhoneSubmit}>
                    <div className="form-group">
                    <h3>Current Phone: </h3><h3 className="focus-contrast">{info.phone}</h3>
                    <label><h3>Update Phone: </h3></label>
                    <input type="text" className="form-control" name="phone"
                    onChange={handlePhoneChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
 </div>
 <br />
 <h3><Link to={`/accountsettings`}>Account Settings</Link></h3>
        <br />
    </div>)
}
    <br />
            <br />
            <br />
            <br />
 </div>
    )
}

export default SponsorEditHooks;