import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import Spinner from './Spinner';

const EntrantEditEmail = (props) => {

    const initialInputState = { email: '', username: '', password: '' };
    const [eachEntry, setEachEntry] = useState(initialInputState);
    const {email, username, password} = eachEntry;
    const [user] = useState(() => JSON.parse(localStorage.getItem("user")) || "");
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);

    const initialEmailState = { email: '' };
    const initialNameState = { username: '' };
    const initialPasswordState = { password: '' };
    const initialConfirmPassState = { confirmPass: '' };
    const [emailEntry, setEmailEntry] = useState(initialEmailState);
    const [nameEntry, setNameEntry] = useState(initialNameState);
    const [passwordEntry, setPasswordEntry] = useState(initialPasswordState);
    const [confirmPass, setConfirmPass] = useState(initialConfirmPassState);
    const [noMatch, setNoMatch] = useState(false);

    const patchEmail = async (data) => {
        return axios.patch(`${process.env.REACT_APP_API_URL}/b/patchemailent/${user.entrantid}`, data, { headers: {'x-access-token': localStorage.getItem('x-access-token')} })
        .then(response => {
          return response.status
        })
        .catch(err => Promise.reject('Please enter an Email address!'));
    }

    const handleEmailChange = e => {
        setEmailEntry({ ...emailEntry, [e.target.name]: e.target.value });
      };

      const handleEmailSubmit = e => {
        e.preventDefault();
        patchEmail(emailEntry)
        .then(token => window.location = `/editmyemail`)
        .catch(err => alert(err));
    };

    const patchName = async (data) => {
        return axios.patch(`${process.env.REACT_APP_API_URL}/b/patchusernameent/${user.entrantid}`, data, { headers: {'x-access-token': localStorage.getItem('x-access-token')} })
        .then(response => {
          return response.status
        })
        .catch(err => Promise.reject('Please enter a Name!'));
    }

    const handleNameChange = e => {
        setNameEntry({ ...nameEntry, [e.target.name]: e.target.value });
      };

      const handleNameSubmit = e => {
        e.preventDefault();
        patchName(nameEntry)
        .then(token => window.location = `/editmyemail`)
        .catch(err => alert(err));
    };

    const patchPassword = async (data) => {
        return axios.patch(`${process.env.REACT_APP_API_URL}/b/patchuserpass/${user.entrantid}`, data, { headers: {'x-access-token': localStorage.getItem('x-access-token')} })
        .then(response => {
          return response.status
        })
        .catch(err => Promise.reject('Please enter a Password!'));
    }

    const handlePasswordChange = e => {
        setPasswordEntry({ ...passwordEntry, [e.target.name]: e.target.value });
      };

      const handlePasswordSubmit = e => {
        if (passwordEntry.password !== confirmPass.confirmPass) {
            e.preventDefault();
            alert('Passwords do not match!')
        } else {
        e.preventDefault();
        patchPassword(passwordEntry)
        .then(token => window.location = `/editmyemail`)
        .catch(err => alert(err));
        }
    };

    const handleConfirmChange = e => {
        setConfirmPass({ ...confirmPass, [e.target.name]: e.target.value });
    }
    
    const fetchInfo = async () => {
        const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/entrantsemailname/${ user.entrantid }`);
        const info = await fetchInfo.json();
        setInfo(info);
        setLoading(false);
    };
    
    useEffect(() => {
        fetchInfo();
    }, [])

    useEffect(() => {
        if (passwordEntry.password !== confirmPass.confirmPass) {
            setNoMatch(true);
        } else setNoMatch(false);
    }, [confirmPass])

    return(
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
            <div>
            <h1>Edit Account Login: </h1>
            </div>
            <div className="focus-edit">
                    <div className="focus-edit-box">
                    <form onSubmit={handleEmailSubmit}>
                    <div className="form-group">
                    <h3>Current Email: </h3><h3 className="focus-contrast">{info.email}</h3>
                    <label><h3>Update Email: </h3></label>
                    <input type="text" className="form-control" name="email"
                    onChange={handleEmailChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handleNameSubmit}>
                    <div className="form-group">
                    <h3>Current Username: </h3><h3 className="focus-contrast">{info.username}</h3>
                    <label><h3>Update Username: </h3></label>
                    <input type="text" className="form-control" name="username"
                    onChange={handleNameChange}/>
                    </div>
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
                    <div className="focus-edit-box">
                    <form onSubmit={handlePasswordSubmit}>
                    <div className="form-group">
                    <h3>Current Password is not displayed for security purposes</h3>
                    <label><h3>Update Password: </h3></label>
                    <input type="password" className="form-control" name="password"
                    onChange={handlePasswordChange}/>
                    </div>
                    <br />
                    <div className="form-group">
                    <label><h3>Confirm Password: </h3></label>
                    <input type="password" className="form-control" name="confirmPass"
                    onChange={handleConfirmChange}/>
                    </div>
                    {noMatch === true ? (<div className="pass-validate">
                        <p>Passwords do not match!</p>
                    </div>) : ('')}
                    <br />
                    <button type="submit" className="march-form-submit-button"> Save </button>
                    </form>
                    </div>
                    <br />
            </div>
            <br />
            <h3><Link to={`/myaccount`}>Account Settings</Link></h3>
            <br />
            </div>)
}
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default EntrantEditEmail;