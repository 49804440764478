import React from "react";
import SpinnerFetching from './SpinnerFetching';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

const SponEntriesChartThree = (props) => {

    function isProcessing() {
        return props.loading === true;
    }
    
  return (
    <div>
        {
    isProcessing() ? ( <SpinnerFetching /> ) :(
             <div className="glass-chart-section">
                <ResponsiveContainer width="90%" height={250}>
    <BarChart
      data={props.entryLogs}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Bar type="monotone" dataKey="Entries" fill="#2BA880" />
    </BarChart>
    </ResponsiveContainer>
  </div>
    )}
    </div>
)

}

export default SponEntriesChartThree;