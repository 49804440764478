import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import Spinner from './Spinner';

const EntrantCreateHooks = (props) => {

    const initialInputState = { email: '', username: '', password: '' };
    const [eachEntry, setEachEntry] = useState(initialInputState);
    const {email, username, password} = eachEntry;
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [confirmPass, setConfirmPass] = useState('');
    const [noMatch, setNoMatch] = useState(false);

    useEffect(()=>{
        fetchInfo();
    }, [])

    const fetchInfo = async () => {
        const fetchInfo = await fetch(`${process.env.REACT_APP_API_URL}/b/content/createentrant`);
        const info = await fetchInfo.json();
        setInfo(info);
        setLoading(false);
    };

    const handleConfirmChange = e => {
        setConfirmPass(e.target.value)
    }

    const handleInputChange = e => {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
      };

      const entrantCreate = async (data) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/b/register`, { email: data.email, username: data.username, password: data.password })
        .then(response => {
            const userItem = {
                'entrantid': response.data.rows[0].entrantid, 
                'email': response.data.rows[0].email,
                'username': response.data.rows[0].username
                }
        localStorage.setItem('x-access-token', response.data.token);
        localStorage.setItem('x-access-token-expiration', Date.now() + 2 * 60 * 60 * 1000);
        localStorage.setItem('user', JSON.stringify(userItem));
        return response.data
        })
        .catch(err => Promise.reject('Account could not be created!'));
        }

      const handleFinalSubmit = e => {
        if (password !== confirmPass) {
            e.preventDefault();
            alert('Passwords do not match!')
        } else {
        e.preventDefault();
        entrantCreate(eachEntry)
        .then(res => window.location='/sendverification')
        .catch(err => alert(err));
        }
    };

    useEffect(() => {
        if (password !== confirmPass) {
            setNoMatch(true);
        } else setNoMatch(false);
    }, [confirmPass])

    return(
        <div>
            <br />
            <br />
            <br />
            {
        (loading) ? ( <Spinner /> ) : (
            <div className="focus-div">
                <div>
            <h1>{info.create} *</h1>
            </div>
            <div className="focus-login">
                <form onSubmit={handleFinalSubmit}>
                    <div className="form-group">
                        <label><h3>Email: </h3></label>
                            <input type="text" className="form-control" name="email"
                            onChange={handleInputChange}/>
                    </div>
                    <div className="form-group">
                        <label><h3>Username: </h3></label>
                            <input type="text" className="form-control" name="username"
                            onChange={handleInputChange}/>
                    </div>
                    <div className="form-group">
                        <label><h3>Password: </h3></label>
                            <input type="password" className="form-control" name="password"
                            onChange={handleInputChange}/>
                    </div>
                    <div className="form-group">
                        <label><h3>Confirm Password: </h3></label>
                            <input type="password" className="form-control" name="confirmpass"
                            onChange={handleConfirmChange}/>
                    </div>
                    {noMatch === true ? (<div className="pass-validate">
                        <p>Passwords do not match!</p>
                    </div>) : ('')}
                    <br />
                <button type="submit" className="february-form-submit-button">Submit</button>
                </form>
                <div className="signup-agree">
            <p>* By creating an account, you accept the <Link to='/terms'>Terms and Conditions</Link>.</p>
            </div>
            </div>
            <br />
                <Link to='/businesscreate'><h3>🏙️ Create a Business Account</h3></Link>
                </div>)
}
                <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default EntrantCreateHooks;