import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import EntrantLoginHooks from './EntrantLoginHooks';
import EntrantCreateHooks from './EntrantCreateHooks';
import About from './About';
import SponsorDash from './SponsorDash';
import SponsorReturnHooks from './SponsorReturnHooks';
import SponsorLoginHooks from './SponsorLoginHooks';
import SponsorCreateHooks from './SponsorCreateHooks';
import SponActive from './SponActive';
import SponAll from './SponAll';
import SponExpired from './SponExpired';
import Giveaways from './Giveaways';
import GiveawayDetails from './GiveawayDetails';
import CreateGiveawayHooks from './CreateGiveawayHooks';
import BizAbout from './BizAbout';
import ReturnHooks from './ReturnHooks';
import BusinessDetails from './BusinessDetails';
import Feed from './Feed';
import FAQ from './FAQ';
import Followers from './Followers';
import MyGwaysEntered from './MyGwaysEntered';
import MyGwaysWon from './MyGwaysWon';
import MyFollowedSpons from './MyFollowedSpons';
import Upload from './Upload';
import Home from './Home';
import ItemDetails from './ItemDetails';
import CreateItemHooks from './CreateItemHooks';
import SponItems from './SponItems';
import SponUpload from './SponUpload';
import EntrantEditHooks from './EntrantEditHooks';
import SponsorEditHooks from './SponsorEditHooks';
import ViewWinner from './ViewWinner';
import ItemImageDash from './ItemImageDash';
import ItemUploadOne from './ItemUploadOne';
//import ItemUploadTwo from './ItemUploadTwo';
//import ItemUploadThree from './ItemUploadThree';
//import ItemUploadFour from './ItemUploadFour';
//import ItemUploadFive from './ItemUploadFive';
import MyFavItems from './MyFavItems';
import SponShop from './SponShop';
import SponGwayShop from './SponGwayShop';
import SponPastGwayShop from './SponPastGwayShop';
import EntrantEditEmail from './EntrantEditEmail';
import SponsorEditEmail from './SponsorEditEmail';
import GiveawayGuide from './GiveawayGuide';
import ItemView from './ItemView';
import Terms from './Terms';
import Privacy from './Privacy';
import AdminLogin from './AdminLogin';
import AdminDash from './AdminDash';
import SearchResults from './SearchResults';
import WriteReview from './WriteReview';
import SetTier from './SetTier';
import Mailbox from './Mailbox';
import MailItem from './MailItem';
import Reply from './Reply';
import MailboxSpon from './MailboxSpon';
import MailItemSpon from './MailItemSpon';
import ReplySpon from './ReplySpon';
import Businesses from './Businesses';
import VerifyLanding from './VerifyLanding';
import VerifyAgain from './VerifyAgain';
import VerifyLandingSpon from './VerifyLandingSpon';
import VerifyAgainSpon from './VerifyAgainSpon';
import ForgotPass from './ForgotPass';
import ForgotPassSpon from './ForgotPassSpon';
import ChangePassword from './ChangePassword';
import ChangePasswordSpon from './ChangePasswordSpon';
import UpgradeAccount from './UpgradeAccount';
import UpgradeAddress from './UpgradeAddress';
import GiveawayLanding from './GiveawayLanding';
import ComposeMessage from './ComposeMessage';
import ComposeSpon from './ComposeSpon';
import UpgradeReturn from './UpgradeReturn';
import EditProduct from './EditProduct';
import RandomGiveaway from './RandomGiveaway';
import RandomSponsor from './RandomSponsor';
import SearchFromNav from './SearchFromNav';
import SponFuture from './SponFuture';
import SponAccountSettings from './SponAccountSettings';
import AccountSettings from './AccountSettings';
import PaymentHistory from './PaymentHistory';
import CancelSubscription from './CancelSubscription';
import MailSent from './MailSent';
import MailSentSpon from './MailSentSpon';
import MailSentItem from './MailSentItem';
import MailSentItemSpon from './MailSentItemSpon';
import AdminReports from './AdminReports';
import ReportDetails from './ReportDetails';
import ReportGiveaway from './ReportGiveaway';
import InstantWinDetails from './InstantWinDetails';
import InstantLanding from './InstantLanding';
import ReportInstant from './ReportInstant';
import ReportDetailsInstant from './ReportDetailsInstant';
import ViewWinnerInstant from './ViewWinnerInstant';
import ViewWinnerDetailsInstant from './ViewWinnerDetailsInstant';
import ComposeMessageInstant from './ComposeMessageInstant';
import ComposeSponInstant from './ComposeSponInstant';
import WriteReviewInstant from './WriteReviewInstant';
import NotFound from './NotFound';

const Router = (props) => (
<Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/about" component={About} />
    <Route exact path="/businessabout" component={BizAbout} />
    <Route path="/giveaways/:gwayid" component={GiveawayDetails} />
    <Route exact path="/login" component={EntrantLoginHooks} />
    <Route exact path="/create" component={EntrantCreateHooks} />
    <Route exact path="/businesslogin" component={SponsorLoginHooks} />
    <Route exact path="/businesscreate" component={SponsorCreateHooks} />
    <PrivateRoute exact path="/feed" component={Feed} />
    <PrivateRoute exact path="/mygiveaways" component={MyGwaysEntered} />
    <PrivateRoute exact path="/mywongiveaways" component={MyGwaysWon} />
    <PrivateRoute exact path="/following" component={MyFollowedSpons} />
    <PrivateRoute exact path="/upload" component={Upload} />
    <SponsorsRoute exact path="/mydashboard" component={SponsorDash} />
    <SponsorsRoute exact path="/creategiveaway" component={CreateGiveawayHooks} />
    <Route exact path="/return" component={ReturnHooks}/>
    <Route path="/business/:sponsorid" component={BusinessDetails} />
    <SponsorsRoute exact path="/businessreturn" component={SponsorReturnHooks} />
    <SponsorsRoute exact path="/expiredgiveaways" component={SponExpired} />
    <SponsorsRoute exact path="/livegiveaways" component={SponActive} />
    <SponsorsRoute exact path="/scheduledgiveaways" component={SponFuture} />
    <SponsorsRoute exact path="/allgiveaways" component={SponAll} />
    <SponsorsRoute exact path="/followers" component={Followers} />
    //<Route exact path="/faq" component={FAQ} />
    <Route path="/products/:itemid" component={ItemDetails} />
    <SponsorsRoute exact path="/myshop" component={SponItems} />
    <SponsorsRoute exact path="/createproduct" component={CreateItemHooks} />
    <SponsorsRoute exact path="/businessupload" component={SponUpload} />
    <PrivateRoute exact path="/editmyprofile" component={EntrantEditHooks}/>
    <SponsorsRoute exact path="/editbusiness" component={SponsorEditHooks}/>
    <SponsorsRoute path="/viewwinner/:gwayid" component={ViewWinner}/>
    <SponsorsRoute path="/imagedash/:itemid" component={ItemImageDash}/>
    <SponsorsRoute path="/uploadone/:itemid" component={ItemUploadOne}/>
    <PrivateRoute exact path="/myfavorites" component={MyFavItems} />
    <Route path="/shop/:sponsorid" component={SponShop} />
    <Route path="/shopgiveaways/:sponsorid" component={SponGwayShop} />
    <Route path="/pastgiveaways/:sponsorid" component={SponPastGwayShop} />
    <PrivateRoute exact path="/editmyemail" component={EntrantEditEmail} />
    <SponsorsRoute exact path="/editbusinessemail" component={SponsorEditEmail}/>
    <Route exact path="/guide" component={GiveawayGuide} />
    <SponsorsRoute path="/viewmyproduct/:itemid" component={ItemView}/>
    <Route exact path="/terms" component={Terms} />
    <Route exact path="/privacy" component={Privacy} />
    <Route exact path="/adminportal" component={AdminLogin} />
    <AdminRoute exact path="/admindash" component={AdminDash} />
    <Route exact path="/search" component={SearchResults} />
    <PrivateRoute exact path="/writereview/:gwayid" component={WriteReview} />
    <SponsorsRoute exact path="/settier" component={SetTier} />
    <PrivateRoute exact path="/mailbox" component={Mailbox} />
    <PrivateRoute path="/mailbox/:messageid" component={MailItem} />
    <PrivateRoute path="/reply/:messageid" component={Reply} />
    <SponsorsRoute exact path="/businessmailbox" component={MailboxSpon} />
    <SponsorsRoute path="/businessmailbox/:messageid" component={MailItemSpon} />
    <SponsorsRoute path="/businessreply/:messageid" component={ReplySpon} />
    <Route exact path="/giveaways" component={Giveaways} />
    <Route exact path="/businesses" component={Businesses} />
    <Route path="/verify/:token" component={VerifyLanding} />
    <PrivateRoute exact path="/sendverification" component={VerifyAgain} />
    <Route path="/businessverify/:token" component={VerifyLandingSpon} />
    <SponsorsRoute path="/businesssendverification" component={VerifyAgainSpon} />
    <Route exact path="/forgotpassword" component={ForgotPass} />
    <Route exact path="/businessforgotpassword" component={ForgotPassSpon} />
    <Route path="/changepassword/:token" component={ChangePassword} />
    <Route path="/businesschangepassword/:token" component={ChangePasswordSpon} />
    <SponsorsRoute exact path="/upgradeaccount" component={UpgradeAccount} />
    <SponsorsRoute exact path="/upgradeaddress" component={UpgradeAddress} />
    <SponsorsRoute path="/gwayview/:gwayid" component={GiveawayLanding}/>
    <PrivateRoute path="/composemail/:gwayid" component={ComposeMessage} />
    <SponsorsRoute path="/compose/:gwayid" component={ComposeSpon}/>
    <SponsorsRoute exact path="/upgradereturn" component={UpgradeReturn}/>
    <SponsorsRoute path="/editproduct/:itemid" component={EditProduct} />
    <Route exact path="/randomgiveaway" component={RandomGiveaway} />
    <Route exact path="/randombusiness" component={RandomSponsor} />
    <Route path="/quicksearch" component={SearchFromNav} />
    <SponsorsRoute path="/accountsettings" component={SponAccountSettings} />
    <PrivateRoute path="/myaccount" component={AccountSettings} />
    <SponsorsRoute path="/paymenthistory" component={PaymentHistory} />
    <SponsorsRoute path="/managesubscription" component={CancelSubscription} />
    <PrivateRoute exact path="/mailsent" component={MailSent} />
    <SponsorsRoute exact path="/businessmailsent" component={MailSentSpon} />
    <PrivateRoute path="/mailsent/:messageid" component={MailSentItem} />
    <SponsorsRoute path="/businessmailsent/:messageid" component={MailSentItemSpon} />
    <AdminRoute exact path="/adminreports" component={AdminReports} />
    <AdminRoute path="/adminreport/:reportid" component={ReportDetails} />
    <Route path="/reportgiveaway/:gwayid" component={ReportGiveaway} />
    <Route path="/instant/:gwayid" component={InstantWinDetails} />
    <SponsorsRoute path="/instantview/:gwayid" component={InstantLanding}/>
    <Route path="/reportinstant/:gwayid" component={ReportInstant} />
    <AdminRoute path="/instantreport/:reportid" component={ReportDetailsInstant} />
    <SponsorsRoute path="/viewwinnerinstant/:gwayid" component={ViewWinnerInstant}/>
    <SponsorsRoute path="/viewwinnerdetails/:winnerid/:gwayid" component={ViewWinnerDetailsInstant}/>
    <PrivateRoute path="/composemailinstant/:sponsorid/:gwayid" component={ComposeMessageInstant} />
    <SponsorsRoute path="/composeinstant/:winnerid" component={ComposeSponInstant}/>
    <PrivateRoute exact path="/writereviewinstant/:gwayid" component={WriteReviewInstant} />
    <Route component={NotFound} />
    </Switch>
    )
    
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={ props =>
        localStorage.getItem('x-access-token') && localStorage.getItem('x-access-token-expiration') > Date.now()
        ? <Component {...props} />
         :  <Redirect to={{ pathname: "/" }}/>
}
/>
);

const SponsorsRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={ props =>
        localStorage.getItem('x-api-key') && localStorage.getItem('x-api-key-expiration') > Date.now()
        ? <Component {...props} />
         :  <Redirect to={{ pathname: "/" }}/>
}
/>
);

const AdminRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={ props =>
        localStorage.getItem('x-admin-key') && localStorage.getItem('x-admin-key-expiration') > Date.now()
        ? <Component {...props} />
         :  <Redirect to={{ pathname: "/" }}/>
}
/>
);

export default Router;