import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { isSponAuthenticated } from './repository';
import backlessgreen from './backlessgreen.png';

const SidebarSpon = (props) => {

  const [sponuser, setSponuser] = useState(JSON.parse(localStorage.getItem('sponuser')) || [])
  const [tier, setTier] = useState(sponuser.spontier);

  let drawerClasses = 'side-drawer'
  if (props.show === true) {
    drawerClasses = 'side-drawer open'
  } if (props.show === false) {
    drawerClasses = 'side-drawer'
  }

  function isPremium() { 
    return tier > 0;
}

useEffect(() => {
  axios.get(`${process.env.REACT_APP_API_URL}/b/sponus`, { headers: { 'x-api-key': localStorage.getItem('x-api-key')} })
  .then(response => {
      setSponuser( response.data )
      setTier( response.data.spontier )
      return response
   })
 .catch(err => Promise.reject('Request Not Authenticated!'));
}, [])

  return (
    <nav className={drawerClasses}>
      { isSponAuthenticated() ? (
      <div className="sidebar-spon-auth">
        <div className="sidebar_logo">
      <Link to="/"><img className="navimg" id="backlessgreen" src={backlessgreen} alt="Givmap" height="37" width="120" /></Link>
      </div>
      <div className='sidebar-center'>
     <ul>
     <li><Link to="/mydashboard">📊 Home</Link></li>
      <li><Link to="/search">🔎 Search</Link></li>
      <li><h3>Manage Giveaways</h3></li>
      <li><Link to='/livegiveaways'>⏱️ View my live Giveaways</Link></li>
      { isPremium() ? (<li><Link to='/scheduledgiveaways'>📆 View my scheduled Giveaways</Link></li>) : '' }
      <li><Link to='/expiredgiveaways'>🎊 View my expired Giveaways</Link></li>
      <li><Link to='/allgiveaways'>🛍️ View all my Giveaways</Link></li>
      <li><Link to='/creategiveaway'>🎁 Create a new Giveaway</Link></li>
      <li><h3>Manage Products</h3></li>
      <li><Link to='/myshop'>🛒 View my Products</Link></li>
      <li><Link to='/createproduct'>💡 Create a new Product</Link></li>
      <li><h3>Other</h3></li>
      <li><Link to='/accountsettings'>⚙️ Account Settings</Link></li>
      <li><Link to='/businesses'>🏙️ Browse Businesses</Link></li>
      <li><Link to='/followers'>🚶 View my followers</Link></li>
      <li><Link to='/businessabout'>📈 Givmap for your Business</Link></li>
      { isPremium() ? ("") : (<li><Link to='/upgradeaccount'>🚀 UPGRADE ACCOUNT</Link></li>) }
      </ul>
      </div>
     </div>
     ) : (
      <div className="sidebar-no-auth">
      <div className="sidebar_logo">
      <Link to="/"><img className="navimg" id="backlessgreen" src={backlessgreen} alt="Givmap" height="37" width="120" /></Link>
      </div>
      <div className='sidebar-center'>
    <ul>
    <li><Link to='/search'>🔎 Search</Link></li>
    <li><Link to='/giveaways'>🎁 Browse Giveaways</Link></li>
     <li><Link to='/businesses'>🏙️ Browse Businesses</Link></li>
     <li><Link to="/about">📜 About</Link><br /></li>
     <li><Link to='/businessabout'>📈 Givmap for your Business</Link></li>
     <li><Link to='/create'>✍️ Sign Up</Link></li>
     <li><Link to='/login'>💻 Login</Link></li>
    </ul>
    </div>
    </div>
     )
     }
      <div className="sidebar_bottom">
      <small><p>Givmap Version 2.0</p></small>
    </div>
    </nav>
)

}
export default SidebarSpon;