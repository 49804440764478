import React from 'react';

const SpinnerFetching = (props) => {
    return (
        <div>
             <div className="loading-process">
        </div>
        <div className="loading-spinner-processing">
            
        </div>
        </div>
    )
}

export default SpinnerFetching;