import React  from 'react';
import Router from './Router';
import { isAuthenticated } from './repository';
import { isSponAuthenticated } from './repository';
import Toolbar from './Toolbar';
import SponToolbar from './SponToolbar';
import ToolbarEnt from './ToolbarEnt';
import Footer from './Footer';
import './style.css'

const App = () => {

    return (
        <div style={{height: '100%'}}>
         { ( isSponAuthenticated() ) ? <SponToolbar /> : ( isAuthenticated() ) ? <ToolbarEnt /> : <Toolbar /> }
        <main>
          <div className="app-div">
        <Router />
        <Footer />
        </div>
        </main>
      </div>
    )
}

export default App;