import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div>
      <br />
      <br />
      <br />
      <div className="focus-div">
    <h1>Page Not Found!</h1>
    <h3><Link to="/">🎁</Link></h3>
    </div>
  </div>
);

export default NotFound;